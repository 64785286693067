// action - state management
import { getLocalStorage, removeLocalStorage, setLocalStorage } from 'assets/js/hash';
import axios from 'axios';
// import axios from 'axios';
import config from '../../config';
import * as actionTypes from '../actions';
import { openSnackbar } from './snackbar';

// ==============================|| STATE ||============================== //
const moduleConfig = {
    name: "lookup",
    item: "lookup",
    items: "lookups",
    actionTypes: {
        setItem: actionTypes.SET_LOOKUP,
        setItems: actionTypes.SET_LOOKUPS,
    },
}
export const initialState = {
    lookup: null,
    lookups: [],
    fieldsForUpdates: [
    ],
};

// ==============================|| COMMON ACTIONS ||============================== //

export const getItem = (input, callback) => {
    console.log(`[[ CALL ${moduleConfig.name} getItem() ]]`, input)
    // return (dispatch) => {
    //     client
    //         .query({
    //             query: moduleConfig.gql.query.getItem,
    //             variables: { input },
    //         })
    //         .then(({ data }) => {
    //             console.log('[[ RES ]]', data)
    //             let result = data?.[moduleConfig.gql.response.getItem];
    //             dispatch({ type: moduleConfig.actionTypes.setItem, payload: result });
    //             if (callback) callback(result, null);
    //             return
    //         })
    //         .catch((error) => {
    //             console.log('[[ ERROR ]]', error.message)
    //             dispatch({
    //                 type: actionTypes.SET_ERROR,
    //                 error,
    //             });
    //             if (callback) callback(null, error);
    //             return
    //         });
    // };
}
export const getItems = (input, callback) => {
    console.log(`[[ CALL ${moduleConfig.name} getItems() ]]`, input)
    return (dispatch) => {
        axios({
            method: "get",
            url: `${config.server.API_ENDPOINT}/lookup/list`,
            withCredentials: true,
            headers: { 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json' },
        }).then(response => {
            console.log('[[ RES ]]', response.data)
            let { status, message, data } = response.data;
            let result = data;
            dispatch({ type: moduleConfig.actionTypes.setItems, payload: result });
            if (callback) callback(result, null);
            return
        }).catch((error) => {
            console.log('[[ ERROR ]]', error.message)
            dispatch({
                type: actionTypes.SET_ERROR,
                error,
            });
            if (callback) callback(null, error);
            return
        });
    };
}
export const getLookupItems = (input, callback) => {
    console.log(`[[ CALL ${moduleConfig.name} getLookupItems() ]]`, input)
    return (dispatch) => {
        client
            .query({
                query: moduleConfig.gql.query.getLookupItems,
                variables: { input },
            })
            .then(({ data }) => {
                console.log('[[ RES ]]', data)
                let result = data?.[moduleConfig.gql.response.getLookupItems];
                dispatch({ type: moduleConfig.actionTypes.setItems, payload: result });
                if (callback) callback(result, null);
                return
            })
            .catch((error) => {
                console.log('[[ ERROR ]]', error.message)
                dispatch({
                    type: actionTypes.SET_ERROR,
                    error,
                });
                if (callback) callback(null, error);
                return
            });
    };
}
export const getItemsByPage = (input, callback) => {
    console.log(`[[ CALL ${moduleConfig.name} getItemsByPage() ]]`, input)
    return (dispatch) => {
        client
            .query({
                query: moduleConfig.gql.query.getItemsByPage,
                variables: { input },
            })
            .then(({ data }) => {
                console.log('[[ RES ]]', data)
                let result = data?.[moduleConfig.gql.response.getItemsByPage]
                // dispatch({ type: moduleConfig.actionTypes.setItems, payload: result });
                if (callback) callback(result, null);
                return
            })
            .catch((error) => {
                console.log('[[ ERROR ]]', error.message)
                dispatch({
                    type: actionTypes.SET_ERROR,
                    error,
                });
                if (callback) callback(null, error);
                return
            });
    };
}
// ==============================|| CUSTOM ACTIONS ||============================== //


// ==============================|| REDUCER ||============================== //

const lookupReducer = (state = initialState, action) => {
    let { payload } = action;
    switch (action.type) {

        // ------------- COMMON ACTIONS ------------- //
        case moduleConfig.actionTypes.setItem:
            return {
                ...state,
                [`${moduleConfig.item}`]: payload,
            };
        case moduleConfig.actionTypes.setItems:
            return {
                ...state,
                [`${moduleConfig.items}`]: payload,
            };

        // ------------- CUSTOM ACTIONS ------------- //

        // ------------- ------------- ------------- //
        default:
            return state;
    }
};

export default lookupReducer;
