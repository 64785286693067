// third-party
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// project import
import auth from './auth';
import chat from './chat';
import calendar from './calendar';
import menu from './menu';
import snackbar from './snackbar';
import productReducer from './product';
import cartReducer from './cart';

// ==========================
import appReducer from './app.reducer';
import accommodationReducer from './accommodation.reducer';
import alertConfigReducer from './alert-config.reducer';
import brandReducer from './brand.reducer';
import chartReducer from './chart.reducer';
import conditionReducer from './condition.reducer';
import contactReducer from './contact.reducer';
import customerReducer from './customer.reducer';
import datasetReducer from './dataset.reducer';
import departmentReducer from './department.reducer';
import eventReducer from './event.reducer';
import fileReducer from './file.reducer';
import invitationReducer from './invitation.reducer';
import keyValueReducer from './key-value.reducer';
import logReducer from './log.reducer';
import lookupReducer from './lookup.reducer';
import messageTemplateReducer from './message-template.reducer';
import noteReducer from './note.reducer';
import policyConfigReducer from './policy-config.reducer';
import ppmReducer from './ppm.reducer';
import prefillConfigReducer from './prefill-config.reducer';
import projectReducer from './project.reducer';
import questionReducer from './question.reducer';
import questionnaireReducer from './questionnaire.reducer';
import responseReducer from './response.reducer';
import sendMessageReducer from './send-message.reducer';
import staffReducer from './staff.reducer';
import surveyReducer from './survey.reducer';
import tokenReducer from './token.reducer';
import touchpointReducer from './touchpoint.reducer';
import userReducer from './user.reducer';
import widgetReducer from './widget.reducer';


// ==============================|| COMBINE REDUCERS ||============================== //

const reducers = combineReducers({
  auth,
  chat,
  calendar,
  menu,
  snackbar,
  cart: persistReducer(
    {
      key: 'cart',
      storage,
      keyPrefix: 'mantis-ts-'
    },
    cartReducer
  ),
  product: productReducer,
  // =========================
  app: appReducer,
  accommodation: accommodationReducer,
  alertConfig: alertConfigReducer,
  brand: brandReducer,
  chart: chartReducer,
  condition: conditionReducer,
  contact: contactReducer,
  customer: customerReducer,
  dataset: datasetReducer,
  department: departmentReducer,
  event: eventReducer,
  file: fileReducer,
  invitation: invitationReducer,
  keyValue: keyValueReducer,
  log: logReducer,
  lookup: lookupReducer,
  messageTemplate: messageTemplateReducer,
  note: noteReducer,
  policyConfig: policyConfigReducer,
  ppm: ppmReducer,
  prefillConfig: prefillConfigReducer,
  project: projectReducer,
  question: questionReducer,
  questionnaire: questionnaireReducer,
  response: responseReducer,
  sendMessage: sendMessageReducer,
  staff: staffReducer,
  survey: surveyReducer,
  token: tokenReducer,
  touchpoint: touchpointReducer,
  user: userReducer,
  widget: widgetReducer,
});

export default reducers;
