import { gql } from '@apollo/client';

// ====================== QUERY ====================== //
const QUERY_ITEM = gql`
    query dataset($input: DatasetInput!){
        dataset(input: $input){
            id
            uuid
            name
            description
            channel_type
            file
            url
            expression
            table_name
            filters
            company
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`
const QUERY_ITEMS = gql`
    query datasets($input: DatasetsInput){
        datasets(input: $input){
            id
            uuid
            name
            description
            channel_type
            file
            url
            expression
            table_name
            filters
            company
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`
const QUERY_LOOKUP_ITEMS = gql`
    query datasets($input: DatasetsInput){
        datasets(input: $input){
            # id
            uuid
            name
            description
            channel_type
            file
            url
            table_name
            company
            # created_at
            # created_by
            # updated_at
            # updated_by
        }
    }
`
const QUERY_ITEMS_BY_PAGE = gql`
    query datasetsByPage($input: DatasetsByPageInput!){
        datasetsByPage(input: $input){
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
            currentPage
            prevPage
            nextPage
            totalPages
            totalItems
            datasets{
                id
                uuid
                name
                description
                channel_type
                file
                url
                expression
                table_name
                filters
                company
                created_at
                created_by
                updated_at
                updated_by
            }
        }
    }
`
const QUERY_GET_DATASET = gql`
    query getDataset($input: GetDatasetInput!){
        getDataset(input: $input){
            dataset {
                id
                uuid
                name
                description
                channel_type
                file
                url
                expression
                table_name
                filters
                company
                created_at
                created_by
                updated_at
                updated_by
            }
            rows
            cols
            headers
            sample
            items
        }
    }
`
// ====================== MUTATION ====================== //
const MUTATION_CREATE_ITEM = gql`
    mutation createDataset($input: CreateDatasetInput!){
        createDataset(input: $input){
            id
            uuid
            name
            description
            channel_type
            file
            url
            expression
            table_name
            filters
            company
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`
const MUTATION_CREATE_ITEMS = gql`
    mutation createDatasets($input: [CreateDatasetInput]!){
        createDatasets(input: $input){
            id
            uuid
            name
            description
            channel_type
            file
            url
            expression
            table_name
            filters
            company
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`
const MUTATION_DELETE_ITEM = gql`
    mutation deleteDataset($input: DeleteDatasetInput!){
        deleteDataset(input: $input){
            id
            uuid
            name
            description
            channel_type
            file
            url
            expression
            table_name
            filters
            company
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`
const MUTATION_DELETE_ITEMS = gql`
    mutation deleteDatasets($input: DeleteDatasetInput!){
        deleteDatasets(input: $input){
            id
            uuid
            name
            description
            channel_type
            file
            url
            expression
            table_name
            filters
            company
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`
const MUTATION_EXPORT_ITEMS = gql`
    mutation exportDatasets($input: DatasetsInput!, $by: ActionByInput!){
        exportDatasets(input: $input, by: $by){
           uuid
           size
           key
           name
           status
           topic
           type
           url
           company
           created_at
           created_by
           updated_at
           updated_by
        }
    }
`
const MUTATION_UPDATE_ITEM = gql`
    mutation updateDataset($input: UpdateDatasetInput!){
        updateDataset(input: $input){
            id
            uuid
            name
            description
            channel_type
            file
            url
            expression
            table_name
            filters
            company
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`

// ====================== CREATE GQL ====================== //
const datasetGQL = {
    query: {
        getItem: QUERY_ITEM,
        getItems: QUERY_ITEMS,
        getLookupItems: QUERY_LOOKUP_ITEMS,
        getItemsByPage: QUERY_ITEMS_BY_PAGE,
        getDataset: QUERY_GET_DATASET,
    },
    mutation: {
        createItem: MUTATION_CREATE_ITEM,
        createItems: MUTATION_CREATE_ITEMS,
        deleteItem: MUTATION_DELETE_ITEM,
        deleteItems: MUTATION_DELETE_ITEMS,
        exportItems: MUTATION_EXPORT_ITEMS,
        updateItem: MUTATION_UPDATE_ITEM,
    },
    response: {
        getItem: "dataset",
        getItems: "datasets",
        getLookupItems: "datasets",
        getItemsByPage: "datasetsByPage",
        getDataset: "getDataset",
        createItem: "createDataset",
        createItems: "createDatasets",
        deleteItem: "deleteDataset",
        deleteItems: "deleteDatasets",
        exportItems: "exportDatasets",
        updateItem: "updateDataset",
    }
}

// ====================== EXPORT ====================== //
export {
    datasetGQL,
}