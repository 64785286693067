// action - state management
import { getLocalStorage, removeLocalStorage, setLocalStorage } from 'assets/js/hash';
import { client } from 'graphql/client';
import { staffGQL } from 'graphql/staff';
// import axios from 'axios';
// import config from '../../config';
import * as actionTypes from '../actions';
import { setCount } from './file.reducer';
import { openSnackbar } from './snackbar';

// ==============================|| STATE ||============================== //
const moduleConfig = {
    name: "staff",
    item: "staff",
    items: "staffs",
    actionTypes: {
        setItem: actionTypes.SET_STAFF,
        setItems: actionTypes.SET_STAFFS,
    },
    gql: staffGQL,
}
export const initialState = {
    staff: null,
    staffs: [],
    fieldsForUpdates: [
        "uuid",
        "staff_company",
        "prefixname",
        "firstname",
        "lastname",
        "department_uuid",
        "role",
        "mobile",
        "email",
        "updated_by",
    ],
};

// ==============================|| COMMON ACTIONS ||============================== //

export const getItem = (input, callback) => {
    console.log(`[[ CALL ${moduleConfig.name} getItem() ]]`, input)
    return (dispatch) => {
        client
            .query({
                query: moduleConfig.gql.query.getItem,
                variables: { input },
            })
            .then(({ data }) => {
                console.log('[[ RES ]]', data)
                let result = data?.[moduleConfig.gql.response.getItem];
                dispatch({ type: moduleConfig.actionTypes.setItem, payload: result });
                if (callback) callback(result, null);
                return
            })
            .catch((error) => {
                console.log('[[ ERROR ]]', error.message)
                dispatch({
                    type: actionTypes.SET_ERROR,
                    error,
                });
                if (callback) callback(null, error);
                return
            });
    };
}
export const getItems = (input, callback) => {
    console.log(`[[ CALL ${moduleConfig.name} getItems() ]]`, input)
    return (dispatch) => {
        client
            .query({
                query: moduleConfig.gql.query.getItems,
                variables: { input },
            })
            .then(({ data }) => {
                console.log('[[ RES ]]', data)
                let result = data?.[moduleConfig.gql.response.getItems];
                dispatch({ type: moduleConfig.actionTypes.setItems, payload: result });
                if (callback) callback(result, null);
                return
            })
            .catch((error) => {
                console.log('[[ ERROR ]]', error.message)
                dispatch({
                    type: actionTypes.SET_ERROR,
                    error,
                });
                if (callback) callback(null, error);
                return
            });
    };
}
export const getLookupItems = (input, callback) => {
    console.log(`[[ CALL ${moduleConfig.name} getLookupItems() ]]`, input)
    return (dispatch) => {
        client
            .query({
                query: moduleConfig.gql.query.getLookupItems,
                variables: { input },
            })
            .then(({ data }) => {
                console.log('[[ RES ]]', data)
                let result = data?.[moduleConfig.gql.response.getLookupItems];
                dispatch({ type: moduleConfig.actionTypes.setItems, payload: result });
                if (callback) callback(result, null);
                return
            })
            .catch((error) => {
                console.log('[[ ERROR ]]', error.message)
                dispatch({
                    type: actionTypes.SET_ERROR,
                    error,
                });
                if (callback) callback(null, error);
                return
            });
    };
}
export const getItemsByPage = (input, callback) => {
    console.log(`[[ CALL ${moduleConfig.name} getItemsByPage() ]]`, input)
    return (dispatch) => {
        client
            .query({
                query: moduleConfig.gql.query.getItemsByPage,
                variables: { input },
            })
            .then(({ data }) => {
                console.log('[[ RES ]]', data)
                let result = data?.[moduleConfig.gql.response.getItemsByPage]
                // dispatch({ type: moduleConfig.actionTypes.setItems, payload: result });
                if (callback) callback(result, null);
                return
            })
            .catch((error) => {
                console.log('[[ ERROR ]]', error.message)
                dispatch({
                    type: actionTypes.SET_ERROR,
                    error,
                });
                if (callback) callback(null, error);
                return
            });
    };
}
export const createItem = (input, callback) => {
    console.log(`[[ CALL ${moduleConfig.name} createItem() ]]`, input)
    return (dispatch) => {
        client
            .mutate({
                mutation: moduleConfig.gql.mutation.createItem,
                variables: { input },
            })
            .then(({ data }) => {
                console.log('[[ RES ]]', data)
                let result = data?.[moduleConfig.gql.response.createItem];
                dispatch(
                    openSnackbar({
                        open: true,
                        autoHideDuration: 5000,
                        message: `Create ${moduleConfig.item} success`,
                        variant: 'alert',
                        alert: {
                            variant: 'border',
                            icon: 'success',
                            color: 'success'
                        },
                        transition: 'SlideUp',
                        close: false,
                    })
                );
                if (callback) callback(result, null);
                return
            })
            .catch((error) => {
                console.log('[[ ERROR ]]', error.message)
                dispatch(
                    openSnackbar({
                        open: true,
                        autoHideDuration: 5000,
                        message: `${error?.response?.data?.message || error?.message}`,
                        variant: 'alert',
                        alert: {
                            variant: 'border',
                            icon: 'error',
                            color: 'error',
                        },
                        transition: 'SlideUp',
                        close: false,
                    })
                );
                if (callback) callback(null, error);
                return
            });
    };
}
export const createItems = (input, callback) => {
    console.log(`[[ CALL ${moduleConfig.name} createItems() ]]`, input)
    return (dispatch) => {
        client
            .mutate({
                mutation: moduleConfig.gql.mutation.createItems,
                variables: { input },
            })
            .then(({ data }) => {
                console.log('[[ RES ]]', data)
                let result = data?.[moduleConfig.gql.response.createItems];
                dispatch(
                    openSnackbar({
                        open: true,
                        autoHideDuration: 5000,
                        message: `Create ${moduleConfig.items} success`,
                        variant: 'alert',
                        alert: {
                            variant: 'border',
                            icon: 'success',
                            color: 'success'
                        },
                        transition: 'SlideUp',
                        close: false,
                    })
                );
                if (callback) callback(result, null);
                return
            })
            .catch((error) => {
                console.log('[[ ERROR ]]', error.message)
                dispatch(
                    openSnackbar({
                        open: true,
                        autoHideDuration: 5000,
                        message: `${error?.response?.data?.message || error?.message}`,
                        variant: 'alert',
                        alert: {
                            variant: 'border',
                            icon: 'error',
                            color: 'error',
                        },
                        transition: 'SlideUp',
                        close: false,
                    })
                );
                if (callback) callback(null, error);
                return
            });
    };
}
export const deleteItem = (input, callback) => {
    console.log(`[[ CALL ${moduleConfig.name} deleteItem() ]]`, input)
    return (dispatch) => {
        client
            .mutate({
                mutation: moduleConfig.gql.mutation.deleteItem,
                variables: { input },
            })
            .then(({ data }) => {
                console.log('[[ RES ]]', data)
                let result = data?.[moduleConfig.gql.response.deleteItem];
                dispatch(
                    openSnackbar({
                        open: true,
                        autoHideDuration: 5000,
                        message: `Delete ${moduleConfig.item} success`,
                        variant: 'alert',
                        alert: {
                            variant: 'border',
                            icon: 'success',
                            color: 'success'
                        },
                        transition: 'SlideUp',
                        close: false,
                    })
                );
                if (callback) callback(result, null);
                return
            })
            .catch((error) => {
                console.log('[[ ERROR ]]', error.message)
                dispatch(
                    openSnackbar({
                        open: true,
                        autoHideDuration: 5000,
                        message: `${error?.response?.data?.message || error?.message}`,
                        variant: 'alert',
                        alert: {
                            variant: 'border',
                            icon: 'error',
                            color: 'error',
                        },
                        transition: 'SlideUp',
                        close: false,
                    })
                );
                if (callback) callback(null, error);
                return
            });
    };
}
export const deleteItems = (input, callback) => {
    console.log(`[[ CALL ${moduleConfig.name} deleteItems() ]]`, input)
    return (dispatch) => {
        client
            .mutate({
                mutation: moduleConfig.gql.mutation.deleteItems,
                variables: { input },
            })
            .then(({ data }) => {
                console.log('[[ RES ]]', data)
                let result = data?.[moduleConfig.gql.response.deleteItems];
                dispatch(
                    openSnackbar({
                        open: true,
                        autoHideDuration: 5000,
                        message: `Delete ${moduleConfig.items} success`,
                        variant: 'alert',
                        alert: {
                            variant: 'border',
                            icon: 'success',
                            color: 'success'
                        },
                        transition: 'SlideUp',
                        close: false,
                    })
                );
                if (callback) callback(result, null);
                return
            })
            .catch((error) => {
                console.log('[[ ERROR ]]', error.message)
                dispatch(
                    openSnackbar({
                        open: true,
                        autoHideDuration: 5000,
                        message: `${error?.response?.data?.message || error?.message}`,
                        variant: 'alert',
                        alert: {
                            variant: 'border',
                            icon: 'error',
                            color: 'error',
                        },
                        transition: 'SlideUp',
                        close: false,
                    })
                );
                if (callback) callback(null, error);
                return
            });
    };
}
export const exportItems = (input, by, callback) => {
    console.log(`[[ CALL ${moduleConfig.name} exportItems() ]]`, input)
    return (dispatch) => {
        client
            .mutate({
                mutation: moduleConfig.gql.mutation.exportItems,
                variables: { input, by },
            })
            .then(({ data }) => {
                console.log('[[ RES ]]', data)
                let result = data?.[moduleConfig.gql.response.exportItems];
                dispatch(
                    openSnackbar({
                        open: true,
                        autoHideDuration: 5000,
                        message: `Export ${result?.name || 'file'} success`,
                        variant: 'alert',
                        alert: {
                            variant: 'border',
                            icon: 'success',
                            color: 'success'
                        },
                        transition: 'SlideUp',
                        close: false,
                    })
                );
                dispatch(setCount(1, (res)=>{}));
                if (callback) callback(result, null);
                return
            })
            .catch((error) => {
                console.log('[[ ERROR ]]', error.message)
                dispatch(
                    openSnackbar({
                        open: true,
                        autoHideDuration: 5000,
                        message: `${error?.response?.data?.message || error?.message}`,
                        variant: 'alert',
                        alert: {
                            variant: 'border',
                            icon: 'error',
                            color: 'error',
                        },
                        transition: 'SlideUp',
                        close: false,
                    })
                );
                if (callback) callback(null, error);
                return
            });
    };
}
export const updateItem = (input, callback) => {
    console.log(`[[ CALL ${moduleConfig.name} updateItem() ]]`, input)
    return (dispatch) => {
        client
            .mutate({
                mutation: moduleConfig.gql.mutation.updateItem,
                variables: { input },
            })
            .then(({ data }) => {
                console.log('[[ RES ]]', data)
                let result = data?.[moduleConfig.gql.response.updateItem];
                dispatch(
                    openSnackbar({
                        open: true,
                        autoHideDuration: 5000,
                        message: `Update ${moduleConfig.item} success`,
                        variant: 'alert',
                        alert: {
                            variant: 'border',
                            icon: 'success',
                            color: 'success'
                        },
                        transition: 'SlideUp',
                        close: false,
                    })
                );
                if (callback) callback(result, null);
                return
            })
            .catch((error) => {
                console.log('[[ ERROR ]]', error.message)
                dispatch(
                    openSnackbar({
                        open: true,
                        autoHideDuration: 5000,
                        message: `${error?.response?.data?.message || error?.message}`,
                        variant: 'alert',
                        alert: {
                            variant: 'border',
                            icon: 'error',
                            color: 'error',
                        },
                        transition: 'SlideUp',
                        close: false,
                    })
                );
                if (callback) callback(null, error);
                return
            });
    };
}

// ==============================|| CUSTOM ACTIONS ||============================== //


// ==============================|| REDUCER ||============================== //

const staffReducer = (state = initialState, action) => {
    let { payload } = action;
    switch (action.type) {

        // ------------- COMMON ACTIONS ------------- //
        case moduleConfig.actionTypes.setItem:
            return {
                ...state,
                [`${moduleConfig.item}`]: payload,
            };
        case moduleConfig.actionTypes.setItems:
            return {
                ...state,
                [`${moduleConfig.items}`]: payload,
            };

        // ------------- CUSTOM ACTIONS ------------- //

        // ------------- ------------- ------------- //
        default:
            return state;
    }
};

export default staffReducer;
