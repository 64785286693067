import { gql } from '@apollo/client';

// ====================== QUERY ====================== //
const QUERY_ITEM = gql`
    query condition($input: ConditionInput!){
        condition(input: $input){
            id
            uuid
            invitation_uuid
            type
            key
            value
            relational_operator
            conditional_operator
            company
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`
const QUERY_ITEMS = gql`
    query conditions($input: ConditionsInput){
        conditions(input: $input){
            id
            uuid
            invitation_uuid
            type
            key
            value
            relational_operator
            conditional_operator
            company
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`
const QUERY_LOOKUP_ITEMS = gql`
    query conditions($input: ConditionsInput){
        conditions(input: $input){
            # id
            uuid
            invitation_uuid
            type
            key
            value
            relational_operator
            conditional_operator
            company
            # created_at
            # created_by
            # updated_at
            # updated_by
        }
    }
`
const QUERY_ITEMS_BY_PAGE = gql`
    query conditionsByPage($input: ConditionsByPageInput!){
        conditionsByPage(input: $input){
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
            currentPage
            prevPage
            nextPage
            totalPages
            totalItems
            conditions{
                id
                uuid
                invitation_uuid
                type
                key
                value
                relational_operator
                conditional_operator
                company
                created_at
                created_by
                updated_at
                updated_by
            }
        }
    }
`
// ====================== MUTATION ====================== //
const MUTATION_CREATE_ITEM = gql`
    mutation createCondition($input: CreateConditionInput!){
        createCondition(input: $input){
            id
            uuid
            invitation_uuid
            type
            key
            value
            relational_operator
            conditional_operator
            company
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`
const MUTATION_CREATE_ITEMS = gql`
    mutation createConditions($input: [CreateConditionInput]!){
        createConditions(input: $input){
            id
            uuid
            invitation_uuid
            type
            key
            value
            relational_operator
            conditional_operator
            company
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`
const MUTATION_DELETE_ITEM = gql`
    mutation deleteCondition($input: DeleteConditionInput!){
        deleteCondition(input: $input){
            id
            uuid
            invitation_uuid
            type
            key
            value
            relational_operator
            conditional_operator
            company
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`
const MUTATION_DELETE_ITEMS = gql`
    mutation deleteConditions($input: DeleteConditionInput!){
        deleteConditions(input: $input){
            id
            uuid
            invitation_uuid
            type
            key
            value
            relational_operator
            conditional_operator
            company
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`
const MUTATION_EXPORT_ITEMS = gql`
    mutation exportConditions($input: ConditionsInput!, $by: ActionByInput!){
        exportConditions(input: $input, by: $by){
           uuid
           size
           key
           name
           status
           topic
           type
           url
           company
           created_at
           created_by
           updated_at
           updated_by
        }
    }
`
const MUTATION_UPDATE_ITEM = gql`
    mutation updateCondition($input: UpdateConditionInput!){
        updateCondition(input: $input){
            id
            uuid
            invitation_uuid
            type
            key
            value
            relational_operator
            conditional_operator
            company
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`

// ====================== CREATE GQL ====================== //
const conditionGQL = {
    query: {
        getItem: QUERY_ITEM,
        getItems: QUERY_ITEMS,
        getLookupItems: QUERY_LOOKUP_ITEMS,
        getItemsByPage: QUERY_ITEMS_BY_PAGE,
    },
    mutation: {
        createItem: MUTATION_CREATE_ITEM,
        createItems: MUTATION_CREATE_ITEMS,
        deleteItem: MUTATION_DELETE_ITEM,
        deleteItems: MUTATION_DELETE_ITEMS,
        exportItems: MUTATION_EXPORT_ITEMS,
        updateItem: MUTATION_UPDATE_ITEM,
    },
    response: {
        getItem: "condition",
        getItems: "conditions",
        getLookupItems: "conditions",
        getItemsByPage: "conditionsByPage",
        createItem: "createCondition",
        createItems: "createConditions",
        deleteItem: "deleteCondition",
        deleteItems: "deleteConditions",
        exportItems: "exportConditions",
        updateItem: "updateCondition",
    }
}

// ====================== EXPORT ====================== //
export {
    conditionGQL,
}