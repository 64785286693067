import { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// material-ui
import { useTheme } from '@mui/material/styles';
import { useMediaQuery, Box, Container, Toolbar } from '@mui/material';

// project import
import Drawer from './Drawer';
import Header from './Header';
import Footer from './Footer';
import navigation from 'menu-items';
import useConfig from 'hooks/useConfig';
import Breadcrumbs from 'components/@extended/Breadcrumbs';
import { openDrawer } from 'store/reducers/menu';
import MaintenanceAuthentication from '../../pages/maintenance/authentication';
import { isAccessMenu } from 'assets/js/user';

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
  const theme = useTheme();
  const location = useLocation();

  const matchDownLG = useMediaQuery(theme.breakpoints.down('xl'));

  const { container, miniDrawer } = useConfig();
  const dispatch = useDispatch();

  const menu = useSelector((state) => state.menu);
  const auth = useSelector((state) => state.auth);
  const { drawerOpen } = menu;

  // drawer toggler
  const [open, setOpen] = useState(!miniDrawer || drawerOpen);
  const handleDrawerToggle = () => {
    setOpen(!open);
    dispatch(openDrawer({ drawerOpen: !open }));
  };

  // set media wise responsive drawer
  useEffect(() => {
    if (!miniDrawer) {
      setOpen(!matchDownLG);
      dispatch(openDrawer({ drawerOpen: !matchDownLG }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchDownLG]);

  useEffect(() => {
    if (open !== drawerOpen) setOpen(drawerOpen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drawerOpen]);

  const permission = isAccessMenu({ user: auth?.user, program: location?.pathname });
  // console.log('permission', location?.pathname, permission)

  const renderScreenChangeAsking = () => {
    return null
  }

  return (
    <Box sx={{ display: 'flex', width: '100%' }}>
      <Header open={open} handleDrawerToggle={handleDrawerToggle} />
      <Drawer open={open} handleDrawerToggle={handleDrawerToggle} />
      <Box component="main" sx={{
        width: 'calc(100% - 260px)',
        flexGrow: 1,
        p: { xs: 2, sm: 3 },
        background: theme.palette?.custom?.mainBackgroundColor || null,
      }}>
        <Toolbar />
        {container && (
          <Container
            // maxWidth="lg"
            sx={{
              px: { xs: 0, sm: 2 },
              position: 'relative',
              minHeight: 'calc(100vh - 110px)',
              display: 'flex',
              flexDirection: 'column',
              flex: 1
            }}
            style={{
              maxWidth: 'unset',
              padding: '0px',
            }}
          >
            <Breadcrumbs navigation={navigation} title titleBottom card={false} divider={false} />
            {!permission && <MaintenanceAuthentication />}
            {permission && <Outlet />}
            {/* <Footer /> */}
          </Container>
        )}
        {!container && (
          <Box sx={{ position: 'relative', minHeight: 'calc(100vh - 110px)', display: 'flex', flexDirection: 'column' }}>
            <Breadcrumbs navigation={navigation} title titleBottom card={false} divider={false} />
            {!permission && <MaintenanceAuthentication />}
            {permission && <Outlet />}
            {/* <Footer /> */}
          </Box>
        )}
      </Box>
      {false && renderScreenChangeAsking()}
    </Box>
  );
};

export default MainLayout;
