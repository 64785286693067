import { gql } from '@apollo/client';

// ====================== QUERY ====================== //
const QUERY_ITEM = gql`
    query project($input: ProjectInput!){
        project(input: $input){
            id
            uuid
            code
            name
            type
            project_company
            status
            brand_uuid
            brand {
                id
                uuid
                code
                name
            }
            ppm_uuid
            ppm {
                id
                uuid
                code
                name
            }
            company
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`
const QUERY_ITEMS = gql`
    query projects($input: ProjectsInput){
        projects(input: $input){
            id
            uuid
            code
            name
            type
            project_company
            status
            brand_uuid
            brand {
                id
                uuid
                code
                name
            }
            ppm_uuid
            ppm {
                id
                uuid
                code
                name
            }
            company
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`
const QUERY_LOOKUP_ITEMS = gql`
    query projects($input: ProjectsInput){
        projects(input: $input){
            # id
            uuid
            code
            name
            type
            project_company
            status
            # brand_uuid
            # ppm_uuid
            # ppm {
            #     id
            #     uuid
            #     code
            #     name
            # }
            company
            # created_at
            # created_by
            # updated_at
            # updated_by
        }
    }
`
const QUERY_ITEMS_BY_PAGE = gql`
    query projectsByPage($input: ProjectsByPageInput!){
        projectsByPage(input: $input){
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
            currentPage
            prevPage
            nextPage
            totalPages
            totalItems
            projects{
                id
                uuid
                code
                name
                type
                project_company
                status
                brand_uuid
                brand {
                    id
                    uuid
                    code
                    name
                }
                ppm_uuid
                ppm {
                    id
                    uuid
                    code
                    name
                }
                company
                created_at
                created_by
                updated_at
                updated_by
            }
        }
    }
`
// ====================== MUTATION ====================== //
const MUTATION_CREATE_ITEM = gql`
    mutation createProject($input: CreateProjectInput!){
        createProject(input: $input){
            id
            uuid
            code
            name
            type
            project_company
            status
            brand_uuid
            brand {
                id
                uuid
                code
                name
            }
            ppm_uuid
            ppm {
                id
                uuid
                code
                name
            }
            company
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`
const MUTATION_CREATE_ITEMS = gql`
    mutation createProjects($input: [CreateProjectInput]!){
        createProjects(input: $input){
            id
            uuid
            code
            name
            type
            project_company
            status
            brand_uuid
            brand {
                id
                uuid
                code
                name
            }
            ppm_uuid
            ppm {
                id
                uuid
                code
                name
            }
            company
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`
const MUTATION_DELETE_ITEM = gql`
    mutation deleteProject($input: DeleteProjectInput!){
        deleteProject(input: $input){
            id
            uuid
            code
            name
            type
            project_company
            status
            brand_uuid
            brand {
                id
                uuid
                code
                name
            }
            ppm_uuid
            ppm {
                id
                uuid
                code
                name
            }
            company
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`
const MUTATION_DELETE_ITEMS = gql`
    mutation deleteProjects($input: DeleteProjectInput!){
        deleteProjects(input: $input){
            id
            uuid
            code
            name
            type
            project_company
            status
            brand_uuid
            brand {
                id
                uuid
                code
                name
            }
            ppm_uuid
            ppm {
                id
                uuid
                code
                name
            }
            company
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`
const MUTATION_EXPORT_ITEMS = gql`
    mutation exportProjects($input: ProjectsInput!, $by: ActionByInput!){
        exportProjects(input: $input, by: $by){
           uuid
           size
           key
           name
           status
           topic
           type
           url
           company
           created_at
           created_by
           updated_at
           updated_by
        }
    }
`
const MUTATION_UPDATE_ITEM = gql`
    mutation updateProject($input: UpdateProjectInput!){
        updateProject(input: $input){
            id
            uuid
            code
            name
            type
            project_company
            status
            brand_uuid
            brand {
                id
                uuid
                code
                name
            }
            ppm_uuid
            ppm {
                id
                uuid
                code
                name
            }
            company
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`

// ====================== CREATE GQL ====================== //
const projectGQL = {
    query: {
        getItem: QUERY_ITEM,
        getItems: QUERY_ITEMS,
        getLookupItems: QUERY_LOOKUP_ITEMS,
        getItemsByPage: QUERY_ITEMS_BY_PAGE,
    },
    mutation: {
        createItem: MUTATION_CREATE_ITEM,
        createItems: MUTATION_CREATE_ITEMS,
        deleteItem: MUTATION_DELETE_ITEM,
        deleteItems: MUTATION_DELETE_ITEMS,
        exportItems: MUTATION_EXPORT_ITEMS,
        updateItem: MUTATION_UPDATE_ITEM,
    },
    response: {
        getItem: "project",
        getItems: "projects",
        getLookupItems: "projects",
        getItemsByPage: "projectsByPage",
        createItem: "createProject",
        createItems: "createProjects",
        deleteItem: "deleteProject",
        deleteItems: "deleteProjects",
        exportItems: "exportProjects",
        updateItem: "updateProject",
    }
}

// ====================== EXPORT ====================== //
export {
    projectGQL,
}