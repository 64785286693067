import { gql } from '@apollo/client';

// ====================== QUERY ====================== //
const QUERY_ITEM = gql`
    query customer($input: CustomerInput!){
        customer(input: $input){
            id
            uuid
            code
            customer_id
            prefixname
            name
            firstname
            lastname
            type
            nationality
            status
            coming_type
            level
            mobile
            email
            gender
            birthday
            accommodation_uuids
            accommodations {
                id
                uuid
                code
                project_uuid
                project {
                    id
                    uuid
                    code
                    name
                    type
                    company
                }
                unit_number
                unit_type
                sale_price
                rent_price
            }
            address
            channel
            company
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`
const QUERY_ITEMS = gql`
    query customers($input: CustomersInput){
        customers(input: $input){
            id
            uuid
            code
            customer_id
            prefixname
            name
            firstname
            lastname
            type
            nationality
            status
            coming_type
            level
            mobile
            email
            gender
            birthday
            accommodation_uuids
            accommodations {
                id
                uuid
                code
                project_uuid
                project {
                    id
                    uuid
                    code
                    name
                    type
                    company
                }
                unit_number
                unit_type
                sale_price
                rent_price
            }
            address
            channel
            company
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`
const QUERY_LOOKUP_ITEMS = gql`
    query customers($input: CustomersInput){
        customers(input: $input){
            # id
            uuid
            code
            customer_id
            prefixname
            name
            firstname
            lastname
            type
            nationality
            status
            coming_type
            level
            mobile
            email
            gender
            birthday
            # accommodation_uuids
            # accommodations {
            #     id
            #     uuid
            #     code
            #     project_uuid
            #     project {
            #         id
            #         uuid
            #         code
            #         name
            #         type
            #         company
            #     }
            #     unit_number
            #     unit_type
            #     sale_price
            #     rent_price
            # }
            # address
            # channel
            company
            # created_at
            # created_by
            # updated_at
            # updated_by
        }
    }
`
const QUERY_ITEMS_BY_PAGE = gql`
    query customersByPage($input: CustomersByPageInput!){
        customersByPage(input: $input){
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
            currentPage
            prevPage
            nextPage
            totalPages
            totalItems
            customers{
                id
                uuid
                code
                customer_id
                prefixname
                name
                firstname
                lastname
                type
                nationality
                status
                coming_type
                level
                mobile
                email
                gender
                birthday
                accommodation_uuids
                accommodations {
                    id
                    uuid
                    code
                    project_uuid
                    project {
                        id
                        uuid
                        code
                        name
                        type
                        company
                    }
                    unit_number
                    unit_type
                    sale_price
                    rent_price
                }
                address
                channel
                company
                created_at
                created_by
                updated_at
                updated_by
            }
        }
    }
`
// ====================== MUTATION ====================== //
const MUTATION_CREATE_ITEM = gql`
    mutation createCustomer($input: CreateCustomerInput!){
        createCustomer(input: $input){
            id
            uuid
            code
            customer_id
            prefixname
            name
            firstname
            lastname
            type
            nationality
            status
            coming_type
            level
            mobile
            email
            gender
            birthday
            accommodation_uuids
            accommodations {
                id
                uuid
                code
                project_uuid
                project {
                    id
                    uuid
                    code
                    name
                    type
                    company
                }
                unit_number
                unit_type
                sale_price
                rent_price
            }
            address
            channel
            company
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`
const MUTATION_CREATE_ITEMS = gql`
    mutation createCustomers($input: [CreateCustomerInput]!){
        createCustomers(input: $input){
            id
            uuid
            code
            customer_id
            prefixname
            name
            firstname
            lastname
            type
            nationality
            status
            coming_type
            level
            mobile
            email
            gender
            birthday
            accommodation_uuids
            accommodations {
                id
                uuid
                code
                project_uuid
                project {
                    id
                    uuid
                    code
                    name
                    type
                    company
                }
                unit_number
                unit_type
                sale_price
                rent_price
            }
            address
            channel
            company
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`
const MUTATION_DELETE_ITEM = gql`
    mutation deleteCustomer($input: DeleteCustomerInput!){
        deleteCustomer(input: $input){
            id
            uuid
            code
            customer_id
            prefixname
            name
            firstname
            lastname
            type
            nationality
            status
            coming_type
            level
            mobile
            email
            gender
            birthday
            accommodation_uuids
            accommodations {
                id
                uuid
                code
                project_uuid
                project {
                    id
                    uuid
                    code
                    name
                    type
                    company
                }
                unit_number
                unit_type
                sale_price
                rent_price
            }
            address
            channel
            company
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`
const MUTATION_DELETE_ITEMS = gql`
    mutation deleteCustomers($input: DeleteCustomerInput!){
        deleteCustomers(input: $input){
            id
            uuid
            code
            customer_id
            prefixname
            name
            firstname
            lastname
            type
            nationality
            status
            coming_type
            level
            mobile
            email
            gender
            birthday
            accommodation_uuids
            accommodations {
                id
                uuid
                code
                project_uuid
                project {
                    id
                    uuid
                    code
                    name
                    type
                    company
                }
                unit_number
                unit_type
                sale_price
                rent_price
            }
            address
            channel
            company
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`
const MUTATION_EXPORT_ITEMS = gql`
    mutation exportCustomers($input: CustomersInput!, $by: ActionByInput!){
        exportCustomers(input: $input, by: $by){
           uuid
           size
           key
           name
           status
           topic
           type
           url
           company
           created_at
           created_by
           updated_at
           updated_by
        }
    }
`
const MUTATION_UPDATE_ITEM = gql`
    mutation updateCustomer($input: UpdateCustomerInput!){
        updateCustomer(input: $input){
            id
            uuid
            code
            customer_id
            prefixname
            name
            firstname
            lastname
            type
            nationality
            status
            coming_type
            level
            mobile
            email
            gender
            birthday
            accommodation_uuids
            accommodations {
                id
                uuid
                code
                project_uuid
                project {
                    id
                    uuid
                    code
                    name
                    type
                    company
                }
                unit_number
                unit_type
                sale_price
                rent_price
            }
            address
            channel
            company
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`

// ====================== CREATE GQL ====================== //
const customerGQL = {
    query: {
        getItem: QUERY_ITEM,
        getItems: QUERY_ITEMS,
        getLookupItems: QUERY_LOOKUP_ITEMS,
        getItemsByPage: QUERY_ITEMS_BY_PAGE,
    },
    mutation: {
        createItem: MUTATION_CREATE_ITEM,
        createItems: MUTATION_CREATE_ITEMS,
        deleteItem: MUTATION_DELETE_ITEM,
        deleteItems: MUTATION_DELETE_ITEMS,
        exportItems: MUTATION_EXPORT_ITEMS,
        updateItem: MUTATION_UPDATE_ITEM,
    },
    response: {
        getItem: "customer",
        getItems: "customers",
        getLookupItems: "customers",
        getItemsByPage: "customersByPage",
        createItem: "createCustomer",
        createItems: "createCustomers",
        deleteItem: "deleteCustomer",
        deleteItems: "deleteCustomers",
        exportItems: "exportCustomers",
        updateItem: "updateCustomer",
    }
}

// ====================== EXPORT ====================== //
export {
    customerGQL,
}