// project import
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Routes from 'routes';
import ThemeCustomization from 'themes';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import Locales from 'components/Locales';
import RTLLayout from 'components/RTLLayout';
import ScrollTop from 'components/ScrollTop';
import Snackbar from 'components/@extended/Snackbar';
// import Snackbar from '@mui/material/Snackbar';
// import MuiAlert from '@mui/material/Alert';

// auth provider
// import { FirebaseProvider as AuthProvider } from 'contexts/FirebaseContext';
// import { AWSCognitoProvider as AuthProvider } from 'contexts/AWSCognitoContext';
// import { JWTProvider as AuthProvider } from 'contexts/JWTContext';
import { Auth0Provider as AuthProvider } from 'contexts/Auth0Context';

// ==============================|| APP - THEME, ROUTER, LOCAL  ||============================== //

const App = () => {

  useEffect(() => {
    console.log('App')
  }, []);


  return (
    <ThemeCustomization>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <RTLLayout>
          <Locales>
            <ScrollTop>
              <AuthProvider>
                <>
                  <Routes/>
                  <Snackbar />
                </>
              </AuthProvider>
            </ScrollTop>
          </Locales>
        </RTLLayout>
      </LocalizationProvider>
    </ThemeCustomization>
  );
}

export default App;