// action - state management
import { getLocalStorage, removeLocalStorage, setLocalStorage } from 'assets/js/hash';
import { client } from 'graphql/client';
import { userGQL } from 'graphql/user';
import axios from 'axios';
import config from '../../config';
import * as actionTypes from '../actions';
import { openSnackbar } from './snackbar';
import { setCount } from './file.reducer';

// ==============================|| STATE ||============================== //
const moduleConfig = {
    name: "user",
    item: "user",
    items: "users",
    actionTypes: {
        setItem: actionTypes.SET_USER,
        setItems: actionTypes.SET_USERS,
    },
    gql: userGQL,
}
export const initialState = {
    authUser: null,
    authUserAccount: null,
    user: null,
    users: [],
    fieldsForUpdates: [
        "uuid",
        "is_agree_to_tos_and_pp",
        "firstname",
        "lastname",
        "phone",
        "password",
        "license",
        "level",
        "permission",
        "profile_image",
        "updated_by",
    ],
};

// ==============================|| COMMON ACTIONS ||============================== //

export const getItem = (input, callback) => {
    console.log(`[[ CALL ${moduleConfig.name} getItem() ]]`, input)
    return (dispatch) => {
        client
            .query({
                query: moduleConfig.gql.query.getItem,
                variables: { input },
            })
            .then(({ data }) => {
                console.log('[[ RES ]]', data)
                let result = data?.[moduleConfig.gql.response.getItem];
                dispatch({ type: moduleConfig.actionTypes.setItem, payload: result });
                if (callback) callback(result, null);
                return
            })
            .catch((error) => {
                console.log('[[ ERROR ]]', error.message)
                dispatch({
                    type: actionTypes.SET_ERROR,
                    error,
                });
                if (callback) callback(null, error);
                return
            });
    };
}
export const getItems = (input, callback) => {
    console.log(`[[ CALL ${moduleConfig.name} getItems() ]]`, input)
    return (dispatch) => {
        client
            .query({
                query: moduleConfig.gql.query.getItems,
                variables: { input },
            })
            .then(({ data }) => {
                console.log('[[ RES ]]', data)
                let result = data?.[moduleConfig.gql.response.getItems];
                dispatch({ type: moduleConfig.actionTypes.setItems, payload: result });
                if (callback) callback(result, null);
                return
            })
            .catch((error) => {
                console.log('[[ ERROR ]]', error.message)
                dispatch({
                    type: actionTypes.SET_ERROR,
                    error,
                });
                if (callback) callback(null, error);
                return
            });
    };
}
export const getLookupItems = (input, callback) => {
    console.log(`[[ CALL ${moduleConfig.name} getLookupItems() ]]`, input)
    return (dispatch) => {
        client
            .query({
                query: moduleConfig.gql.query.getLookupItems,
                variables: { input },
            })
            .then(({ data }) => {
                console.log('[[ RES ]]', data)
                let result = data?.[moduleConfig.gql.response.getLookupItems];
                dispatch({ type: moduleConfig.actionTypes.setItems, payload: result });
                if (callback) callback(result, null);
                return
            })
            .catch((error) => {
                console.log('[[ ERROR ]]', error.message)
                dispatch({
                    type: actionTypes.SET_ERROR,
                    error,
                });
                if (callback) callback(null, error);
                return
            });
    };
}
export const getItemsByPage = (input, callback) => {
    console.log(`[[ CALL ${moduleConfig.name} getItemsByPage() ]]`, input)
    return (dispatch) => {
        client
            .query({
                query: moduleConfig.gql.query.getItemsByPage,
                variables: { input },
            })
            .then(({ data }) => {
                console.log('[[ RES ]]', data)
                let result = data?.[moduleConfig.gql.response.getItemsByPage]
                // dispatch({ type: moduleConfig.actionTypes.setItems, payload: result });
                if (callback) callback(result, null);
                return
            })
            .catch((error) => {
                console.log('[[ ERROR ]]', error.message)
                dispatch({
                    type: actionTypes.SET_ERROR,
                    error,
                });
                if (callback) callback(null, error);
                return
            });
    };
}
export const changePassword = (input, callback, update) => {
    console.log(`[[ CALL ${moduleConfig.name} changePassword() ]]`, input, update)
    return (dispatch) => {
        client
            .mutate({
                mutation: moduleConfig.gql.mutation.changePassword,
                variables: { input },
            })
            .then(({ data }) => {
                console.log('[[ RES ]]', data)
                let result = data?.[moduleConfig.gql.response.changePassword];
                dispatch(
                    openSnackbar({
                        open: true,
                        autoHideDuration: 5000,
                        message: 'Password changed successfully.',
                        variant: 'alert',
                        alert: {
                            variant: 'border',
                            icon: 'success',
                            color: 'success'
                        },
                        transition: 'SlideUp',
                        close: false,
                    })
                );
                if (callback) callback(result, null);
                if (update) {
                    // dispatch({ type: actionTypes.SET_AUTH_USER, payload: result });
                }
                return
            })
            .catch((error) => {
                console.log('[[ ERROR ]]', error.message)
                dispatch(
                    openSnackbar({
                        open: true,
                        autoHideDuration: 5000,
                        message: `${error?.response?.data?.message || error?.message}`,
                        variant: 'alert',
                        alert: {
                            variant: 'border',
                            icon: 'error',
                            color: 'error',
                        },
                        transition: 'SlideUp',
                        close: false,
                    })
                );
                if (callback) callback(null, error);
                return
            });
    };
}
export const createItem = (input, callback) => {
    console.log(`[[ CALL ${moduleConfig.name} createItem() ]]`, input)
    return (dispatch) => {
        client
            .mutate({
                mutation: moduleConfig.gql.mutation.createItem,
                variables: { input },
            })
            .then(({ data }) => {
                console.log('[[ RES ]]', data)
                let result = data?.[moduleConfig.gql.response.createItem];
                dispatch(
                    openSnackbar({
                        open: true,
                        autoHideDuration: 5000,
                        message: `Create ${moduleConfig.item} success`,
                        variant: 'alert',
                        alert: {
                            variant: 'border',
                            icon: 'success',
                            color: 'success'
                        },
                        transition: 'SlideUp',
                        close: false,
                    })
                );
                if (callback) callback(result, null);
                return
            })
            .catch((error) => {
                console.log('[[ ERROR ]]', error.message)
                dispatch(
                    openSnackbar({
                        open: true,
                        autoHideDuration: 5000,
                        message: `${error?.response?.data?.message || error?.message}`,
                        variant: 'alert',
                        alert: {
                            variant: 'border',
                            icon: 'error',
                            color: 'error',
                        },
                        transition: 'SlideUp',
                        close: false,
                    })
                );
                if (callback) callback(null, error);
                return
            });
    };
}
export const createItems = (input, callback) => {
    console.log(`[[ CALL ${moduleConfig.name} createItems() ]]`, input)
    return (dispatch) => {
        client
            .mutate({
                mutation: moduleConfig.gql.mutation.createItems,
                variables: { input },
            })
            .then(({ data }) => {
                console.log('[[ RES ]]', data)
                let result = data?.[moduleConfig.gql.response.createItems];
                dispatch(
                    openSnackbar({
                        open: true,
                        autoHideDuration: 5000,
                        message: `Create ${moduleConfig.items} success`,
                        variant: 'alert',
                        alert: {
                            variant: 'border',
                            icon: 'success',
                            color: 'success'
                        },
                        transition: 'SlideUp',
                        close: false,
                    })
                );
                if (callback) callback(result, null);
                return
            })
            .catch((error) => {
                console.log('[[ ERROR ]]', error.message)
                dispatch(
                    openSnackbar({
                        open: true,
                        autoHideDuration: 5000,
                        message: `${error?.response?.data?.message || error?.message}`,
                        variant: 'alert',
                        alert: {
                            variant: 'border',
                            icon: 'error',
                            color: 'error',
                        },
                        transition: 'SlideUp',
                        close: false,
                    })
                );
                if (callback) callback(null, error);
                return
            });
    };
}
export const deleteItem = (input, callback) => {
    console.log(`[[ CALL ${moduleConfig.name} deleteItem() ]]`, input)
    return (dispatch) => {
        client
            .mutate({
                mutation: moduleConfig.gql.mutation.deleteItem,
                variables: { input },
            })
            .then(({ data }) => {
                console.log('[[ RES ]]', data)
                let result = data?.[moduleConfig.gql.response.deleteItem];
                dispatch(
                    openSnackbar({
                        open: true,
                        autoHideDuration: 5000,
                        message: `Delete ${moduleConfig.item} success`,
                        variant: 'alert',
                        alert: {
                            variant: 'border',
                            icon: 'success',
                            color: 'success'
                        },
                        transition: 'SlideUp',
                        close: false,
                    })
                );
                if (callback) callback(result, null);
                return
            })
            .catch((error) => {
                console.log('[[ ERROR ]]', error.message)
                dispatch(
                    openSnackbar({
                        open: true,
                        autoHideDuration: 5000,
                        message: `${error?.response?.data?.message || error?.message}`,
                        variant: 'alert',
                        alert: {
                            variant: 'border',
                            icon: 'error',
                            color: 'error',
                        },
                        transition: 'SlideUp',
                        close: false,
                    })
                );
                if (callback) callback(null, error);
                return
            });
    };
}
export const deleteItems = (input, callback) => {
    console.log(`[[ CALL ${moduleConfig.name} deleteItems() ]]`, input)
    return (dispatch) => {
        client
            .mutate({
                mutation: moduleConfig.gql.mutation.deleteItems,
                variables: { input },
            })
            .then(({ data }) => {
                console.log('[[ RES ]]', data)
                let result = data?.[moduleConfig.gql.response.deleteItems];
                dispatch(
                    openSnackbar({
                        open: true,
                        autoHideDuration: 5000,
                        message: `Delete ${moduleConfig.items} success`,
                        variant: 'alert',
                        alert: {
                            variant: 'border',
                            icon: 'success',
                            color: 'success'
                        },
                        transition: 'SlideUp',
                        close: false,
                    })
                );
                if (callback) callback(result, null);
                return
            })
            .catch((error) => {
                console.log('[[ ERROR ]]', error.message)
                dispatch(
                    openSnackbar({
                        open: true,
                        autoHideDuration: 5000,
                        message: `${error?.response?.data?.message || error?.message}`,
                        variant: 'alert',
                        alert: {
                            variant: 'border',
                            icon: 'error',
                            color: 'error',
                        },
                        transition: 'SlideUp',
                        close: false,
                    })
                );
                if (callback) callback(null, error);
                return
            });
    };
}
export const forgotPassword = (input, callback) => {
    console.log(`[[ CALL ${moduleConfig.name} forgotPassword() ]]`, input)
    return (dispatch) => {
        return axios({
            method: "post",
            url: `${config.server.API_ENDPOINT}/auth/forgot-password`,
            data: input,
        }).then(response => {
            console.log('[[ RES ]]', response)
            let { status, message } = response.data;
            dispatch(
                openSnackbar({
                    open: true,
                    autoHideDuration: 5000,
                    message: `${message}`,
                    variant: 'alert',
                    alert: {
                        variant: 'border',
                        icon: 'success',
                        color: 'success'
                    },
                    transition: 'SlideUp',
                    close: false,
                })
            );
            if (callback) callback(response.data, null);
            return
        }).catch((error) => {
            console.log('[[ ERROR ]]', error.message)
            dispatch(
                openSnackbar({
                    open: true,
                    autoHideDuration: 5000,
                    message: `${error?.response?.data?.message || error?.message}`,
                    variant: 'alert',
                    alert: {
                        variant: 'border',
                        icon: 'error',
                        color: 'error',
                    },
                    transition: 'SlideUp',
                    close: false,
                })
            );
            if (callback) callback(null, error);
            return
        });
    };
}
export const register = (input, callback) => {
    console.log(`[[ CALL ${moduleConfig.name} register() ]]`, input)
    return (dispatch) => {
        return axios({
            method: "post",
            url: `${config.server.API_ENDPOINT}/auth/register`,
            data: input,
        }).then(response => {
            console.log('[[ RES ]]', response)
            let { status, message } = response.data;
            dispatch(
                openSnackbar({
                    open: true,
                    autoHideDuration: 5000,
                    message: `${message}`,
                    variant: 'alert',
                    alert: {
                        variant: 'border',
                        icon: 'success',
                        color: 'success'
                    },
                    transition: 'SlideUp',
                    close: false,
                })
            );
            if (callback) callback(response.data, null);
            return
        }).catch((error) => {
            console.log('[[ ERROR ]]', error.message)
            dispatch(
                openSnackbar({
                    open: true,
                    autoHideDuration: 5000,
                    message: `${error?.response?.data?.message || error?.message}`,
                    variant: 'alert',
                    alert: {
                        variant: 'border',
                        icon: 'error',
                        color: 'error',
                    },
                    transition: 'SlideUp',
                    close: false,
                })
            );
            if (callback) callback(null, error);
            return
        });
    };
}
export const resendCode = (input, callback) => {
    console.log(`[[ CALL ${moduleConfig.name} resendCode() ]]`, input)
    return (dispatch) => {
        return axios({
            method: "post",
            url: `${config.server.API_ENDPOINT}/auth/resend-code`,
            data: input,
        }).then(response => {
            console.log('[[ RES ]]', response)
            let { status, message } = response.data;
            dispatch(
                openSnackbar({
                    open: true,
                    autoHideDuration: 5000,
                    message: `${message}`,
                    variant: 'alert',
                    alert: {
                        variant: 'border',
                        icon: 'success',
                        color: 'success'
                    },
                    transition: 'SlideUp',
                    close: false,
                })
            );
            if (callback) callback(response.data, null);
            return
        }).catch((error) => {
            console.log('[[ ERROR ]]', error.message)
            dispatch(
                openSnackbar({
                    open: true,
                    autoHideDuration: 5000,
                    message: `${error?.response?.data?.message || error?.message}`,
                    variant: 'alert',
                    alert: {
                        variant: 'border',
                        icon: 'error',
                        color: 'error',
                    },
                    transition: 'SlideUp',
                    close: false,
                })
            );
            if (callback) callback(null, error);
            return
        });
    };
}
export const resetPassword = (token, input, callback) => {
    console.log(`[[ CALL ${moduleConfig.name} resetPassword() ]]`, token)
    return (dispatch) => {
        return axios({
            method: "patch",
            url: `${config.server.API_ENDPOINT}/auth/reset-password/${token}`,
            data: input,
        }).then(response => {
            console.log('[[ RES ]]', response, callback)
            let { status, message } = response.data;
            dispatch(
                openSnackbar({
                    open: true,
                    autoHideDuration: 5000,
                    message: `${'Successfuly reset password.'}`,
                    variant: 'alert',
                    alert: {
                        variant: 'border',
                        icon: 'success',
                        color: 'success'
                    },
                    transition: 'SlideUp',
                    close: false,
                })
            );
            if (callback) callback(response.data, null);
            return
        }).catch((error) => {
            console.log('[[ ERROR ]]', error.message)
            dispatch(
                openSnackbar({
                    open: true,
                    autoHideDuration: 5000,
                    message: `${error?.response?.data?.message || error?.message}`,
                    variant: 'alert',
                    alert: {
                        variant: 'border',
                        icon: 'error',
                        color: 'error',
                    },
                    transition: 'SlideUp',
                    close: false,
                })
            );
            if (callback) callback(null, error);
            return
        });
    };
}
export const exportItems = (input, by, callback) => {
    console.log(`[[ CALL ${moduleConfig.name} exportItems() ]]`, input)
    return (dispatch) => {
        client
            .mutate({
                mutation: moduleConfig.gql.mutation.exportItems,
                variables: { input, by },
            })
            .then(({ data }) => {
                console.log('[[ RES ]]', data)
                let result = data?.[moduleConfig.gql.response.exportItems];
                dispatch(
                    openSnackbar({
                        open: true,
                        autoHideDuration: 5000,
                        message: `Export ${result?.name || 'file'} success`,
                        variant: 'alert',
                        alert: {
                            variant: 'border',
                            icon: 'success',
                            color: 'success'
                        },
                        transition: 'SlideUp',
                        close: false,
                    })
                );
                dispatch(setCount(1, (res)=>{}));
                if (callback) callback(result, null);
                return
            })
            .catch((error) => {
                console.log('[[ ERROR ]]', error.message)
                dispatch(
                    openSnackbar({
                        open: true,
                        autoHideDuration: 5000,
                        message: `${error?.response?.data?.message || error?.message}`,
                        variant: 'alert',
                        alert: {
                            variant: 'border',
                            icon: 'error',
                            color: 'error',
                        },
                        transition: 'SlideUp',
                        close: false,
                    })
                );
                if (callback) callback(null, error);
                return
            });
    };
}
export const updateItem = (input, callback, update) => {
    console.log(`[[ CALL ${moduleConfig.name} updateItem() ]]`, input, update)
    return (dispatch) => {
        client
            .mutate({
                mutation: moduleConfig.gql.mutation.updateItem,
                variables: { input },
            })
            .then(({ data }) => {
                console.log('[[ RES ]]', data)
                let result = data?.[moduleConfig.gql.response.updateItem];
                dispatch(
                    openSnackbar({
                        open: true,
                        autoHideDuration: 5000,
                        message: `Update ${moduleConfig.item} success`,
                        variant: 'alert',
                        alert: {
                            variant: 'border',
                            icon: 'success',
                            color: 'success'
                        },
                        transition: 'SlideUp',
                        close: false,
                    })
                );
                if (callback) callback(result, null);
                if (update) {
                    dispatch({ type: actionTypes.SET_AUTH_USER, payload: result });
                }
                return
            })
            .catch((error) => {
                console.log('[[ ERROR ]]', error.message)
                dispatch(
                    openSnackbar({
                        open: true,
                        autoHideDuration: 5000,
                        message: `${error?.response?.data?.message || error?.message}`,
                        variant: 'alert',
                        alert: {
                            variant: 'border',
                            icon: 'error',
                            color: 'error',
                        },
                        transition: 'SlideUp',
                        close: false,
                    })
                );
                if (callback) callback(null, error);
                return
            });
    };
}
export const verifyUser = (code, callback) => {
    console.log(`[[ CALL ${moduleConfig.name} verifyUser() ]]`, code)
    return (dispatch) => {
        return axios({
            method: "get",
            url: `${config.server.API_ENDPOINT}/auth/verify-email/${code}`,
        }).then(response => {
            console.log('[[ RES ]]', response, callback)
            let { status, message } = response.data;
            dispatch(
                openSnackbar({
                    open: true,
                    autoHideDuration: 5000,
                    message: `${message}`,
                    variant: 'alert',
                    alert: {
                        variant: 'border',
                        icon: 'success',
                        color: 'success'
                    },
                    transition: 'SlideUp',
                    close: false,
                })
            );
            if (callback) callback(response.data, null);
            return
        }).catch((error) => {
            console.log('[[ ERROR ]]', error.message)
            dispatch(
                openSnackbar({
                    open: true,
                    autoHideDuration: 5000,
                    message: `${error?.response?.data?.message || error?.message}`,
                    variant: 'alert',
                    alert: {
                        variant: 'border',
                        icon: 'error',
                        color: 'error',
                    },
                    transition: 'SlideUp',
                    close: false,
                })
            );
            if (callback) callback(null, error);
            return
        });
    };
}

// ==============================|| CUSTOM ACTIONS ||============================== //

// ==============================|| REDUCER ||============================== //

const userReducer = (state = initialState, action) => {
    let { payload } = action;
    switch (action.type) {

        // ------------- COMMON ACTIONS ------------- //
        case moduleConfig.actionTypes.setItem:
            return {
                ...state,
                [`${moduleConfig.item}`]: payload,
            };
        case moduleConfig.actionTypes.setItems:
            return {
                ...state,
                [`${moduleConfig.items}`]: payload,
            };

        // ------------- CUSTOM ACTIONS ------------- //
        // ------------- ------------- ------------- //
        default:
            return state;
    }
};

export default userReducer;
