import { gql } from '@apollo/client';

// ====================== QUERY ====================== //
const QUERY_ITEM = gql`
    query department($input: DepartmentInput!){
        department(input: $input){
            id
            uuid
            code
            name
            report_to
            report_dept {
                id
                uuid
                code
                name
            }
            assign_to
            assign_dept {
                id
                uuid
                code
                name
            }
            company
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`
const QUERY_ITEMS = gql`
    query departments($input: DepartmentsInput){
        departments(input: $input){
            id
            uuid
            code
            name
            report_to
            report_dept {
                id
                uuid
                code
                name
            }
            assign_to
            assign_dept {
                id
                uuid
                code
                name
            }
            company
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`
const QUERY_LOOKUP_ITEMS = gql`
    query departments($input: DepartmentsInput){
        departments(input: $input){
            # id
            uuid
            code
            name
            # report_to
            # report_dept {
            #     id
            #     uuid
            #     code
            #     name
            # }
            # assign_to
            # assign_dept {
            #     id
            #     uuid
            #     code
            #     name
            # }
            company
            # created_at
            # created_by
            # updated_at
            # updated_by
        }
    }
`
const QUERY_ITEMS_BY_PAGE = gql`
    query departmentsByPage($input: DepartmentsByPageInput!){
        departmentsByPage(input: $input){
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
            currentPage
            prevPage
            nextPage
            totalPages
            totalItems
            departments{
                id
                uuid
                code
                name
                report_to
                report_dept {
                    id
                    uuid
                    code
                    name
                }
                assign_to
                assign_dept {
                    id
                    uuid
                    code
                    name
                }
                company
                created_at
                created_by
                updated_at
                updated_by
            }
        }
    }
`
// ====================== MUTATION ====================== //
const MUTATION_CREATE_ITEM = gql`
    mutation createDepartment($input: CreateDepartmentInput!){
        createDepartment(input: $input){
            id
            uuid
            code
            name
            report_to
            report_dept {
                id
                uuid
                code
                name
            }
            assign_to
            assign_dept {
                id
                uuid
                code
                name
            }
            company
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`
const MUTATION_CREATE_ITEMS = gql`
    mutation createDepartments($input: [CreateDepartmentInput]!){
        createDepartments(input: $input){
            id
            uuid
            code
            name
            report_to
            report_dept {
                id
                uuid
                code
                name
            }
            assign_to
            assign_dept {
                id
                uuid
                code
                name
            }
            company
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`
const MUTATION_DELETE_ITEM = gql`
    mutation deleteDepartment($input: DeleteDepartmentInput!){
        deleteDepartment(input: $input){
            id
            uuid
            code
            name
            report_to
            report_dept {
                id
                uuid
                code
                name
            }
            assign_to
            assign_dept {
                id
                uuid
                code
                name
            }
            company
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`
const MUTATION_DELETE_ITEMS = gql`
    mutation deleteDepartments($input: DeleteDepartmentInput!){
        deleteDepartments(input: $input){
            id
            uuid
            code
            name
            report_to
            report_dept {
                id
                uuid
                code
                name
            }
            assign_to
            assign_dept {
                id
                uuid
                code
                name
            }
            company
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`
const MUTATION_EXPORT_ITEMS = gql`
    mutation exportDepartments($input: DepartmentsInput!, $by: ActionByInput!){
        exportDepartments(input: $input, by: $by){
           uuid
           size
           key
           name
           status
           topic
           type
           url
           company
           created_at
           created_by
           updated_at
           updated_by
        }
    }
`
const MUTATION_UPDATE_ITEM = gql`
    mutation updateDepartment($input: UpdateDepartmentInput!){
        updateDepartment(input: $input){
            id
            uuid
            code
            name
            report_to
            report_dept {
                id
                uuid
                code
                name
            }
            assign_to
            assign_dept {
                id
                uuid
                code
                name
            }
            company
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`

// ====================== CREATE GQL ====================== //
const departmentGQL = {
    query: {
        getItem: QUERY_ITEM,
        getItems: QUERY_ITEMS,
        getLookupItems: QUERY_LOOKUP_ITEMS,
        getItemsByPage: QUERY_ITEMS_BY_PAGE,
    },
    mutation: {
        createItem: MUTATION_CREATE_ITEM,
        createItems: MUTATION_CREATE_ITEMS,
        deleteItem: MUTATION_DELETE_ITEM,
        deleteItems: MUTATION_DELETE_ITEMS,
        exportItems: MUTATION_EXPORT_ITEMS,
        updateItem: MUTATION_UPDATE_ITEM,
    },
    response: {
        getItem: "department",
        getItems: "departments",
        getLookupItems: "departments",
        getItemsByPage: "departmentsByPage",
        createItem: "createDepartment",
        createItems: "createDepartments",
        deleteItem: "deleteDepartment",
        deleteItems: "deleteDepartments",
        deleteItems: "deleteDepartments",
        exportItems: "exportDepartments",
        updateItem: "updateDepartment",
    }
}

// ====================== EXPORT ====================== //
export {
    departmentGQL,
}