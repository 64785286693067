// third-party
import { FormattedMessage } from 'react-intl';

// assets
import {
  AppstoreOutlined,
  BuildOutlined,
  FormOutlined,
  SettingOutlined,
  SendOutlined,
  NotificationOutlined
} from '@ant-design/icons';

// icons
const icons = {
  AppstoreOutlined,
  BuildOutlined,
  FormOutlined,
  SettingOutlined,
  SendOutlined,
  NotificationOutlined
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const surveySetup = {
  id: 'group-survey-setup',
  title: <FormattedMessage id="survey-setup" />,
  type: 'group',
  children: [
    {
      id: 'survey-summary',
      title: <FormattedMessage id="survey-summary" />,
      type: 'item',
      url: '/survey-setup/survey-summary',
      icon: icons.AppstoreOutlined,
      breadcrumbs: false,
    },
    {
      id: 'questionnaire-builder',
      title: <FormattedMessage id="questionnaire-builder" />,
      description: "Create questionnaire builder tools",
      type: 'item',
      url: '/survey-setup/questionnaire-builder',
      icon: icons.FormOutlined,
      breadcrumbs: false,
    },
    {
      id: 'invitations',
      title: <FormattedMessage id="invitations" />,
      description: "Invitation setting & Send survey via SMS or Email",
      type: 'item',
      url: '/survey-setup/invitations',
      icon: icons.SendOutlined,
      breadcrumbs: false,
    },
  ]
};

export default surveySetup;
