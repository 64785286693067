import { gql } from '@apollo/client';

// ====================== QUERY ====================== //
const QUERY_ITEM = gql`
    query invitation($input: InvitationInput!){
        invitation(input: $input){
            id
            uuid
            name
            type
            ref_uuid
            survey_uuid
            survey {
                id
                uuid
                code
                ref_uuid
                title
                description
                company
            }
            questionnaire_uuid
            questionnaire {
                id
                company
                uuid
                code
                ref_uuid
                title
                description
                payload
                touchpoint_uuid
                touchpoint {
                    id
                    uuid
                    code
                    name
                    name_en
                    name_th
                    company
                }
            }
            prefill_config_uuid
            prefill_config {
                id
                uuid
                name
                type
                invitation_uuid
                fields
                company
            }
            receiver_message_template_uuids
            receiver_message_templates {
                id
                uuid
                name
                invitation_uuid
                type
                language
                is_default
                header
                body
                company
            }
            token_uuids
            tokens {
                id
                uuid
                invitation_uuid
                payload
                email
                phone_number
                email_status
                sms_status
                token_usage
                company
            }
            policy_config_uuid
            policy_config {
                id
                uuid
                name
                type
                invitation_uuid
                alert_pause_notification_status
                auto_submit_response_status
                token_expired_date
                token_usage
                invitation_do_not_on_pending
                invitation_status
                invitation_days
                invitation_start_time
                invitation_end_time
                company
            }
            alert_config_uuids
            alert_configs {
                id
                uuid
                name
                type
                invitation_uuid
                questionnaire_uuid
                is_default
                conditions
                alert_message_template_sms_uuid
                alert_message_template_sms {
                    id
                    uuid
                    name
                    invitation_uuid
                    type
                    language
                    is_default
                    header
                    body
                    company
                }
                alert_message_template_email_uuid
                alert_message_template_email {
                    id
                    uuid
                    name
                    invitation_uuid
                    type
                    language
                    is_default
                    header
                    body
                    company
                }
                phone_numbers
                recipients_to
                recipients_cc
            }
            company
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`
const QUERY_ITEM_CUT = gql`
    query invitation($input: InvitationInput!){
        invitation(input: $input){
            id
            uuid
            name
            type
            ref_uuid
            survey_uuid
            survey {
                id
                uuid
                code
                ref_uuid
                title
                description
                company
            }
            questionnaire_uuid
            questionnaire {
                id
                company
                uuid
                code
                ref_uuid
                title
                description
                # payload
                touchpoint_uuid
                touchpoint {
                    id
                    uuid
                    code
                    name
                    name_en
                    name_th
                    company
                }
            }
            prefill_config_uuid
            prefill_config {
                id
                uuid
                name
                type
                invitation_uuid
                fields
                company
            }
            # receiver_message_template_uuids
            # receiver_message_templates {
            #     id
            #     uuid
            #     name
            #     invitation_uuid
            #     type
            #     language
            #     is_default
            #     header
            #     body
            #     company
            # }
            # token_uuids
            # tokens {
            #     id
            #     uuid
            #     invitation_uuid
            #     payload
            #     email
            #     phone_number
            #     email_status
            #     sms_status
            #     token_usage
            #     company
            # }
            policy_config_uuid
            policy_config {
                id
                uuid
                name
                type
                invitation_uuid
                alert_pause_notification_status
                auto_submit_response_status
                token_expired_date
                token_usage
                invitation_do_not_on_pending
                invitation_status
                invitation_days
                invitation_start_time
                invitation_end_time
                company
            }
            # alert_config_uuids
            # alert_configs {
            #     id
            #     uuid
            #     name
            #     type
            #     invitation_uuid
            #     questionnaire_uuid
            #     is_default
            #     conditions
            #     alert_message_template_sms_uuid
            #     alert_message_template_sms {
            #         id
            #         uuid
            #         name
            #         invitation_uuid
            #         type
            #         language
            #         is_default
            #         header
            #         body
            #         company
            #     }
            #     alert_message_template_email_uuid
            #     alert_message_template_email {
            #         id
            #         uuid
            #         name
            #         invitation_uuid
            #         type
            #         language
            #         is_default
            #         header
            #         body
            #         company
            #     }
            #     phone_numbers
            #     recipients_to
            #     recipients_cc
            # }
            company
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`
const QUERY_ITEMS = gql`
    query invitations($input: InvitationsInput){
        invitations(input: $input){
            id
            uuid
            name
            type
            ref_uuid
            survey_uuid
            survey {
                id
                uuid
                code
                ref_uuid
                title
                description
                company
            }
            questionnaire_uuid
            questionnaire {
                id
                company
                uuid
                code
                ref_uuid
                title
                description
                touchpoint_uuid
                touchpoint {
                    id
                    uuid
                    code
                    name
                    name_en
                    name_th
                    company
                }
            }
            prefill_config_uuid
            prefill_config {
                id
                uuid
                name
                type
                invitation_uuid
                fields
                company
            }
            receiver_message_template_uuids
            receiver_message_templates {
                id
                uuid
                name
                invitation_uuid
                type
                language
                is_default
                header
                body
                company
            }
            token_uuids
            tokens {
                id
                uuid
                invitation_uuid
                payload
                email
                phone_number
                email_status
                sms_status
                token_usage
                company
            }
            policy_config_uuid
            policy_config {
                id
                uuid
                name
                type
                invitation_uuid
                alert_pause_notification_status
                auto_submit_response_status
                token_expired_date
                token_usage
                invitation_do_not_on_pending
                invitation_status
                invitation_days
                invitation_start_time
                invitation_end_time
                company
            }
            alert_config_uuids
            alert_configs {
                id
                uuid
                name
                type
                invitation_uuid
                questionnaire_uuid
                is_default
                conditions
                alert_message_template_sms_uuid
                alert_message_template_sms {
                    id
                    uuid
                    name
                    invitation_uuid
                    type
                    language
                    is_default
                    header
                    body
                    company
                }
                alert_message_template_email_uuid
                alert_message_template_email {
                    id
                    uuid
                    name
                    invitation_uuid
                    type
                    language
                    is_default
                    header
                    body
                    company
                }
                phone_numbers
                recipients_to
                recipients_cc
            }
            company
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`
const QUERY_ITEMS_ON_BUILDER = gql`
    query invitations($input: InvitationsInput){
        invitations(input: $input){
            id
            uuid
            name
            type
            ref_uuid
            survey_uuid
            survey {
                id
                uuid
                code
                ref_uuid
                title
                description
                company
            }
            questionnaire_uuid
            questionnaire {
                id
                company
                uuid
                code
                ref_uuid
                title
                description
                touchpoint_uuid
                touchpoint {
                    id
                    uuid
                    code
                    name
                    name_en
                    name_th
                    company
                }
            }
            # prefill_config_uuid
            # prefill_config {
            #     id
            #     uuid
            #     name
            #     type
            #     invitation_uuid
            #     fields
            #     company
            # }
            # receiver_message_template_uuids
            # receiver_message_templates {
            #     id
            #     uuid
            #     name
            #     invitation_uuid
            #     type
            #     language
            #     is_default
            #     header
            #     body
            #     company
            # }
            # token_uuids
            tokens {
            #     id
                uuid
            #     invitation_uuid
            #     payload
            #     email
            #     phone_number
            #     email_status
            #     sms_status
            #     token_usage
            #     company
            }
            # policy_config_uuid
            # policy_config {
            #     id
            #     uuid
            #     name
            #     type
            #     invitation_uuid
            #     alert_pause_notification_status
            #     auto_submit_response_status
            #     token_expired_date
            #     token_usage
            #     invitation_do_not_on_pending
            #     invitation_status
            #     invitation_days
            #     invitation_start_time
            #     invitation_end_time
            #     company
            # }
            # alert_config_uuids
            # alert_configs {
            #     id
            #     uuid
            #     name
            #     type
            #     invitation_uuid
            #     questionnaire_uuid
            #     is_default
            #     conditions
            #     alert_message_template_sms_uuid
            #     alert_message_template_sms {
            #         id
            #         uuid
            #         name
            #         invitation_uuid
            #         type
            #         language
            #         is_default
            #         header
            #         body
            #         company
            #     }
            #     alert_message_template_email_uuid
            #     alert_message_template_email {
            #         id
            #         uuid
            #         name
            #         invitation_uuid
            #         type
            #         language
            #         is_default
            #         header
            #         body
            #         company
            #     }
            #     phone_numbers
            #     recipients_to
            #     recipients_cc
            # }
            company
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`
const QUERY_LOOKUP_ITEMS = gql`
    query invitations($input: InvitationsInput){
        invitations(input: $input){
            # id
            uuid
            name
            type
            ref_uuid
            # survey_uuid
            # survey {
            #     id
            #     uuid
            #     code
            #     ref_uuid
            #     title
            #     description
            #     company
            # }
            # questionnaire_uuid
            # questionnaire {
            #     id
            #     company
            #     uuid
            #     code
            #     ref_uuid
            #     title
            #     description
            #     touchpoint_uuid
            #     touchpoint {
            #         id
            #         uuid
            #         code
            #         name
            #         name_en
            #         name_th
            #         company
            #     }
            # }
            # prefill_config_uuid
            # prefill_config {
            #     id
            #     uuid
            #     name
            #     type
            #     invitation_uuid
            #     fields
            #     company
            # }
            # receiver_message_template_uuids
            # receiver_message_templates {
            #     id
            #     uuid
            #     name
            #     invitation_uuid
            #     type
            #     language
            #     is_default
            #     header
            #     body
            #     company
            # }
            # token_uuids
            # tokens {
            #     id
            #     uuid
            #     invitation_uuid
            #     payload
            #     email
            #     phone_number
            #     email_status
            #     sms_status
            #     token_usage
            #     company
            # }
            # policy_config_uuid
            # policy_config {
            #     id
            #     uuid
            #     name
            #     type
            #     invitation_uuid
            #     alert_pause_notification_status
            #     auto_submit_response_status
            #     token_expired_date
            #     token_usage
            #     invitation_do_not_on_pending
            #     invitation_status
            #     invitation_days
            #     invitation_start_time
            #     invitation_end_time
            #     company
            # }
            # alert_config_uuids
            # alert_configs {
            #     id
            #     uuid
            #     name
            #     type
            #     invitation_uuid
            #     questionnaire_uuid
            #     is_default
            #     conditions
            #     alert_message_template_sms_uuid
            #     alert_message_template_sms {
            #         id
            #         uuid
            #         name
            #         invitation_uuid
            #         type
            #         language
            #         is_default
            #         header
            #         body
            #         company
            #     }
            #     alert_message_template_email_uuid
            #     alert_message_template_email {
            #         id
            #         uuid
            #         name
            #         invitation_uuid
            #         type
            #         language
            #         is_default
            #         header
            #         body
            #         company
            #     }
            #     phone_numbers
            #     recipients_to
            #     recipients_cc
            # }
            company
            # created_at
            # created_by
            # updated_at
            # updated_by
        }
    }
`
const QUERY_ITEMS_BY_PAGE = gql`
    query invitationsByPage($input: InvitationsByPageInput!){
        invitationsByPage(input: $input){
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
            currentPage
            prevPage
            nextPage
            totalPages
            totalItems
            invitations{
                id
                uuid
                name
                type
                ref_uuid
                survey_uuid
                survey {
                    id
                    uuid
                    code
                    ref_uuid
                    title
                    description
                    company
                }
                questionnaire_uuid
                questionnaire {
                    id
                    company
                    uuid
                    code
                    ref_uuid
                    title
                    description
                    touchpoint_uuid
                    touchpoint {
                        id
                        uuid
                        code
                        name
                        name_en
                        name_th
                        company
                    }
                }
                prefill_config_uuid
                prefill_config {
                    id
                    uuid
                    name
                    type
                    invitation_uuid
                    fields
                    company
                }
                receiver_message_template_uuids
                receiver_message_templates {
                    id
                    uuid
                    name
                    invitation_uuid
                    type
                    language
                    is_default
                    header
                    body
                    company
                }
                token_uuids
                tokens {
                    id
                    uuid
                    invitation_uuid
                    payload
                    email
                    phone_number
                    email_status
                    sms_status
                    token_usage
                    company
                }
                policy_config_uuid
                policy_config {
                    id
                    uuid
                    name
                    type
                    invitation_uuid
                    alert_pause_notification_status
                    auto_submit_response_status
                    token_expired_date
                    token_usage
                    invitation_do_not_on_pending
                    invitation_status
                    invitation_days
                    invitation_start_time
                    invitation_end_time
                    company
                }
                alert_config_uuids
                alert_configs {
                    id
                    uuid
                    name
                    type
                    invitation_uuid
                    questionnaire_uuid
                    is_default
                    conditions
                    alert_message_template_sms_uuid
                    alert_message_template_sms {
                        id
                        uuid
                        name
                        invitation_uuid
                        type
                        language
                        is_default
                        header
                        body
                        company
                    }
                    alert_message_template_email_uuid
                    alert_message_template_email {
                        id
                        uuid
                        name
                        invitation_uuid
                        type
                        language
                        is_default
                        header
                        body
                        company
                    }
                    phone_numbers
                    recipients_to
                    recipients_cc
                }
                company
                created_at
                created_by
                updated_at
                updated_by
            }
        }
    }
`
// ====================== MUTATION ====================== //
const MUTATION_CREATE_ITEM = gql`
    mutation createInvitation($input: CreateInvitationInput!){
        createInvitation(input: $input){
            id
            uuid
            name
            type
            ref_uuid
            survey_uuid
            survey {
                id
                uuid
                code
                ref_uuid
                title
                description
                company
            }
            questionnaire_uuid
            questionnaire {
                id
                company
                uuid
                code
                ref_uuid
                title
                description
                touchpoint_uuid
                touchpoint {
                    id
                    uuid
                    code
                    name
                    name_en
                    name_th
                    company
                }
            }
            prefill_config_uuid
            prefill_config {
                id
                uuid
                name
                type
                invitation_uuid
                fields
                company
            }
            receiver_message_template_uuids
            receiver_message_templates {
                id
                uuid
                name
                invitation_uuid
                type
                language
                is_default
                header
                body
                company
            }
            token_uuids
            tokens {
                id
                uuid
                invitation_uuid
                payload
                email
                phone_number
                email_status
                sms_status
                token_usage
                company
            }
            policy_config_uuid
            policy_config {
                id
                uuid
                name
                type
                invitation_uuid
                alert_pause_notification_status
                auto_submit_response_status
                token_expired_date
                token_usage
                invitation_do_not_on_pending
                invitation_status
                invitation_days
                invitation_start_time
                invitation_end_time
                company
            }
            alert_config_uuids
            alert_configs {
                id
                uuid
                name
                type
                invitation_uuid
                questionnaire_uuid
                is_default
                conditions
                alert_message_template_sms_uuid
                alert_message_template_sms {
                    id
                    uuid
                    name
                    invitation_uuid
                    type
                    language
                    is_default
                    header
                    body
                    company
                }
                alert_message_template_email_uuid
                alert_message_template_email {
                    id
                    uuid
                    name
                    invitation_uuid
                    type
                    language
                    is_default
                    header
                    body
                    company
                }
                phone_numbers
                recipients_to
                recipients_cc
            }
            company
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`
const MUTATION_CREATE_ITEMS = gql`
    mutation createInvitations($input: [CreateInvitationInput]!){
        createInvitations(input: $input){
            id
            uuid
            name
            type
            ref_uuid
            survey_uuid
            survey {
                id
                uuid
                code
                ref_uuid
                title
                description
                company
            }
            questionnaire_uuid
            questionnaire {
                id
                company
                uuid
                code
                ref_uuid
                title
                description
                touchpoint_uuid
                touchpoint {
                    id
                    uuid
                    code
                    name
                    name_en
                    name_th
                    company
                }
            }
            prefill_config_uuid
            prefill_config {
                id
                uuid
                name
                type
                invitation_uuid
                fields
                company
            }
            receiver_message_template_uuids
            receiver_message_templates {
                id
                uuid
                name
                invitation_uuid
                type
                language
                is_default
                header
                body
                company
            }
            token_uuids
            tokens {
                id
                uuid
                invitation_uuid
                payload
                email
                phone_number
                email_status
                sms_status
                token_usage
                company
            }
            policy_config_uuid
            policy_config {
                id
                uuid
                name
                type
                invitation_uuid
                alert_pause_notification_status
                auto_submit_response_status
                token_expired_date
                token_usage
                invitation_do_not_on_pending
                invitation_status
                invitation_days
                invitation_start_time
                invitation_end_time
                company
            }
            alert_config_uuids
            alert_configs {
                id
                uuid
                name
                type
                invitation_uuid
                questionnaire_uuid
                is_default
                conditions
                alert_message_template_sms_uuid
                alert_message_template_sms {
                    id
                    uuid
                    name
                    invitation_uuid
                    type
                    language
                    is_default
                    header
                    body
                    company
                }
                alert_message_template_email_uuid
                alert_message_template_email {
                    id
                    uuid
                    name
                    invitation_uuid
                    type
                    language
                    is_default
                    header
                    body
                    company
                }
                phone_numbers
                recipients_to
                recipients_cc
            }
            company
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`
const MUTATION_DELETE_ITEM = gql`
    mutation deleteInvitation($input: DeleteInvitationInput!){
        deleteInvitation(input: $input){
            id
            uuid
            name
            type
            ref_uuid
            survey_uuid
            survey {
                id
                uuid
                code
                ref_uuid
                title
                description
                company
            }
            questionnaire_uuid
            questionnaire {
                id
                company
                uuid
                code
                ref_uuid
                title
                description
                touchpoint_uuid
                touchpoint {
                    id
                    uuid
                    code
                    name
                    name_en
                    name_th
                    company
                }
            }
            prefill_config_uuid
            prefill_config {
                id
                uuid
                name
                type
                invitation_uuid
                fields
                company
            }
            receiver_message_template_uuids
            receiver_message_templates {
                id
                uuid
                name
                invitation_uuid
                type
                language
                is_default
                header
                body
                company
            }
            token_uuids
            tokens {
                id
                uuid
                invitation_uuid
                payload
                email
                phone_number
                email_status
                sms_status
                token_usage
                company
            }
            policy_config_uuid
            policy_config {
                id
                uuid
                name
                type
                invitation_uuid
                alert_pause_notification_status
                auto_submit_response_status
                token_expired_date
                token_usage
                invitation_do_not_on_pending
                invitation_status
                invitation_days
                invitation_start_time
                invitation_end_time
                company
            }
            alert_config_uuids
            alert_configs {
                id
                uuid
                name
                type
                invitation_uuid
                questionnaire_uuid
                is_default
                conditions
                alert_message_template_sms_uuid
                alert_message_template_sms {
                    id
                    uuid
                    name
                    invitation_uuid
                    type
                    language
                    is_default
                    header
                    body
                    company
                }
                alert_message_template_email_uuid
                alert_message_template_email {
                    id
                    uuid
                    name
                    invitation_uuid
                    type
                    language
                    is_default
                    header
                    body
                    company
                }
                phone_numbers
                recipients_to
                recipients_cc
            }
            company
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`
const MUTATION_DELETE_ITEMS = gql`
    mutation deleteInvitations($input: DeleteInvitationInput!){
        deleteInvitations(input: $input){
            id
            uuid
            name
            type
            ref_uuid
            survey_uuid
            survey {
                id
                uuid
                code
                ref_uuid
                title
                description
                company
            }
            questionnaire_uuid
            questionnaire {
                id
                company
                uuid
                code
                ref_uuid
                title
                description
                touchpoint_uuid
                touchpoint {
                    id
                    uuid
                    code
                    name
                    name_en
                    name_th
                    company
                }
            }
            prefill_config_uuid
            prefill_config {
                id
                uuid
                name
                type
                invitation_uuid
                fields
                company
            }
            receiver_message_template_uuids
            receiver_message_templates {
                id
                uuid
                name
                invitation_uuid
                type
                language
                is_default
                header
                body
                company
            }
            token_uuids
            tokens {
                id
                uuid
                invitation_uuid
                payload
                email
                phone_number
                email_status
                sms_status
                token_usage
                company
            }
            policy_config_uuid
            policy_config {
                id
                uuid
                name
                type
                invitation_uuid
                alert_pause_notification_status
                auto_submit_response_status
                token_expired_date
                token_usage
                invitation_do_not_on_pending
                invitation_status
                invitation_days
                invitation_start_time
                invitation_end_time
                company
            }
            alert_config_uuids
            alert_configs {
                id
                uuid
                name
                type
                invitation_uuid
                questionnaire_uuid
                is_default
                conditions
                alert_message_template_sms_uuid
                alert_message_template_sms {
                    id
                    uuid
                    name
                    invitation_uuid
                    type
                    language
                    is_default
                    header
                    body
                    company
                }
                alert_message_template_email_uuid
                alert_message_template_email {
                    id
                    uuid
                    name
                    invitation_uuid
                    type
                    language
                    is_default
                    header
                    body
                    company
                }
                phone_numbers
                recipients_to
                recipients_cc
            }
            company
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`
const MUTATION_EXPORT_ITEMS = gql`
    mutation exportInvitations($input: InvitationsInput!, $by: ActionByInput!){
        exportInvitations(input: $input, by: $by){
           uuid
           size
           key
           name
           status
           topic
           type
           url
           company
           created_at
           created_by
           updated_at
           updated_by
        }
    }
`
const MUTATION_UPDATE_ITEM = gql`
    mutation updateInvitation($input: UpdateInvitationInput!){
        updateInvitation(input: $input){
            id
            uuid
            name
            type
            ref_uuid
            survey_uuid
            survey {
                id
                uuid
                code
                ref_uuid
                title
                description
                company
            }
            questionnaire_uuid
            questionnaire {
                id
                company
                uuid
                code
                ref_uuid
                title
                description
                touchpoint_uuid
                touchpoint {
                    id
                    uuid
                    code
                    name
                    name_en
                    name_th
                    company
                }
            }
            prefill_config_uuid
            prefill_config {
                id
                uuid
                name
                type
                invitation_uuid
                fields
                company
            }
            receiver_message_template_uuids
            receiver_message_templates {
                id
                uuid
                name
                invitation_uuid
                type
                language
                is_default
                header
                body
                company
            }
            token_uuids
            tokens {
                id
                uuid
                invitation_uuid
                payload
                email
                phone_number
                email_status
                sms_status
                token_usage
                company
            }
            policy_config_uuid
            policy_config {
                id
                uuid
                name
                type
                invitation_uuid
                alert_pause_notification_status
                auto_submit_response_status
                token_expired_date
                token_usage
                invitation_do_not_on_pending
                invitation_status
                invitation_days
                invitation_start_time
                invitation_end_time
                company
            }
            alert_config_uuids
            alert_configs {
                id
                uuid
                name
                type
                invitation_uuid
                questionnaire_uuid
                is_default
                conditions
                alert_message_template_sms_uuid
                alert_message_template_sms {
                    id
                    uuid
                    name
                    invitation_uuid
                    type
                    language
                    is_default
                    header
                    body
                    company
                }
                alert_message_template_email_uuid
                alert_message_template_email {
                    id
                    uuid
                    name
                    invitation_uuid
                    type
                    language
                    is_default
                    header
                    body
                    company
                }
                phone_numbers
                recipients_to
                recipients_cc
            }
            company
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`

// ====================== CREATE GQL ====================== //
const invitationGQL = {
    query: {
        getItem: QUERY_ITEM,
        getItemCut: QUERY_ITEM_CUT,
        getItems: QUERY_ITEMS,
        getItemsOnBuilder: QUERY_ITEMS_ON_BUILDER,
        getLookupItems: QUERY_LOOKUP_ITEMS,
        getItemsByPage: QUERY_ITEMS_BY_PAGE,
    },
    mutation: {
        createItem: MUTATION_CREATE_ITEM,
        createItems: MUTATION_CREATE_ITEMS,
        deleteItem: MUTATION_DELETE_ITEM,
        deleteItems: MUTATION_DELETE_ITEMS,
        exportItems: MUTATION_EXPORT_ITEMS,
        updateItem: MUTATION_UPDATE_ITEM,
    },
    response: {
        getItem: "invitation",
        getItems: "invitations",
        getLookupItems: "invitations",
        getItemsByPage: "invitationsByPage",
        createItem: "createInvitation",
        createItems: "createInvitations",
        deleteItem: "deleteInvitation",
        deleteItems: "deleteInvitations",
        exportItems: "exportInvitations",
        updateItem: "updateInvitation",
    }
}

// ====================== EXPORT ====================== //
export {
    invitationGQL,
}