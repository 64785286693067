import { gql } from '@apollo/client';

// ====================== QUERY ====================== //
const QUERY_ITEM = gql`
    query chart($input: ChartInput!){
        chart(input: $input){
            id
            uuid
            name
            description
            type
            preview_image
            dataset_uuid
            dataset {
                uuid
                name
                description
                channel_type
                file
                url
                expression
                table_name
                filters
            }
            series
            options
            company
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`
const QUERY_ITEMS = gql`
    query charts($input: ChartsInput){
        charts(input: $input){
            id
            uuid
            name
            description
            type
            preview_image
            dataset_uuid
            dataset {
                uuid
                name
                description
                channel_type
                file
                url
                expression
                table_name
                filters
            }
            series
            options
            company
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`
const QUERY_LOOKUP_ITEMS = gql`
    query charts($input: ChartsInput){
        charts(input: $input){
            # id
            uuid
            name
            type
            preview_image
            company
            # created_at
            # created_by
            # updated_at
            # updated_by
        }
    }
`
const QUERY_ITEMS_BY_PAGE = gql`
    query chartsByPage($input: ChartsByPageInput!){
        chartsByPage(input: $input){
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
            currentPage
            prevPage
            nextPage
            totalPages
            totalItems
            charts{
              id
              uuid
              name
              description
              type
              preview_image
              dataset_uuid
              dataset {
                uuid
                name
                description
                channel_type
                file
                url
                expression
                table_name
                filters
              }
              series
              options
              company
              created_at
              created_by
              updated_at
              updated_by
            }
        }
    }
`
// ====================== MUTATION ====================== //
const MUTATION_CREATE_ITEM = gql`
    mutation createChart($input: CreateChartInput!){
        createChart(input: $input){
            id
            uuid
            name
            description
            type
            preview_image
            dataset_uuid
            dataset {
                uuid
                name
                description
                channel_type
                file
                url
                expression
                table_name
                filters
            }
            series
            options
            company
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`
const MUTATION_CREATE_ITEMS = gql`
    mutation createCharts($input: [CreateChartInput]!){
        createCharts(input: $input){
            id
            uuid
            name
            description
            type
            preview_image
            dataset_uuid
            dataset {
                uuid
                name
                description
                channel_type
                file
                url
                expression
                table_name
                filters
            }
            series
            options
            company
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`
const MUTATION_DELETE_ITEM = gql`
    mutation deleteChart($input: DeleteChartInput!){
        deleteChart(input: $input){
            id
            uuid
            name
            description
            type
            preview_image
            dataset_uuid
            dataset {
                uuid
                name
                description
                channel_type
                file
                url
                expression
                table_name
                filters
            }
            series
            options
            company
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`
const MUTATION_DELETE_ITEMS = gql`
    mutation deleteCharts($input: DeleteChartInput!){
        deleteCharts(input: $input){
            id
            uuid
            name
            description
            type
            preview_image
            dataset_uuid
            dataset {
                uuid
                name
                description
                channel_type
                file
                url
                expression
                table_name
                filters
            }
            series
            options
            company
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`
const MUTATION_EXPORT_ITEMS = gql`
    mutation exportCharts($input: ChartsInput!, $by: ActionByInput!){
        exportCharts(input: $input, by: $by){
           uuid
           size
           key
           name
           status
           topic
           type
           url
           company
           created_at
           created_by
           updated_at
           updated_by
        }
    }
`
const MUTATION_UPDATE_ITEM = gql`
    mutation updateChart($input: UpdateChartInput!){
        updateChart(input: $input){
            id
            uuid
            name
            description
            type
            preview_image
            dataset_uuid
            dataset {
                uuid
                name
                description
                channel_type
                file
                url
                expression
                table_name
                filters
            }
            series
            options
            company
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`

// ====================== CREATE GQL ====================== //
const chartGQL = {
  query: {
    getItem: QUERY_ITEM,
    getItems: QUERY_ITEMS,
    getLookupItems: QUERY_LOOKUP_ITEMS,
    getItemsByPage: QUERY_ITEMS_BY_PAGE,
  },
  mutation: {
    createItem: MUTATION_CREATE_ITEM,
    createItems: MUTATION_CREATE_ITEMS,
    deleteItem: MUTATION_DELETE_ITEM,
    deleteItems: MUTATION_DELETE_ITEMS,
    exportItems: MUTATION_EXPORT_ITEMS,
    updateItem: MUTATION_UPDATE_ITEM,
  },
  response: {
    getItem: "chart",
    getItems: "charts",
    getLookupItems: "charts",
    getItemsByPage: "chartsByPage",
    createItem: "createChart",
    createItems: "createCharts",
    deleteItem: "deleteChart",
    deleteItems: "deleteCharts",
    exportItems: "exportCharts",
    updateItem: "updateChart",
  }
}

// ====================== EXPORT ====================== //
export {
  chartGQL,
}