import { gql } from '@apollo/client';

// ====================== QUERY ====================== //
const QUERY_ITEM = gql`
  query contact($input: ContactInput!) {
    contact(input: $input) {
      id
      uuid
      contact_name
      contact_company
      contact_email
      contact_phone
      contact_topic
      contact_dept
      contact_message
      contact_status
      created_at
      created_by
      updated_at
      updated_by
    }
  }
`;
const QUERY_ITEMS = gql`
  query contacts($input: ContactsInput) {
    contacts(input: $input) {
      id
      uuid
      contact_name
      contact_company
      contact_email
      contact_phone
      contact_topic
      contact_dept
      contact_message
      contact_status
      created_at
      created_by
      updated_at
      updated_by
    }
  }
`;
const QUERY_LOOKUP_ITEMS = gql`
  query contacts($input: ContactsInput) {
    contacts(input: $input) {
      # id
      uuid
      contact_name
      contact_company
      contact_email
      contact_phone
      # contact_topic
      # contact_dept
      # contact_message
      # contact_status
      # created_at
      # created_by
      # updated_at
      # updated_by
    }
  }
`
const QUERY_ITEMS_BY_PAGE = gql`
    query contactsByPage($input: ContactsByPageInput!){
        contactsByPage(input: $input){
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
            currentPage
            prevPage
            nextPage
            totalPages
            totalItems
            contacts{
              id
              uuid
              contact_name
              contact_company
              contact_email
              contact_phone
              contact_topic
              contact_dept
              contact_message
              contact_status
              created_at
              created_by
              updated_at
              updated_by
            }
        }
    }
`
// ====================== MUTATION ====================== //
const MUTATION_CREATE_ITEM = gql`
  mutation createContact($input: CreateContactInput!) {
    createContact(input: $input) {
      id
      uuid
      contact_name
      contact_company
      contact_email
      contact_phone
      contact_topic
      contact_dept
      contact_message
      contact_status
      created_at
      created_by
      updated_at
      updated_by
    }
  }
`;
const MUTATION_CREATE_ITEMS = gql`
  mutation createContacts($input: [CreateContactInput]!) {
    createContacts(input: $input) {
      id
      uuid
      contact_name
      contact_company
      contact_email
      contact_phone
      contact_topic
      contact_dept
      contact_message
      contact_status
      created_at
      created_by
      updated_at
      updated_by
    }
  }
`;
const MUTATION_DELETE_ITEM = gql`
  mutation deleteContact($input: DeleteContactInput!) {
    deleteContact(input: $input) {
      id
      uuid
      contact_name
      contact_company
      contact_email
      contact_phone
      contact_topic
      contact_dept
      contact_message
      contact_status
      created_at
      created_by
      updated_at
      updated_by
    }
  }
`;
const MUTATION_DELETE_ITEMS = gql`
  mutation deleteContacts($input: DeleteContactInput!) {
    deleteContacts(input: $input) {
      id
      uuid
      contact_name
      contact_company
      contact_email
      contact_phone
      contact_topic
      contact_dept
      contact_message
      contact_status
      created_at
      created_by
      updated_at
      updated_by
    }
  }
`;
const MUTATION_EXPORT_ITEMS = gql`
    mutation exportContacts($input: ContactsInput!, $by: ActionByInput!){
        exportContacts(input: $input, by: $by){
           uuid
           size
           key
           name
           status
           topic
           type
           url
          #  company
           created_at
           created_by
           updated_at
           updated_by
        }
    }
`
const MUTATION_UPDATE_ITEM = gql`
  mutation updateContact($input: UpdateContactInput!) {
    updateContact(input: $input) {
      id
      uuid
      contact_name
      contact_company
      contact_email
      contact_phone
      contact_topic
      contact_dept
      contact_message
      contact_status
      created_at
      created_by
      updated_at
      updated_by
    }
  }
`;

// ====================== CREATE GQL ====================== //
const contactGQL = {
  query: {
    getItem: QUERY_ITEM,
    getItems: QUERY_ITEMS,
    getLookupItems: QUERY_LOOKUP_ITEMS,
    getItemsByPage: QUERY_ITEMS_BY_PAGE,
  },
  mutation: {
    createItem: MUTATION_CREATE_ITEM,
    createItems: MUTATION_CREATE_ITEMS,
    deleteItem: MUTATION_DELETE_ITEM,
    deleteItems: MUTATION_DELETE_ITEMS,
    exportItems: MUTATION_EXPORT_ITEMS,
    updateItem: MUTATION_UPDATE_ITEM
  },
  response: {
    getItem: 'contact',
    getItems: 'contacts',
    getLookupItems: "contacts",
    getItemsByPage: "contactsByPage",
    createItem: 'createContact',
    createItems: 'createContacts',
    deleteItem: 'deleteContact',
    deleteItems: 'deleteContacts',
    exportItems: "exportContacts",
    updateItem: 'updateContact'
  }
};

// ====================== EXPORT ====================== //
export { contactGQL };
