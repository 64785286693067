import { gql } from '@apollo/client';

// ====================== QUERY ====================== //
const QUERY_ITEM = gql`
    query survey($input: SurveyInput!){
        survey(input: $input){
            id
            uuid
            code
            ref_uuid
            title
            description
            questionnaire_uuids
            questionnaires {
                id
                uuid
                code
                company
                ref_uuid
                title
                description
                touchpoint_uuid
                touchpoint {
                    id
                    uuid
                    code
                    name
                    name_en
                    name_th
                }
                payload
                no_of_responses
                responses {
                    uuid
                }
            }
            company
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`
const QUERY_ITEM_CUT = gql`
    query survey($input: SurveyInput!){
        survey(input: $input){
            id
            uuid
            code
            ref_uuid
            title
            description
            questionnaire_uuids
            questionnaires {
                id
                uuid
                code
                company
                ref_uuid
                title
                description
                touchpoint_uuid
                touchpoint {
                    id
                    uuid
                    code
                    name
                    name_en
                    name_th
                }
                # payload
                # no_of_responses
                # responses {
                #     uuid
                # }
            }
            company
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`
const QUERY_ITEMS_ON_SURVEY_SETUP = gql`
    query surveys($input: SurveysInput){
        surveys(input: $input){
            id
            uuid
            code
            ref_uuid
            title
            description
            questionnaire_uuids
            questionnaires {
                id
                uuid
                code
                company
                ref_uuid
                title
                description
                touchpoint_uuid
                touchpoint {
                    id
                    uuid
                    code
                    name
                    name_en
                    name_th
                }
                # payload
                no_of_responses
                no_of_questions
                # responses {
                #     uuid
                # }
            }
            company
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`
const QUERY_ITEMS = gql`
    query surveys($input: SurveysInput){
        surveys(input: $input){
            id
            uuid
            code
            ref_uuid
            title
            description
            questionnaire_uuids
            questionnaires {
                id
                uuid
                code
                company
                ref_uuid
                title
                description
                touchpoint_uuid
                touchpoint {
                    id
                    uuid
                    code
                    name
                    name_en
                    name_th
                }
                payload
                no_of_responses
                responses {
                    uuid
                }
            }
            company
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`
const QUERY_LOOKUP_ITEMS = gql`
    query surveys($input: SurveysInput){
        surveys(input: $input){
            # id
            uuid
            code
            ref_uuid
            title
            description
            # questionnaire_uuids
            # questionnaires {
            #     id
            #     uuid
            #     code
            #     company
            #     ref_uuid
            #     title
            #     description
            #     touchpoint_uuid
            #     touchpoint {
            #         id
            #         uuid
            #         code
            #         name
            #         name_en
            #         name_th
            #     }
            #     payload
            #     no_of_responses
            #     responses {
            #         uuid
            #     }
            # }
            company
            # created_at
            # created_by
            # updated_at
            # updated_by
        }
    }
`
const QUERY_ITEMS_BY_PAGE = gql`
    query surveysByPage($input: SurveysByPageInput!){
        surveysByPage(input: $input){
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
            currentPage
            prevPage
            nextPage
            totalPages
            totalItems
            surveys{
                id
                uuid
                code
                ref_uuid
                title
                description
                questionnaire_uuids
                questionnaires {
                    id
                    uuid
                    code
                    company
                    ref_uuid
                    title
                    description
                    touchpoint_uuid
                    touchpoint {
                        id
                        uuid
                        code
                        name
                        name_en
                        name_th
                    }
                    payload
                    no_of_responses
                    # responses {
                    #     uuid
                    # }
                }
                company
                created_at
                created_by
                updated_at
                updated_by
            }
        }
    }
`

const QUERY_ITEMS_BY_PAGE_0 = gql`
    query surveysByPage($input: SurveysByPageInput!){
        surveysByPage(input: $input){
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
            currentPage
            prevPage
            nextPage
            totalPages
            totalItems
            surveys{
                id
                uuid
                code
                ref_uuid
                title
                description
                questionnaire_uuids
                questionnaires {
                    id
                    uuid
                    code
                    company
                    ref_uuid
                    title
                    description
                    touchpoint_uuid
                    touchpoint {
                        id
                        uuid
                        code
                        name
                        name_en
                        name_th
                    }
                    # payload
                    # no_of_responses
                    # responses {
                    #     uuid
                    # }
                }
                company
                created_at
                created_by
                updated_at
                updated_by
            }
        }
    }
`
// ====================== MUTATION ====================== //
const MUTATION_CREATE_ITEM = gql`
    mutation createSurvey($input: CreateSurveyInput!){
        createSurvey(input: $input){
            id
            uuid
            code
            ref_uuid
            title
            description
            questionnaire_uuids
            questionnaires {
                id
                uuid
                code
                company
                ref_uuid
                title
                description
                touchpoint_uuid
                touchpoint {
                    id
                    uuid
                    code
                    name
                    name_en
                    name_th
                }
                payload
                no_of_responses
                responses {
                    uuid
                }
            }
            company
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`
const MUTATION_CREATE_ITEMS = gql`
    mutation createSurveys($input: [CreateSurveyInput]!){
        createSurveys(input: $input){
            id
            uuid
            code
            ref_uuid
            title
            description
            questionnaire_uuids
            questionnaires {
                id
                uuid
                code
                company
                ref_uuid
                title
                description
                touchpoint_uuid
                touchpoint {
                    id
                    uuid
                    code
                    name
                    name_en
                    name_th
                }
                payload
                no_of_responses
                responses {
                    uuid
                }
            }
            company
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`
const MUTATION_DELETE_ITEM = gql`
    mutation deleteSurvey($input: DeleteSurveyInput!){
        deleteSurvey(input: $input){
            id
            uuid
            code
            ref_uuid
            title
            description
            questionnaire_uuids
            questionnaires {
                id
                uuid
                code
                company
                ref_uuid
                title
                description
                touchpoint_uuid
                touchpoint {
                    id
                    uuid
                    code
                    name
                    name_en
                    name_th
                }
                payload
                no_of_responses
                responses {
                    uuid
                }
            }
            company
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`
const MUTATION_DELETE_ITEMS = gql`
    mutation deleteSurveys($input: DeleteSurveyInput!){
        deleteSurveys(input: $input){
            id
            uuid
            code
            ref_uuid
            title
            description
            questionnaire_uuids
            questionnaires {
                id
                uuid
                code
                company
                ref_uuid
                title
                description
                touchpoint_uuid
                touchpoint {
                    id
                    uuid
                    code
                    name
                    name_en
                    name_th
                }
                payload
                no_of_responses
                responses {
                    uuid
                }
            }
            company
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`
const MUTATION_EXPORT_ITEMS = gql`
    mutation exportSurveys($input: SurveysInput!, $by: ActionByInput!){
        exportSurveys(input: $input, by: $by){
           uuid
           size
           key
           name
           status
           topic
           type
           url
           company
           created_at
           created_by
           updated_at
           updated_by
        }
    }
`
const MUTATION_UPDATE_ITEM = gql`
    mutation updateSurvey($input: UpdateSurveyInput!){
        updateSurvey(input: $input){
            id
            uuid
            code
            ref_uuid
            title
            description
            questionnaire_uuids
            questionnaires {
                id
                uuid
                code
                company
                ref_uuid
                title
                description
                touchpoint_uuid
                touchpoint {
                    id
                    uuid
                    code
                    name
                    name_en
                    name_th
                }
                payload
                no_of_responses
                responses {
                    uuid
                }
            }
            company
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`

// ====================== CREATE GQL ====================== //
const surveyGQL = {
    query: {
        getItem: QUERY_ITEM,
        getItemCut: QUERY_ITEM_CUT,
        getItems: QUERY_ITEMS,
        getItemsOnSurveySetup: QUERY_ITEMS_ON_SURVEY_SETUP,
        getLookupItems: QUERY_LOOKUP_ITEMS,
        getItemsByPage: QUERY_ITEMS_BY_PAGE,
        getItemsByPage0: QUERY_ITEMS_BY_PAGE_0,
    },
    mutation: {
        createItem: MUTATION_CREATE_ITEM,
        createItems: MUTATION_CREATE_ITEMS,
        deleteItem: MUTATION_DELETE_ITEM,
        deleteItems: MUTATION_DELETE_ITEMS,
        exportItems: MUTATION_EXPORT_ITEMS,
        updateItem: MUTATION_UPDATE_ITEM,
    },
    response: {
        getItem: "survey",
        getItems: "surveys",
        getLookupItems: "surveys",
        getItemsByPage: "surveysByPage",
        createItem: "createSurvey",
        createItems: "createSurveys",
        deleteItem: "deleteSurvey",
        deleteItems: "deleteSurveys",
        exportItems: "exportSurveys",
        updateItem: "updateSurvey",
    }
}

// ====================== EXPORT ====================== //
export {
    surveyGQL,
}