// action - app reducer
export const SET_ERROR = '@app/SET_ERROR';
export const SET_LOADING = '@app/SET_LOADING';
export const SET_SNACKBAR = '@app/SET_SNACKBAR';
export const SET_SUCCESS = '@app/SET_SUCCESS';

// action - account reducer
export const LOGIN = '@auth/LOGIN';
export const LOGOUT = '@auth/LOGOUT';
export const REGISTER = '@auth/REGISTER';

// action - accommodation reducer
export const SET_ACCOMMODATION = '@accommodation/SET_ACCOMMODATION';
export const SET_ACCOMMODATIONS = '@accommodation/SET_ACCOMMODATIONS';

// action - alert_config reducer
export const SET_ALERT_CONFIG = '@alert_config/SET_ALERT_CONFIG';
export const SET_ALERT_CONFIGS = '@alert_config/SET_ALERT_CONFIGS';

// action - brand reducer
export const SET_BRAND = '@brand/SET_BRAND';
export const SET_BRANDS = '@brand/SET_BRANDS';

// action - chart reducer
export const SET_CHART = '@chart/SET_CHART';
export const SET_CHARTS = '@chart/SET_CHARTS';

// action - condition reducer
export const SET_CONDITION = '@condition/SET_CONDITION';
export const SET_CONDITIONS = '@condition/SET_CONDITIONS';

// action - contact reducer
export const SET_CONTACT = '@contact/SET_CONTACT';
export const SET_CONTACTS = '@contact/SET_CONTACTS';

// action - customer reducer
export const SET_CUSTOMER = '@customer/SET_CUSTOMER';
export const SET_CUSTOMERS = '@customer/SET_CUSTOMERS';

// action - dataset reducer
export const SET_DATASET = '@dataset/SET_DATASET';
export const SET_DATASETS = '@dataset/SET_DATASETS';

// action - department reducer
export const SET_DEPARTMENT = '@department/SET_DEPARTMENT';
export const SET_DEPARTMENTS = '@department/SET_DEPARTMENTS';

// action - event reducer
export const SET_EVENT = '@event/SET_EVENT';
export const SET_EVENTS = '@event/SET_EVENTS';

// action - file reducer
export const SET_FILE = '@file/SET_FILE';
export const SET_FILES = '@file/SET_FILES';
export const SET_FILE_COUNT = '@file/SET_FILE_COUNT';

// action - invitation reducer
export const SET_INVITATION = '@invitation/SET_INVITATION';
export const SET_INVITATIONS = '@invitation/SET_INVITATIONS';

// action - keyValue reducer
export const SET_KEY_VALUE = '@keyValue/SET_KEY_VALUE';
export const SET_KEY_VALUES = '@keyValue/SET_KEY_VALUES';

// action - log reducer
export const SET_LOG = '@log/SET_LOG';
export const SET_LOGS = '@log/SET_LOGS';

// action - lookup reducer
export const SET_LOOKUP = '@project/SET_LOOKUP';
export const SET_LOOKUPS = '@project/SET_LOOKUPS';

// action - message_template reducer
export const SET_MESSAGE_TEMPLATE = '@message_template/SET_MESSAGE_TEMPLATE';
export const SET_MESSAGE_TEMPLATES = '@message_template/SET_MESSAGE_TEMPLATES';

// action - note reducer
export const SET_NOTE = '@ppm/SET_NOTE';
export const SET_NOTES = '@ppm/SET_NOTES';

// action - policy_config reducer
export const SET_POLICY_CONFIG = '@policy_config/SET_POLICY_CONFIG';
export const SET_POLICY_CONFIGS = '@policy_config/SET_POLICY_CONFIGS';

// action - ppm reducer
export const SET_PPM = '@ppm/SET_PPM';
export const SET_PPMS = '@ppm/SET_PPMS';

// action - prefill_config reducer
export const SET_PREFILL_CONFIG = '@prefill_config/SET_PREFILL_CONFIG';
export const SET_PREFILL_CONFIGS = '@prefill_config/SET_PREFILL_CONFIGS';

// action - project reducer
export const SET_PROJECT = '@project/SET_PROJECT';
export const SET_PROJECTS = '@project/SET_PROJECTS';

// action - question reducer
export const SET_QUESTION = '@question/SET_QUESTION';
export const SET_QUESTIONS = '@question/SET_QUESTIONS';

// action - questionnaire reducer
export const SET_QUESTIONNAIRE = '@questionnaire/SET_QUESTIONNAIRE';
export const SET_QUESTIONNAIRES = '@questionnaire/SET_QUESTIONNAIRES';

// action - response reducer
export const SET_RESPONSE = '@response/SET_RESPONSE';
export const SET_RESPONSES = '@response/SET_RESPONSE';
export const SET_RESPONSE_FILTER = '@response/SET_RESPONSE_FILTER';
export const SET_RESPONSE_FILTERS = '@response/SET_RESPONSE_FILTERS';

// action - staff reducer
export const SET_STAFF = '@staff/SET_STAFF';
export const SET_STAFFS = '@staff/SET_STAFFS';

// action - survey reducer
export const SET_SURVEY = '@survey/SET_SURVEY';
export const SET_SURVEYS = '@survey/SET_SURVEYS';

// action - token reducer
export const SET_TOKEN = '@token/SET_TOKEN';
export const SET_TOKENS = '@token/SET_TOKENS';

// action - touchpoint reducer
export const SET_TOUCHPOINT = '@touchpoint/SET_TOUCHPOINT';
export const SET_TOUCHPOINTS = '@touchpoint/SET_TOUCHPOINTS';

// action - user reducer
export const SET_USER = '@user/SET_USER';
export const SET_USERS = '@user/SET_USERS';
export const SET_AUTH_USER = '@user/SET_AUTH_USER';
export const SET_AUTH_USER_ACCOUNT = '@user/SET_AUTH_USER_ACCOUNT';

// action - widget reducer
export const SET_WIDGET = '@widget/SET_WIDGET';
export const SET_WIDGETS = '@widget/SET_WIDGETS';