import { gql } from '@apollo/client';

// ====================== QUERY ====================== //
const QUERY_ITEM = gql`
    query accommodation($input: AccommodationInput!){
        accommodation(input: $input){
            id
            uuid
            code
            project_uuid
            project {
                id
                uuid
                code
                name
                type
                company
                status
            }
            unit_number
            house_number
            unit_type
            sale_price
            rent_price
            detail
            company
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`
const QUERY_ITEMS = gql`
    query accommodations($input: AccommodationsInput){
        accommodations(input: $input){
            id
            uuid
            code
            project_uuid
            project {
                id
                uuid
                code
                name
                type
                company
                status
            }
            unit_number
            house_number
            unit_type
            sale_price
            rent_price
            detail
            company
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`
const QUERY_LOOKUP_ITEMS = gql`
    query accommodations($input: AccommodationsInput){
        accommodations(input: $input){
            # id
            uuid
            code
            project_uuid
            project {
                # id
                uuid
                code
                name
                type
                company
                status
            }
            unit_number
            house_number
            unit_type
            # sale_price
            # rent_price
            # detail
            company
            # created_at
            # created_by
            # updated_at
            # updated_by
        }
    }
`
const QUERY_ITEMS_BY_PAGE = gql`
    query accommodationsByPage($input: AccommodationsByPageInput!){
        accommodationsByPage(input: $input){
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
            currentPage
            prevPage
            nextPage
            totalPages
            totalItems
            accommodations{
                id
                uuid
                code
                project_uuid
                project {
                    id
                    uuid
                    code
                    name
                    type
                    company
                    status
                }
                unit_number
                house_number
                unit_type
                sale_price
                rent_price
                detail
                company
                created_at
                created_by
                updated_at
                updated_by
            }
        }
    }
`
// ====================== MUTATION ====================== //
const MUTATION_CREATE_ITEM = gql`
    mutation createAccommodation($input: CreateAccommodationInput!){
        createAccommodation(input: $input){
            id
            uuid
            code
            project_uuid
            project {
                id
                uuid
                code
                name
                type
                company
                status
            }
            unit_number
            house_number
            unit_type
            sale_price
            rent_price
            detail
            company
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`
const MUTATION_CREATE_ITEMS = gql`
    mutation createAccommodations($input: [CreateAccommodationInput]!){
        createAccommodations(input: $input){
            id
            uuid
            code
            project_uuid
            project {
                id
                uuid
                code
                name
                type
                company
                status
            }
            unit_number
            house_number
            unit_type
            sale_price
            rent_price
            detail
            company
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`
const MUTATION_DELETE_ITEM = gql`
    mutation deleteAccommodation($input: DeleteAccommodationInput!){
        deleteAccommodation(input: $input){
            id
            uuid
            code
            project_uuid
            project {
                id
                uuid
                code
                name
                type
                company
                status
            }
            unit_number
            house_number
            unit_type
            sale_price
            rent_price
            detail
            company
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`
const MUTATION_DELETE_ITEMS = gql`
    mutation deleteAccommodations($input: DeleteAccommodationInput!){
        deleteAccommodations(input: $input){
            id
            uuid
            code
            project_uuid
            project {
                id
                uuid
                code
                name
                type
                company
                status
            }
            unit_number
            house_number
            unit_type
            sale_price
            rent_price
            detail
            company
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`
const MUTATION_EXPORT_ITEMS = gql`
    mutation exportAccommodations($input: AccommodationsInput!, $by: ActionByInput!){
        exportAccommodations(input: $input, by: $by){
           uuid
           size
           key
           name
           status
           topic
           type
           url
           company
           created_at
           created_by
           updated_at
           updated_by
        }
    }
`
const MUTATION_UPDATE_ITEM = gql`
    mutation updateAccommodation($input: UpdateAccommodationInput!){
        updateAccommodation(input: $input){
            id
            uuid
            code
            project_uuid
            project {
                id
                uuid
                code
                name
                type
                company
                status
            }
            unit_number
            house_number
            unit_type
            sale_price
            rent_price
            detail
            company
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`

// ====================== CREATE GQL ====================== //
const accommodationGQL = {
    query: {
        getItem: QUERY_ITEM,
        getItems: QUERY_ITEMS,
        getLookupItems: QUERY_LOOKUP_ITEMS,
        getItemsByPage: QUERY_ITEMS_BY_PAGE,
    },
    mutation: {
        createItem: MUTATION_CREATE_ITEM,
        createItems: MUTATION_CREATE_ITEMS,
        deleteItem: MUTATION_DELETE_ITEM,
        deleteItems: MUTATION_DELETE_ITEMS,
        exportItems: MUTATION_EXPORT_ITEMS,
        updateItem: MUTATION_UPDATE_ITEM,
    },
    response: {
        getItem: "accommodation",
        getItems: "accommodations",
        getLookupItems: "accommodations",
        getItemsByPage: "accommodationsByPage",
        createItem: "createAccommodation",
        createItems: "createAccommodations",
        deleteItem: "deleteAccommodation",
        deleteItems: "deleteAccommodations",
        exportItems: "exportAccommodations",
        updateItem: "updateAccommodation",
    }
}

// ====================== EXPORT ====================== //
export {
    accommodationGQL,
}