import { gql } from '@apollo/client';

// ====================== QUERY ====================== //
const QUERY_ITEM = gql`
    query event($input: EventInput!){
        event(input: $input){
            id
            uuid
            code
            customer_uuid
            customer {
                id
                uuid
                code
                prefixname
                name
                firstname
                lastname
            }
            staff_uuid
            staff {
                id
                uuid
                code
                company
                prefixname
                name
                firstname
                lastname
            }
            project_uuid
            project {
                id
                uuid
                code
                name
                type
            }
            touchpoint_uuid
            touchpoint {
                id
                uuid
                code
                name
                name_en
                name_th
            }
            case_id
            case_name
            case_topic
            case_detail
            event_location
            event_type
            event_date
            create_date
            warranty_date
            open_date
            visit_date
            booking_date
            contract_date
            transfer_date
            close_date
            company
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`
const QUERY_ITEMS = gql`
    query events($input: EventsInput){
        events(input: $input){
            id
            uuid
            code
            customer_uuid
            customer {
                id
                uuid
                code
                prefixname
                name
                firstname
                lastname
            }
            staff_uuid
            staff {
                id
                uuid
                code
                company
                prefixname
                name
                firstname
                lastname
            }
            project_uuid
            project {
                id
                uuid
                code
                name
                type
            }
            touchpoint_uuid
            touchpoint {
                id
                uuid
                code
                name
                name_en
                name_th
            }
            case_id
            case_name
            case_topic
            case_detail
            event_location
            event_type
            event_date
            create_date
            warranty_date
            open_date
            visit_date
            booking_date
            contract_date
            transfer_date
            close_date
            company
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`
const QUERY_LOOKUP_ITEMS = gql`
    query events($input: EventsInput){
        events(input: $input){
            # id
            uuid
            code
            # customer_uuid
            # customer {
            #     id
            #     uuid
            #     code
            #     prefixname
            #     name
            #     firstname
            #     lastname
            # }
            # staff_uuid
            # staff {
            #     id
            #     uuid
            #     code
            #     company
            #     prefixname
            #     name
            #     firstname
            #     lastname
            # }
            # project_uuid
            # project {
            #     id
            #     uuid
            #     code
            #     name
            #     type
            # }
            # touchpoint_uuid
            # touchpoint {
            #     id
            #     uuid
            #     code
            #     name
            #     name_en
            #     name_th
            # }
            case_id
            case_name
            case_topic
            case_detail
            event_location
            event_type
            event_date
            # create_date
            # warranty_date
            # open_date
            # visit_date
            # booking_date
            # contract_date
            # transfer_date
            # close_date
            company
            # created_at
            # created_by
            # updated_at
            # updated_by
        }
    }
`
const QUERY_ITEMS_BY_PAGE = gql`
    query eventsByPage($input: EventsByPageInput!){
        eventsByPage(input: $input){
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
            currentPage
            prevPage
            nextPage
            totalPages
            totalItems
            events{
                id
                uuid
                code
                customer_uuid
                customer {
                    id
                    uuid
                    code
                    prefixname
                    name
                    firstname
                    lastname
                }
                staff_uuid
                staff {
                    id
                    uuid
                    code
                    company
                    prefixname
                    name
                    firstname
                    lastname
                }
                project_uuid
                project {
                    id
                    uuid
                    code
                    name
                    type
                }
                touchpoint_uuid
                touchpoint {
                    id
                    uuid
                    code
                    name
                    name_en
                    name_th
                }
                case_id
                case_name
                case_topic
                case_detail
                event_location
                event_type
                event_date
                create_date
                warranty_date
                open_date
                visit_date
                booking_date
                contract_date
                transfer_date
                close_date
                company
                created_at
                created_by
                updated_at
                updated_by
            }
        }
    }
`
// ====================== MUTATION ====================== //
const MUTATION_CREATE_ITEM = gql`
    mutation createEvent($input: CreateEventInput!){
        createEvent(input: $input){
            id
            uuid
            code
            customer_uuid
            customer {
                id
                uuid
                code
                prefixname
                name
                firstname
                lastname
            }
            staff_uuid
            staff {
                id
                uuid
                code
                company
                prefixname
                name
                firstname
                lastname
            }
            project_uuid
            project {
                id
                uuid
                code
                name
                type
            }
            touchpoint_uuid
            touchpoint {
                id
                uuid
                code
                name
                name_en
                name_th
            }
            case_id
            case_name
            case_topic
            case_detail
            event_location
            event_type
            event_date
            create_date
            warranty_date
            open_date
            visit_date
            booking_date
            contract_date
            transfer_date
            close_date
            company
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`
const MUTATION_CREATE_ITEMS = gql`
    mutation createEvents($input: [CreateEventInput]!){
        createEvents(input: $input){
            id
            uuid
            code
            customer_uuid
            customer {
                id
                uuid
                code
                prefixname
                name
                firstname
                lastname
            }
            staff_uuid
            staff {
                id
                uuid
                code
                company
                prefixname
                name
                firstname
                lastname
            }
            project_uuid
            project {
                id
                uuid
                code
                name
                type
            }
            touchpoint_uuid
            touchpoint {
                id
                uuid
                code
                name
                name_en
                name_th
            }
            case_id
            case_name
            case_topic
            case_detail
            event_location
            event_type
            event_date
            create_date
            warranty_date
            open_date
            visit_date
            booking_date
            contract_date
            transfer_date
            close_date
            company
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`
const MUTATION_DELETE_ITEM = gql`
    mutation deleteEvent($input: DeleteEventInput!){
        deleteEvent(input: $input){
            id
            uuid
            code
            customer_uuid
            customer {
                id
                uuid
                code
                prefixname
                name
                firstname
                lastname
            }
            staff_uuid
            staff {
                id
                uuid
                code
                company
                prefixname
                name
                firstname
                lastname
            }
            project_uuid
            project {
                id
                uuid
                code
                name
                type
            }
            touchpoint_uuid
            touchpoint {
                id
                uuid
                code
                name
                name_en
                name_th
            }
            case_id
            case_name
            case_topic
            case_detail
            event_location
            event_type
            event_date
            create_date
            warranty_date
            open_date
            visit_date
            booking_date
            contract_date
            transfer_date
            close_date
            company
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`
const MUTATION_DELETE_ITEMS = gql`
    mutation deleteEvents($input: DeleteEventInput!){
        deleteEvents(input: $input){
            id
            uuid
            code
            customer_uuid
            customer {
                id
                uuid
                code
                prefixname
                name
                firstname
                lastname
            }
            staff_uuid
            staff {
                id
                uuid
                code
                company
                prefixname
                name
                firstname
                lastname
            }
            project_uuid
            project {
                id
                uuid
                code
                name
                type
            }
            touchpoint_uuid
            touchpoint {
                id
                uuid
                code
                name
                name_en
                name_th
            }
            case_id
            case_name
            case_topic
            case_detail
            event_location
            event_type
            event_date
            create_date
            warranty_date
            open_date
            visit_date
            booking_date
            contract_date
            transfer_date
            close_date
            company
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`
const MUTATION_EXPORT_ITEMS = gql`
    mutation exportEvents($input: EventsInput!, $by: ActionByInput!){
        exportEvents(input: $input, by: $by){
           uuid
           size
           key
           name
           status
           topic
           type
           url
           company
           created_at
           created_by
           updated_at
           updated_by
        }
    }
`
const MUTATION_UPDATE_ITEM = gql`
    mutation updateEvent($input: UpdateEventInput!){
        updateEvent(input: $input){
            id
            uuid
            code
            customer_uuid
            customer {
                id
                uuid
                code
                prefixname
                name
                firstname
                lastname
            }
            staff_uuid
            staff {
                id
                uuid
                code
                company
                prefixname
                name
                firstname
                lastname
            }
            project_uuid
            project {
                id
                uuid
                code
                name
                type
            }
            touchpoint_uuid
            touchpoint {
                id
                uuid
                code
                name
                name_en
                name_th
            }
            case_id
            case_name
            case_topic
            case_detail
            event_location
            event_type
            event_date
            create_date
            warranty_date
            open_date
            visit_date
            booking_date
            contract_date
            transfer_date
            close_date
            company
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`

// ====================== CREATE GQL ====================== //
const eventGQL = {
    query: {
        getItem: QUERY_ITEM,
        getItems: QUERY_ITEMS,
        getLookupItems: QUERY_LOOKUP_ITEMS,
        getItemsByPage: QUERY_ITEMS_BY_PAGE,
    },
    mutation: {
        createItem: MUTATION_CREATE_ITEM,
        createItems: MUTATION_CREATE_ITEMS,
        deleteItem: MUTATION_DELETE_ITEM,
        deleteItems: MUTATION_DELETE_ITEMS,
        exportItems: MUTATION_EXPORT_ITEMS,
        updateItem: MUTATION_UPDATE_ITEM,
    },
    response: {
        getItem: "event",
        getItems: "events",
        getLookupItems: "events",
        getItemsByPage: "eventsByPage",
        createItem: "createEvent",
        createItems: "createEvents",
        deleteItem: "deleteEvent",
        deleteItems: "deleteEvents",
        exportItems: "exportEvents",
        updateItem: "updateEvent",
    }
}

// ====================== EXPORT ====================== //
export {
    eventGQL,
}