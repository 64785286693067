// project imports
import axios from 'axios';
import config from '../../config';

// action - state management
import * as actionTypes from '../actions';
// ==============================|| STATE ||============================== //

export const initialState = {
    isLoading: false,
    error: null,
    success: null,
    snackbar: {
        open: false,
        variant: "filled",
        vertical: 'bottom',
        horizontal: 'center',
        severity: 'info',
        message: "",
    },
};

// ==============================|| ACTIONS ||============================== //

export const getMetadata = (input, callback) => {
    console.log(`[[ CALL getMetadata() ]]`, input)
    return (dispatch) => {
        return axios({
            method: "get",
            url: `${config.geolocation.API_ENDPOINT}${config.geolocation.API_KEY}`,
        }).then(response => {
            console.log('[[ RES ]]', response)
            if (callback) callback(response.data, null);
        }).catch((error) => {
            console.log('[[ ERROR ]]', error.message)
            if (callback) callback(null, error);
        });
    };
}
export const setLoading = (status) => {
    return { type: actionTypes.SET_LOADING, payload: status };
}

// ==============================|| REDUCER ||============================== //

const appReducer = (state = initialState, action) => {
    let { payload } = action;
    switch (action.type) {
        case actionTypes.SET_LOADING:
            return {
                ...state,
                isLoading: payload,
            };
        case actionTypes.SET_SNACKBAR:
            return {
                ...state,
                snackbar: payload,
            };
        default:
            return state;
    }
};

export default appReducer;
