import { gql } from '@apollo/client';

// ====================== QUERY ====================== //
const QUERY_ITEM = gql`
    query file($input: FileInput!){
        file(input: $input){
            id
            uuid
            size
            input
            key
            name
            status
            topic
            type
            url
            download
            company
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`
const QUERY_ITEMS = gql`
    query files($input: FilesInput){
        files(input: $input){
            id
            uuid
            size
            input
            key
            name
            status
            topic
            type
            url
            download
            company
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`
const QUERY_LOOKUP_ITEMS = gql`
    query files($input: FilesInput){
        files(input: $input){
            # id
            uuid
            size
            # input
            key
            name
            status
            topic
            type
            url
            download
            company
            # created_at
            # created_by
            # updated_at
            # updated_by
        }
    }
`
const QUERY_ITEMS_BY_PAGE = gql`
    query filesByPage($input: FilesByPageInput!){
        filesByPage(input: $input){
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
            currentPage
            prevPage
            nextPage
            totalPages
            totalItems
            files{
                id
                uuid
                size
                input
                key
                name
                status
                topic
                type
                url
                download
                company
                created_at
                created_by
                updated_at
                updated_by
            }
        }
    }
`
// ====================== MUTATION ====================== //
const MUTATION_CREATE_ITEM = gql`
    mutation createFile($input: CreateFileInput!){
        createFile(input: $input){
            id
            uuid
            size
            input
            key
            name
            status
            topic
            type
            url
            download
            company
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`
const MUTATION_CREATE_ITEMS = gql`
    mutation createFiles($input: [CreateFileInput]!){
        createFiles(input: $input){
            id
            uuid
            size
            input
            key
            name
            status
            topic
            type
            url
            download
            company
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`
const MUTATION_DELETE_ITEM = gql`
    mutation deleteFile($input: DeleteFileInput!){
        deleteFile(input: $input){
            id
            uuid
            size
            input
            key
            name
            status
            topic
            type
            url
            download
            company
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`
const MUTATION_DELETE_ITEMS = gql`
    mutation deleteFiles($input: DeleteFileInput!){
        deleteFiles(input: $input){
            id
            uuid
            size
            input
            key
            name
            status
            topic
            type
            url
            download
            company
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`
const MUTATION_EXPORT_ITEMS = gql`
    mutation exportFiles($input: FilesInput!, $by: ActionByInput!){
        exportFiles(input: $input, by: $by){
           uuid
           size
           key
           name
           status
           topic
           type
           url
           download
           company
           created_at
           created_by
           updated_at
           updated_by
        }
    }
`
const MUTATION_UPDATE_ITEM = gql`
    mutation updateFile($input: UpdateFileInput!){
        updateFile(input: $input){
            id
            uuid
            size
            input
            key
            name
            status
            topic
            type
            url
            download
            company
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`

// ====================== CREATE GQL ====================== //
const fileGQL = {
    query: {
        getItem: QUERY_ITEM,
        getItems: QUERY_ITEMS,
        getLookupItems: QUERY_LOOKUP_ITEMS,
        getItemsByPage: QUERY_ITEMS_BY_PAGE,
    },
    mutation: {
        createItem: MUTATION_CREATE_ITEM,
        createItems: MUTATION_CREATE_ITEMS,
        deleteItem: MUTATION_DELETE_ITEM,
        deleteItems: MUTATION_DELETE_ITEMS,
        exportItems: MUTATION_EXPORT_ITEMS,
        updateItem: MUTATION_UPDATE_ITEM,
    },
    response: {
        getItem: "file",
        getItems: "files",
        getLookupItems: "files",
        getItemsByPage: "filesByPage",
        createItem: "createFile",
        createItems: "createFiles",
        deleteItem: "deleteFile",
        deleteItems: "deleteFiles",
        exportItems: "exportFiles",
        updateItem: "updateFile",
    }
}

// ====================== EXPORT ====================== //
export {
    fileGQL,
}