import { gql } from '@apollo/client';

// ====================== QUERY ====================== //
const QUERY_ITEM = gql`
    query questionnaire($input: QuestionnaireInput!){
        questionnaire(input: $input){
            id
            company
            uuid
            code
            ref_uuid
            title
            description
            touchpoint_uuid
            touchpoint {
                id
                uuid
                code
                name
                name_en
                name_th
            }
            survey_uuid
            survey {
                uuid
                title
                description
            }
            payload
            no_of_responses
            no_of_questions
            responses {
                uuid
            }
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`
const QUERY_ITEM_CUT = gql`
    query questionnaire($input: QuestionnaireInput!){
        questionnaire(input: $input){
            id
            company
            uuid
            code
            ref_uuid
            title
            description
            touchpoint_uuid
            touchpoint {
                id
                uuid
                code
                name
                name_en
                name_th
            }
            survey_uuid
            survey {
                uuid
                title
                description
            }
            payload
            # no_of_responses
            # no_of_questions
            # responses {
            #     uuid
            # }
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`
const QUERY_ITEMS = gql`
    query questionnaires($input: QuestionnairesInput){
        questionnaires(input: $input){
            id
            company
            uuid
            code
            ref_uuid
            title
            description
            touchpoint_uuid
            touchpoint {
                id
                uuid
                code
                name
                name_en
                name_th
            }
            survey_uuid
            survey {
                uuid
                title
                description
            }
            payload
            no_of_responses
            no_of_questions
            responses {
                uuid
            }
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`
const QUERY_ITEMS_ON_BUILDER = gql`
    query questionnaires($input: QuestionnairesInput){
        questionnaires(input: $input){
            id
            company
            uuid
            code
            ref_uuid
            title
            description
            touchpoint_uuid
            touchpoint {
                id
                uuid
                code
                name
                name_en
                name_th
            }
            # survey_uuid
            # survey {
            #     uuid
            #     title
            #     description
            # }
            # payload
            short_cut_payload
            no_of_responses
            no_of_questions
            # responses {
            #     uuid
            # }
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`
const QUERY_LOOKUP_ITEMS = gql`
    query questionnaires($input: QuestionnairesInput){
        questionnaires(input: $input){
            # id
            company
            uuid
            code
            ref_uuid
            title
            description
            touchpoint_uuid
            touchpoint {
                # id
                uuid
                code
                name
                name_en
                name_th
            }
            survey_uuid
            survey {
                uuid
                title
                # description
            }
            # payload
            # no_of_responses
            # responses {
            #     uuid
            # }
            # created_at
            # created_by
            # updated_at
            # updated_by
        }
    }
`
const QUERY_ITEMS_BY_PAGE = gql`
    query questionnairesByPage($input: QuestionnairesByPageInput!){
        questionnairesByPage(input: $input){
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
            currentPage
            prevPage
            nextPage
            totalPages
            totalItems
            questionnaires{
                id
                company
                uuid
                code
                ref_uuid
                title
                description
                touchpoint_uuid
                touchpoint {
                    id
                    uuid
                    code
                    name
                    name_en
                    name_th
                }
                survey_uuid
                survey {
                    uuid
                    title
                    description
                }
                payload
                no_of_responses
                no_of_questions
                responses {
                    uuid
                }
                created_at
                created_by
                updated_at
                updated_by
            }
        }
    }
`

const QUERY_ITEMS_BY_PAGE_0 = gql`
    query questionnairesByPage($input: QuestionnairesByPageInput!){
        questionnairesByPage(input: $input){
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
            currentPage
            prevPage
            nextPage
            totalPages
            totalItems
            questionnaires{
                id
                company
                uuid
                code
                ref_uuid
                title
                description
                touchpoint_uuid
                touchpoint {
                    id
                    uuid
                    code
                    name
                    name_en
                    name_th
                }
                survey_uuid
                survey {
                    uuid
                    title
                    description
                }
                # payload
                # no_of_responses
                # no_of_questions
                # responses {
                #     uuid
                # }
                created_at
                created_by
                updated_at
                updated_by
            }
        }
    }
`
// ====================== MUTATION ====================== //
const MUTATION_CREATE_ITEM = gql`
    mutation createQuestionnaire($input: CreateQuestionnaireInput!){
        createQuestionnaire(input: $input){
            id
            company
            uuid
            code
            ref_uuid
            title
            description
            touchpoint_uuid
            touchpoint {
                id
                uuid
                code
                name
                name_en
                name_th
            }
            survey_uuid
            survey {
                uuid
                title
                description
            }
            payload
            no_of_responses
            no_of_questions
            responses {
                uuid
            }
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`
const MUTATION_CREATE_ITEMS = gql`
    mutation createQuestionnaires($input: [CreateQuestionnaireInput]!){
        createQuestionnaires(input: $input){
            id
            company
            uuid
            code
            ref_uuid
            title
            description
            touchpoint_uuid
            touchpoint {
                id
                uuid
                code
                name
                name_en
                name_th
            }
            survey_uuid
            survey {
                uuid
                title
                description
            }
            payload
            no_of_responses
            no_of_questions
            responses {
                uuid
            }
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`
const MUTATION_DELETE_ITEM = gql`
    mutation deleteQuestionnaire($input: DeleteQuestionnaireInput!){
        deleteQuestionnaire(input: $input){
            id
            company
            uuid
            code
            ref_uuid
            title
            description
            touchpoint_uuid
            touchpoint {
                id
                uuid
                code
                name
                name_en
                name_th
            }
            survey_uuid
            survey {
                uuid
                title
                description
            }
            payload
            no_of_responses
            no_of_questions
            responses {
                uuid
            }
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`
const MUTATION_DELETE_ITEMS = gql`
    mutation deleteQuestionnaires($input: DeleteQuestionnaireInput!){
        deleteQuestionnaires(input: $input){
            id
            company
            uuid
            code
            ref_uuid
            title
            description
            touchpoint_uuid
            touchpoint {
                id
                uuid
                code
                name
                name_en
                name_th
            }
            survey_uuid
            survey {
                uuid
                title
                description
            }
            payload
            no_of_responses
            no_of_questions
            responses {
                uuid
            }
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`
const MUTATION_EXPORT_ITEMS = gql`
    mutation exportQuestionnaires($input: QuestionnairesInput!, $by: ActionByInput!){
        exportQuestionnaires(input: $input, by: $by){
           uuid
           size
           key
           name
           status
           topic
           type
           url
           company
           created_at
           created_by
           updated_at
           updated_by
        }
    }
`
const MUTATION_UPDATE_ITEM = gql`
    mutation updateQuestionnaire($input: UpdateQuestionnaireInput!){
        updateQuestionnaire(input: $input){
            id
            company
            uuid
            code
            ref_uuid
            title
            description
            touchpoint_uuid
            touchpoint {
                id
                uuid
                code
                name
                name_en
                name_th
            }
            survey_uuid
            survey {
                uuid
                title
                description
            }
            payload
            no_of_responses
            no_of_questions
            responses {
                uuid
            }
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`

// ====================== CREATE GQL ====================== //
const questionnaireGQL = {
    query: {
        getItem: QUERY_ITEM,
        getItemCut: QUERY_ITEM_CUT,
        getItems: QUERY_ITEMS,
        getItemsOnBuilder: QUERY_ITEMS_ON_BUILDER,
        getLookupItems: QUERY_LOOKUP_ITEMS,
        getItemsByPage: QUERY_ITEMS_BY_PAGE,
        getItemsByPage0: QUERY_ITEMS_BY_PAGE_0,
    },
    mutation: {
        createItem: MUTATION_CREATE_ITEM,
        createItems: MUTATION_CREATE_ITEMS,
        deleteItem: MUTATION_DELETE_ITEM,
        deleteItems: MUTATION_DELETE_ITEMS,
        exportItems: MUTATION_EXPORT_ITEMS,
        updateItem: MUTATION_UPDATE_ITEM,
    },
    response: {
        getItem: "questionnaire",
        getItems: "questionnaires",
        getLookupItems: "questionnaires",
        getItemsByPage: "questionnairesByPage",
        createItem: "createQuestionnaire",
        createItems: "createQuestionnaires",
        deleteItem: "deleteQuestionnaire",
        deleteItems: "deleteQuestionnaires",
        exportItems: "exportQuestionnaires",
        updateItem: "updateQuestionnaire",
    }
}

// ====================== EXPORT ====================== //
export {
    questionnaireGQL,
}