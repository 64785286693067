import { gql } from '@apollo/client';

// ====================== QUERY ====================== //
const QUERY_ITEM = gql`
    query keyValue($input: KeyValueInput!){
        keyValue(input: $input){
            id
            uuid
            key
            value
            topic
            payload
            company
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`
const QUERY_ITEMS = gql`
    query keyValues($input: KeyValuesInput){
        keyValues(input: $input){
            id
            uuid
            key
            value
            topic
            payload
            company
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`
const QUERY_LOOKUP_ITEMS = gql`
    query keyValues($input: KeyValuesInput){
        keyValues(input: $input){
            # id
            uuid
            key
            value
            topic
            # payload
            company
            # created_at
            # created_by
            # updated_at
            # updated_by
        }
    }
`
const QUERY_ITEMS_BY_PAGE = gql`
    query keyValuesByPage($input: KeyValuesByPageInput!){
        keyValuesByPage(input: $input){
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
            currentPage
            prevPage
            nextPage
            totalPages
            totalItems
            key_values{
                id
                uuid
                key
                value
                topic
                payload
                company
                created_at
                created_by
                updated_at
                updated_by
            }
        }
    }
`
// ====================== MUTATION ====================== //
const MUTATION_CREATE_ITEM = gql`
    mutation createKeyValue($input: CreateKeyValueInput!){
        createKeyValue(input: $input){
            id
            uuid
            key
            value
            topic
            payload
            company
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`
const MUTATION_CREATE_ITEMS = gql`
    mutation createKeyValues($input: [CreateKeyValueInput]!){
        createKeyValues(input: $input){
            id
            uuid
            key
            value
            topic
            payload
            company
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`
const MUTATION_DELETE_ITEM = gql`
    mutation deleteKeyValue($input: DeleteKeyValueInput!){
        deleteKeyValue(input: $input){
            id
            uuid
            key
            value
            topic
            payload
            company
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`
const MUTATION_DELETE_ITEMS = gql`
    mutation deleteKeyValues($input: DeleteKeyValueInput!){
        deleteKeyValues(input: $input){
            id
            uuid
            key
            value
            topic
            payload
            company
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`
const MUTATION_EXPORT_ITEMS = gql`
    mutation exportKeyValues($input: KeyValuesInput!, $by: ActionByInput!){
        exportKeyValues(input: $input, by: $by){
           uuid
           size
           key
           name
           status
           topic
           type
           url
           company
           created_at
           created_by
           updated_at
           updated_by
        }
    }
`
const MUTATION_UPDATE_ITEM = gql`
    mutation updateKeyValue($input: UpdateKeyValueInput!){
        updateKeyValue(input: $input){
            id
            uuid
            key
            value
            topic
            payload
            company
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`

// ====================== CREATE GQL ====================== //
const keyValueGQL = {
    query: {
        getItem: QUERY_ITEM,
        getItems: QUERY_ITEMS,
        getLookupItems: QUERY_LOOKUP_ITEMS,
        getItemsByPage: QUERY_ITEMS_BY_PAGE,
    },
    mutation: {
        createItem: MUTATION_CREATE_ITEM,
        createItems: MUTATION_CREATE_ITEMS,
        deleteItem: MUTATION_DELETE_ITEM,
        deleteItems: MUTATION_DELETE_ITEMS,
        exportItems: MUTATION_EXPORT_ITEMS,
        updateItem: MUTATION_UPDATE_ITEM,
    },
    response: {
        getItem: "keyValue",
        getItems: "keyValues",
        getLookupItems: "keyValues",
        getItemsByPage: "keyValuesByPage",
        createItem: "createKeyValue",
        createItems: "createKeyValues",
        deleteItem: "deleteKeyValue",
        deleteItems: "deleteKeyValues",
        exportItems: "exportKeyValues",
        updateItem: "updateKeyValue",
    }
}

// ====================== EXPORT ====================== //
export {
    keyValueGQL,
}