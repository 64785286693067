import { gql } from '@apollo/client';

// ====================== QUERY ====================== //
const QUERY_ITEM = gql`
    query alertConfig($input: AlertConfigInput!){
        alertConfig(input: $input){
            id
            uuid
            name
            type
            invitation_uuid
            questionnaire_uuid
            is_default
            conditions
            alert_message_template_sms_uuid
            alert_message_template_sms {
                id
                uuid
                name
                invitation_uuid
                type
                language
                is_default
                header
                body
            }
            alert_message_template_email_uuid
            alert_message_template_email {
                id
                uuid
                name
                invitation_uuid
                type
                language
                is_default
                header
                body
            }
            phone_numbers
            recipients_to
            recipients_cc
            company
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`
const QUERY_ITEMS = gql`
    query alertConfigs($input: AlertConfigsInput){
        alertConfigs(input: $input){
            id
            uuid
            name
            type
            invitation_uuid
            questionnaire_uuid
            is_default
            conditions
            alert_message_template_sms_uuid
            alert_message_template_sms {
                id
                uuid
                name
                invitation_uuid
                type
                language
                is_default
                header
                body
            }
            alert_message_template_email_uuid
            alert_message_template_email {
                id
                uuid
                name
                invitation_uuid
                type
                language
                is_default
                header
                body
            }
            phone_numbers
            recipients_to
            recipients_cc
            company
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`
const QUERY_LOOKUP_ITEMS = gql`
    query alertConfigs($input: AlertConfigsInput){
        alertConfigs(input: $input){
            # id
            uuid
            name
            type
            # invitation_uuid
            # questionnaire_uuid
            is_default
            # conditions
            # alert_message_template_sms_uuid
            # alert_message_template_sms {
            #     id
            #     uuid
            #     name
            #     invitation_uuid
            #     type
            #     language
            #     is_default
            #     header
            #     body
            # }
            # alert_message_template_email_uuid
            # alert_message_template_email {
            #     id
            #     uuid
            #     name
            #     invitation_uuid
            #     type
            #     language
            #     is_default
            #     header
            #     body
            # }
            phone_numbers
            recipients_to
            recipients_cc
            company
            # created_at
            # created_by
            # updated_at
            # updated_by
        }
    }
`
const QUERY_ITEMS_BY_PAGE = gql`
    query alertConfigsByPage($input: AlertConfigsByPageInput!){
        alertConfigsByPage(input: $input){
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
            currentPage
            prevPage
            nextPage
            totalPages
            totalItems
            alert_configs{
                id
                uuid
                name
                type
                invitation_uuid
                questionnaire_uuid
                is_default
                conditions
                alert_message_template_sms_uuid
                alert_message_template_sms {
                    id
                    uuid
                    name
                    invitation_uuid
                    type
                    language
                    is_default
                    header
                    body
                }
                alert_message_template_email_uuid
                alert_message_template_email {
                    id
                    uuid
                    name
                    invitation_uuid
                    type
                    language
                    is_default
                    header
                    body
                }
                phone_numbers
                recipients_to
                recipients_cc
                company
                created_at
                created_by
                updated_at
                updated_by
            }
        }
    }
`
// ====================== MUTATION ====================== //
const MUTATION_CREATE_ITEM = gql`
    mutation createAlertConfig($input: CreateAlertConfigInput!){
        createAlertConfig(input: $input){
            id
            uuid
            name
            type
            invitation_uuid
            questionnaire_uuid
            is_default
            conditions
            alert_message_template_sms_uuid
            alert_message_template_sms {
                id
                uuid
                name
                invitation_uuid
                type
                language
                is_default
                header
                body
            }
            alert_message_template_email_uuid
            alert_message_template_email {
                id
                uuid
                name
                invitation_uuid
                type
                language
                is_default
                header
                body
            }
            phone_numbers
            recipients_to
            recipients_cc
            company
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`
const MUTATION_CREATE_ITEMS = gql`
    mutation createAlertConfigs($input: [CreateAlertConfigInput]!){
        createAlertConfigs(input: $input){
            id
            uuid
            name
            type
            invitation_uuid
            questionnaire_uuid
            is_default
            conditions
            alert_message_template_sms_uuid
            alert_message_template_sms {
                id
                uuid
                name
                invitation_uuid
                type
                language
                is_default
                header
                body
            }
            alert_message_template_email_uuid
            alert_message_template_email {
                id
                uuid
                name
                invitation_uuid
                type
                language
                is_default
                header
                body
            }
            phone_numbers
            recipients_to
            recipients_cc
            company
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`
const MUTATION_DELETE_ITEM = gql`
    mutation deleteAlertConfig($input: DeleteAlertConfigInput!){
        deleteAlertConfig(input: $input){
            id
            uuid
            name
            type
            invitation_uuid
            questionnaire_uuid
            is_default
            conditions
            alert_message_template_sms_uuid
            alert_message_template_sms {
                id
                uuid
                name
                invitation_uuid
                type
                language
                is_default
                header
                body
            }
            alert_message_template_email_uuid
            alert_message_template_email {
                id
                uuid
                name
                invitation_uuid
                type
                language
                is_default
                header
                body
            }
            phone_numbers
            recipients_to
            recipients_cc
            company
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`
const MUTATION_DELETE_ITEMS = gql`
    mutation deleteAlertConfigs($input: DeleteAlertConfigInput!){
        deleteAlertConfigs(input: $input){
            id
            uuid
            name
            type
            invitation_uuid
            questionnaire_uuid
            is_default
            conditions
            alert_message_template_sms_uuid
            alert_message_template_sms {
                id
                uuid
                name
                invitation_uuid
                type
                language
                is_default
                header
                body
            }
            alert_message_template_email_uuid
            alert_message_template_email {
                id
                uuid
                name
                invitation_uuid
                type
                language
                is_default
                header
                body
            }
            phone_numbers
            recipients_to
            recipients_cc
            company
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`
const MUTATION_EXPORT_ITEMS = gql`
    mutation exportAlertConfigs($input: AlertConfigsInput!, $by: ActionByInput!){
        exportAlertConfigs(input: $input, by: $by){
           uuid
           size
           key
           name
           status
           topic
           type
           url
           company
           created_at
           created_by
           updated_at
           updated_by
        }
    }
`
const MUTATION_UPDATE_ITEM = gql`
    mutation updateAlertConfig($input: UpdateAlertConfigInput!){
        updateAlertConfig(input: $input){
            id
            uuid
            name
            type
            invitation_uuid
            questionnaire_uuid
            is_default
            conditions
            alert_message_template_sms_uuid
            alert_message_template_sms {
                id
                uuid
                name
                invitation_uuid
                type
                language
                is_default
                header
                body
            }
            alert_message_template_email_uuid
            alert_message_template_email {
                id
                uuid
                name
                invitation_uuid
                type
                language
                is_default
                header
                body
            }
            phone_numbers
            recipients_to
            recipients_cc
            company
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`

// ====================== CREATE GQL ====================== //
const alertConfigGQL = {
    query: {
        getItem: QUERY_ITEM,
        getItems: QUERY_ITEMS,
        getLookupItems: QUERY_LOOKUP_ITEMS,
        getItemsByPage: QUERY_ITEMS_BY_PAGE,
    },
    mutation: {
        createItem: MUTATION_CREATE_ITEM,
        createItems: MUTATION_CREATE_ITEMS,
        deleteItem: MUTATION_DELETE_ITEM,
        deleteItems: MUTATION_DELETE_ITEMS,
        exportItems: MUTATION_EXPORT_ITEMS,
        updateItem: MUTATION_UPDATE_ITEM,
    },
    response: {
        getItem: "alertConfig",
        getItems: "alertConfigs",
        getLookupItems: "alertConfigs",
        getItemsByPage: "alertConfigsByPage",
        createItem: "createAlertConfig",
        createItems: "createAlertConfigs",
        deleteItem: "deleteAlertConfig",
        deleteItems: "deleteAlertConfigs",
        exportItems: "exportAlertConfigs",
        updateItem: "updateAlertConfig",
    }
}

// ====================== EXPORT ====================== //
export {
    alertConfigGQL,
}