const getPermission = (user) => {
    let { permission } = user;
    permission = permission
    return permission
}

const isAccessMenu = ({ user, program, action }) => {
    if (!user) return false;
    let { license, level } = user;
    if (level === "ADMIN") return true;
    if (program?.includes('/profiles/user')) return true;

    let permission = getPermission(user);
    if (action) return permission?.[program]?.[action] || false

    return permission?.[program] ? true : false
}
const isAccessMenuCollapse = ({ user, program, children }) => {
    // program = id
    if (!user) return false;
    let { license, level } = user;
    if (level === "ADMIN") return true;

    let permission = getPermission(user);
    let menus = Object.keys(permission);

    // find user menu in collapse
    let finds = menus.filter((menu) => {
        return children.find((i) => { return i.url === menu })
    });

    if (finds.length > 0) return true;
    else return false;
}
const isAccessMenuGroup = ({ user, program, children, group }) => {
    if (!user) return false;
    let { license, level } = user;
    if (level === "ADMIN") return true;

    let permission = getPermission(user);
    let menus = Object.keys(permission);

    const getMembers = (members) => {
        let children = [];
        const flattenMembers = members.map(m => {
            if (m.children && m.children.length) {
                children = [...children, ...m.children];
            }
            return m;
        });

        return flattenMembers.concat(children.length ? getMembers(children) : children);
    };
    let childs = _.map(getMembers(children), (i)=>{
        return i.url
    }).filter(i=>i);

    let valid = _.intersection(menus, childs);

    if(valid.length > 0) return true;
    else return false;
}

export {
    getPermission,
    isAccessMenu,
    isAccessMenuCollapse,
    isAccessMenuGroup,
}