import { useMemo } from 'react';

// material-ui
import { Box, useMediaQuery } from '@mui/material';

// project import
import useConfig from 'hooks/useConfig';
import Search from './Search';
import Message from './Message';
import Profile from './Profile';
import Localization from './Localization';
// import Notification from './Notification';
import Customization from './Customization';
import MobileSection from './MobileSection';
import { isAccessMenu } from 'assets/js/user';
import { useSelector } from 'react-redux';
// import MegaMenuSection from './MegaMenuSection';
// import config from 'config';

// ==============================|| HEADER - CONTENT ||============================== //

const HeaderContent = () => {
  const { i18n } = useConfig();

  const matchesXs = useMediaQuery((theme) => theme.breakpoints.down('md'));

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const localization = useMemo(() => <Localization />, [i18n]);
  const selectors = useSelector((state) => {
    return state
  });
  // const megaMenu = useMemo(() => <MegaMenuSection />, []);

  return (
    <>
      {!matchesXs && <Search />}
      {/* {config.displayThemePreview && !matchesXs && megaMenu} */}
      {/* {!matchesXs && localization} */}
      {matchesXs && <Box sx={{ width: '100%', ml: 1 }} />}

      {/* {config.displayThemePreview && <Notification />} */}
      {isAccessMenu({ user: selectors?.auth?.user, program: "/data-management/file-service", action: 'export', }) && <Message />}
      {<Customization />}
      {!matchesXs && <Profile />}
      {matchesXs && <MobileSection />}
    </>
  );
};

export default HeaderContent;
