
const CryptoJS = require("crypto-js");

const secretProj = 'supanimit';

const decrypt = (ciphertext) => {
    var bytes = CryptoJS.AES.decrypt(ciphertext, secretProj);
    var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
    return decryptedData
}
const encrypt = (data) => {
    var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(data), secretProj).toString();
    return ciphertext
}
const getLocalStorage = (key) => {
    let hashData = localStorage.getItem(key);
    if (!hashData) return null
    if (hashData === '') return null
    return decrypt(hashData);
}
const removeLocalStorage = (key) => {
    localStorage.removeItem(key);
}
const setLocalStorage = (key, data) => {
    let hashData = encrypt(data);
    localStorage.setItem(key, hashData);
}

export {
    getLocalStorage,
    removeLocalStorage,
    setLocalStorage,
}