export default function DataGrid(theme) {
    return {
        MuiDataGrid: {
            styleOverrides: {
                root: {
                    // borderColor: theme.palette?.custom?.dataGridBorderColor || null,
                    '& .MuiDataGrid-cell': {
                        borderBottom: `1px solid ${theme.palette?.custom?.dataGridBorderColor || 'transparent'}`,
                    },
                    '& .MuiDataGrid-columnSeparator--sideRight': {
                        color: theme.palette?.custom?.dataGridBorderColor || null,
                    },
                    '& .table-button': {
                        minWidth: 'unset',
                    },
                    '& .MuiTablePagination-displayedRows': {
                        margin: 0,
                        fontSize: '12px',
                    }
                },
                columnHeaderTitle: {
                    fontWeight: 600,
                },
                columnHeaders: {
                    borderColor: theme.palette?.custom?.dataGridBorderColor || null,
                },
                main: {
                    borderTop: `1px solid ${theme.palette?.custom?.dataGridBorderColor || 'transparent'}`,
                    borderBottom: `1px solid ${theme.palette?.custom?.dataGridBorderColor || 'transparent'}`,
                },
                toolbarContainer: {
                    padding: '10px',
                },
            }
        }
    };
}