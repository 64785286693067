import { Link } from 'react-router-dom';

// project import
import config from 'config';

// material-ui
import { Box, Button, Grid, Stack, Typography } from '@mui/material';

// assets
import construction from 'assets/images/maintenance/under-construction.svg';
import MainCard from 'components/MainCard';

// ==============================|| UNDER CONSTRUCTION - MAIN ||============================== //

function Authentication() {
  return (
    <MainCard style={{ height: '100%', width: '100%', overflow: 'auto', display: 'flex', justifyContent: 'cetner', alignItems: 'center' }} >
      <Grid container spacing={4} >
        <Grid item lg={5} md={6} sm={12} xs={12} style={{ textAlign: 'center' }} sx={{ maxHeight: { lg: 'inherit', xs: '200px' } }}>
          <img src={construction} alt="mantis" style={{ width: '100%', height: 'auto' }} />
        </Grid>
        <Grid item lg={7} md={6} sm={12} xs={12}>
          <Grid container spacing={4} >
            <Grid item xs={12} >
              <Typography align="center" variant="h1" style={{ paddingTop: '50px' }}>
                Authentication
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography align="center" color="textSecondary">
                Hey! Please check out this site later. We are doing some maintenance on it right now.
              </Typography>
            </Grid>
            <Grid item xs={12} >
            </Grid>
            <Grid item xs={12} style={{ textAlign: 'center' }} >
              <Button component={Link} to={config.defaultPath} variant="contained">
                Back To Home
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </MainCard>
  );
}

export default Authentication;
