import { useEffect, useRef, useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
  Avatar,
  Box,
  ClickAwayListener,
  Divider,
  List,
  ListItemButton,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  Paper,
  Popper,
  Typography,
  useMediaQuery,
  Chip,
  Badge
} from '@mui/material';

// project import
import MainCard from 'components/MainCard';
import IconButton from 'components/@extended/IconButton';
import Transitions from 'components/@extended/Transitions';

// assets
import avatar2 from 'assets/images/users/avatar-2.png';
import avatar3 from 'assets/images/users/avatar-3.png';
import avatar4 from 'assets/images/users/avatar-4.png';
import avatar5 from 'assets/images/users/avatar-5.png';
import { MailOutlined, CloseOutlined, UserOutlined, FileSyncOutlined, FileTextOutlined, FileExclamationOutlined, FileDoneOutlined, CloudDownloadOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import * as reducers from 'store/reducers/file.reducer';
import { useNavigate } from 'react-router';
import moment from 'moment';

// sx styles
const avatarSX = {
  width: 48,
  height: 48
};

const actionSX = {
  mt: '6px',
  ml: 1,
  top: 'auto',
  right: 'auto',
  alignSelf: 'flex-start',
  transform: 'none'
};

// ==============================|| HEADER CONTENT - MESSAGES ||============================== //

const Message = () => {
  const theme = useTheme();
  const matchesXs = useMediaQuery(theme.breakpoints.down('md'));
  const dispatch = useDispatch();
  const selectors = useSelector((state) => {
    return state
  });
  const anchorRef = useRef(null);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [items, setItems] = useState([]);
  const [countUnDownload, setCountUnDownload] = useState(0);


  useEffect(() => {
    console.log(`>>>>> ${'Message'} Screen loaded`);

    // call others
    // call main items
    countFileNotDownload();

  }, []);
  const countFileNotDownload = () => {
    // setIsLoading(true);
    dispatch(reducers.getItemsByPage({
      page: 1,
      size: 5,
      where: {
        isNotDownloaded: true,
        company: selectors?.auth?.user?.company,
        created_by: selectors?.auth?.user?.username,
      },
    }, (res) => {
      if (res) {
        setCountUnDownload(res['totalItems']);
        dispatch(reducers.setCount(res['totalItems'], (res) => { }))
      };
      // setIsLoading(false);
    }));
  }
  const refreshItems = (page, startCursor, endCursor, filter, isReset) => {
    // setIsLoading(true);
    dispatch(reducers.getItemsByPage({
      page: page,
      size: 5,
      where: {
        company: selectors?.auth?.user?.company,
        created_by: selectors?.auth?.user?.username,
      },
    }, (res) => {
      if (res) {
        setItems(res['files'])
      };
      // setIsLoading(false);
    }));
  }
  const handleToggle = () => {
    setOpen((prevOpen) => {
      if (prevOpen === false) {
        console.log('open', prevOpen);
        dispatch(reducers.setCount(0, (res) => { 
          countFileNotDownload();
        }))
        refreshItems(1, null, null, {}, true);
      }
      return !prevOpen
    });
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const getStatusColor = (item) => {
    let color = "default"
    switch (item.status) {
      case "WAITING":
        color = "default"
        break;
      case "SUCCESS":
        color = "success"
        break;
      case "ERROR":
        color = "error"
        break;
      default:
        break;
    }
    return color;
  }
  const getFileIcon = (item) => {
    let icon = <FileTextOutlined />
    switch (item.status) {
      case "WAITING":
        icon = <FileTextOutlined />
        break;
      case "SUCCESS":
        icon = <FileDoneOutlined />
        break;
      case "ERROR":
        icon = <FileExclamationOutlined />
        break;
      default:
        break;
    }
    return icon;
  }

  const iconBackColorOpen = theme.palette.mode === 'dark' ? 'grey.200' : 'grey.300';
  const iconBackColor = theme.palette.mode === 'dark' ? 'background.default' : 'grey.100';

  return (
    <Box sx={{ flexShrink: 0, ml: 0.75 }}>
      <IconButton
        color="secondary"
        variant="light"
        sx={{ color: 'text.primary', bgcolor: open ? iconBackColorOpen : iconBackColor }}
        aria-label="open profile"
        ref={anchorRef}
        aria-controls={open ? 'profile-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <Badge color="primary" variant={selectors?.file?.count > 0 ? 'dot' : 'standard'}>
          <CloudDownloadOutlined />
        </Badge>
      </IconButton>
      <Popper
        placement={matchesXs ? 'bottom' : 'bottom-end'}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [matchesXs ? -60 : 0, 9]
              }
            }
          ]
        }}
      >
        {({ TransitionProps }) => (
          <Transitions type="fade" in={open} {...TransitionProps}>
            <Paper
              sx={{
                boxShadow: theme.customShadows.z1,
                width: '100%',
                minWidth: 285,
                maxWidth: 420,
                // [theme.breakpoints.down('md')]: {
                //   maxWidth: 360
                // }
              }}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <MainCard
                  title="File"
                  elevation={0}
                  border={false}
                  content={false}
                  secondary={
                    <IconButton size="small" onClick={handleToggle}>
                      <CloseOutlined />
                    </IconButton>
                  }
                >
                  <List
                    component="nav"
                    sx={{
                      p: 0,
                      '& .MuiListItemButton-root': {
                        py: 1.5,
                        '& .MuiAvatar-root': avatarSX,
                        '& .MuiListItemSecondaryAction-root': { ...actionSX, position: 'relative' }
                      }
                    }}
                  >
                    {items.length > 0 && items.map((item, index) => {
                      return <>
                        <ListItemButton key={'msg-' + index}
                          style={{ backgroundColor: (item?.status === 'SUCCESS' && item?.download == 0) ? 'rgb(238 238 255 / 90%)' : 'transparent' }}
                          onClick={() => {
                            if (item.url) {
                              if (item.download === 0) {
                                // update download++
                                dispatch(reducers.updateItem({
                                  uuid: item.uuid,
                                  download: (item?.download || 0) + 1,
                                  updated_by: selectors?.auth?.user?.username,
                                }, (res) => {
                                  if (res) {
                                    let oldItems = _.clone(items);
                                    let i = _.findIndex(oldItems, (i) => {
                                      return i.uuid === item.uuid
                                    });
                                    oldItems.splice(i, 1, res)
                                    setItems(oldItems)
                                    setCountUnDownload(countUnDownload - 1)
                                    dispatch(reducers.setCount(countUnDownload - 1, (res) => { 
                                    }))
                                  };
                                }, true));
                              }

                              window.open(item.url)
                              //  window.open("https://storage.googleapis.com/download/storage/v1/b/ticx-362315_files/o/AMC_LOGO%2F2022-11-14%2F944586ea-675a-4d96-9398-f979c2f6cf48%2FAMC_logo.png?generation=1668408170714949&alt=media")
                            }
                          }}>
                          <ListItemAvatar>
                            <Avatar alt={item.name} variant="rounded" >{getFileIcon(item)}</Avatar>
                          </ListItemAvatar>
                          <ListItemText
                            primary={
                              <Typography variant="h6" style={{
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                              }}>
                                <Typography component="span" variant="subtitle1">
                                  {item.name}
                                </Typography>
                              </Typography>
                            }
                            secondary={moment(item.created_at).format('DD/MM/YYYY hh:mm a')}
                          />
                          <ListItemSecondaryAction>
                            <Chip variant="combined" color={getStatusColor(item)} label={item.status} size="small" />
                          </ListItemSecondaryAction>
                        </ListItemButton>
                        <Divider key={'divider-' + index} />
                      </>
                    })}
                    {items.length === 0 && <Typography variant="h6" sx={{ textAlign: 'center', padding: '20px' }}>
                      None
                    </Typography>}

                    {/* <ListItemButton>
                      <ListItemAvatar>
                        <Avatar alt="profile user" src={avatar5} />
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          <Typography variant="h6">
                            <Typography component="span" variant="subtitle1">
                              Cristina Danny
                            </Typography>{' '}
                            invited to join{' '}
                            <Typography component="span" variant="subtitle1">
                              Meeting.
                            </Typography>
                          </Typography>
                        }
                        secondary="Daily scrum meeting time"
                      />
                      <ListItemSecondaryAction>
                        <Typography variant="caption" noWrap>
                          9:10 PM
                        </Typography>
                      </ListItemSecondaryAction>
                    </ListItemButton>
                    <Divider /> */}

                    <ListItemButton sx={{ textAlign: 'center' }} onClick={() => {
                      navigate(`${'/data-management/file-service'}`)
                    }}>
                      <ListItemText
                        primary={
                          <Typography variant="h6" color="primary">
                            View All
                          </Typography>
                        }
                      />
                    </ListItemButton>
                  </List>
                </MainCard>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
    </Box>
  );
};

export default Message;
