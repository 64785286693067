import { gql } from '@apollo/client';

// ====================== QUERY ====================== //
const QUERY_ITEM = gql`
    query policyConfig($input: PolicyConfigInput!){
        policyConfig(input: $input){
            id
            uuid
            name
            type
            invitation_uuid
            alert_pause_notification_status
            auto_submit_response_status
            token_expired_date
            token_usage
            invitation_do_not_on_pending
            invitation_status
            invitation_days
            invitation_start_time
            invitation_end_time
            company
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`
const QUERY_ITEMS = gql`
    query policyConfigs($input: PolicyConfigsInput){
        policyConfigs(input: $input){
            id
            uuid
            name
            type
            invitation_uuid
            alert_pause_notification_status
            auto_submit_response_status
            token_expired_date
            token_usage
            invitation_do_not_on_pending
            invitation_status
            invitation_days
            invitation_start_time
            invitation_end_time
            company
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`
const QUERY_LOOKUP_ITEMS = gql`
    query policyConfigs($input: PolicyConfigsInput){
        policyConfigs(input: $input){
            # id
            uuid
            name
            type
            # invitation_uuid
            # alert_pause_notification_status
            # auto_submit_response_status
            # token_expired_date
            # token_usage
            # invitation_do_not_on_pending
            # invitation_status
            # invitation_days
            # invitation_start_time
            # invitation_end_time
            company
            # created_at
            # created_by
            # updated_at
            # updated_by
        }
    }
`
const QUERY_ITEMS_BY_PAGE = gql`
    query policyConfigsByPage($input: PolicyConfigsByPageInput!){
        policyConfigsByPage(input: $input){
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
            currentPage
            prevPage
            nextPage
            totalPages
            totalItems
            policy_configs{
                id
                uuid
                name
                type
                invitation_uuid
                alert_pause_notification_status
                auto_submit_response_status
                token_expired_date
                token_usage
                invitation_do_not_on_pending
                invitation_status
                invitation_days
                invitation_start_time
                invitation_end_time
                company
                created_at
                created_by
                updated_at
                updated_by
            }
        }
    }
`
// ====================== MUTATION ====================== //
const MUTATION_CREATE_ITEM = gql`
    mutation createPolicyConfig($input: CreatePolicyConfigInput!){
        createPolicyConfig(input: $input){
            id
            uuid
            name
            type
            invitation_uuid
            alert_pause_notification_status
            auto_submit_response_status
            token_expired_date
            token_usage
            invitation_do_not_on_pending
            invitation_status
            invitation_days
            invitation_start_time
            invitation_end_time
            company
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`
const MUTATION_CREATE_ITEMS = gql`
    mutation createPolicyConfigs($input: [CreatePolicyConfigInput]!){
        createPolicyConfigs(input: $input){
            id
            uuid
            name
            type
            invitation_uuid
            alert_pause_notification_status
            auto_submit_response_status
            token_expired_date
            token_usage
            invitation_do_not_on_pending
            invitation_status
            invitation_days
            invitation_start_time
            invitation_end_time
            company
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`
const MUTATION_DELETE_ITEM = gql`
    mutation deletePolicyConfig($input: DeletePolicyConfigInput!){
        deletePolicyConfig(input: $input){
            id
            uuid
            name
            type
            invitation_uuid
            alert_pause_notification_status
            auto_submit_response_status
            token_expired_date
            token_usage
            invitation_do_not_on_pending
            invitation_status
            invitation_days
            invitation_start_time
            invitation_end_time
            company
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`
const MUTATION_DELETE_ITEMS = gql`
    mutation deletePolicyConfigs($input: DeletePolicyConfigInput!){
        deletePolicyConfigs(input: $input){
            id
            uuid
            name
            type
            invitation_uuid
            alert_pause_notification_status
            auto_submit_response_status
            token_expired_date
            token_usage
            invitation_do_not_on_pending
            invitation_status
            invitation_days
            invitation_start_time
            invitation_end_time
            company
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`
const MUTATION_EXPORT_ITEMS = gql`
    mutation exportPolicyConfigs($input: PolicyConfigsInput!, $by: ActionByInput!){
        exportPolicyConfigs(input: $input, by: $by){
           uuid
           size
           key
           name
           status
           topic
           type
           url
           company
           created_at
           created_by
           updated_at
           updated_by
        }
    }
`
const MUTATION_UPDATE_ITEM = gql`
    mutation updatePolicyConfig($input: UpdatePolicyConfigInput!){
        updatePolicyConfig(input: $input){
            id
            uuid
            name
            type
            invitation_uuid
            alert_pause_notification_status
            auto_submit_response_status
            token_expired_date
            token_usage
            invitation_do_not_on_pending
            invitation_status
            invitation_days
            invitation_start_time
            invitation_end_time
            company
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`

// ====================== CREATE GQL ====================== //
const policyConfigGQL = {
    query: {
        getItem: QUERY_ITEM,
        getItems: QUERY_ITEMS,
        getLookupItems: QUERY_LOOKUP_ITEMS,
        getItemsByPage: QUERY_ITEMS_BY_PAGE,
    },
    mutation: {
        createItem: MUTATION_CREATE_ITEM,
        createItems: MUTATION_CREATE_ITEMS,
        deleteItem: MUTATION_DELETE_ITEM,
        deleteItems: MUTATION_DELETE_ITEMS,
        exportItems: MUTATION_EXPORT_ITEMS,
        updateItem: MUTATION_UPDATE_ITEM,
    },
    response: {
        getItem: "policyConfig",
        getItems: "policyConfigs",
        getLookupItems: "policyConfigs",
        getItemsByPage: "policyConfigsByPage",
        createItem: "createPolicyConfig",
        createItems: "createPolicyConfigs",
        deleteItem: "deletePolicyConfig",
        deleteItems: "deletePolicyConfigs",
        exportItems: "exportPolicyConfigs",
        updateItem: "updatePolicyConfig",
    }
}

// ====================== EXPORT ====================== //
export {
    policyConfigGQL,
}