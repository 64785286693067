// third-party
import { FormattedMessage } from 'react-intl';

// assets
import {
    DatabaseOutlined,
    FileDoneOutlined,
    ContainerOutlined,
    UserOutlined,
    EditOutlined,
    TableOutlined,
} from '@ant-design/icons';

// icons
const icons = {
    DatabaseOutlined,
    FileDoneOutlined,
    ContainerOutlined,
    UserOutlined,
    EditOutlined,
    TableOutlined,
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const dataManagement = {
    id: 'group-data-management',
    title: <FormattedMessage id="data-management" />,
    type: 'group',
    children: [
        {
            id: 'prefill',
            title: <FormattedMessage id="prefill" />,
            description: "Setting prefill in data management",
            type: 'collapse',
            icon: icons.EditOutlined,
            children: [
                {
                    id: 'touchpoint',
                    title: <FormattedMessage id="touchpoint" />,
                    type: 'item',
                    url: '/data-management/touchpoint',
                    breadcrumbs: false,
                },
                {
                    id: 'project',
                    title: <FormattedMessage id="project" />,
                    type: 'item',
                    url: '/data-management/project',
                    breadcrumbs: false,
                },
                {
                    id: 'brand',
                    title: <FormattedMessage id="brand" />,
                    type: 'item',
                    url: '/data-management/brand',
                    breadcrumbs: false,
                },
                {
                    id: 'ppm',
                    title: <FormattedMessage id="ppm" />,
                    type: 'item',
                    url: '/data-management/ppm',
                    breadcrumbs: false,
                },
                {
                    id: 'staff',
                    title: <FormattedMessage id="staff" />,
                    type: 'item',
                    url: '/data-management/staff',
                    breadcrumbs: false,
                },
                {
                    id: 'department',
                    title: <FormattedMessage id="department" />,
                    type: 'item',
                    url: '/data-management/department',
                    breadcrumbs: false,
                },
                {
                    id: 'customer',
                    title: <FormattedMessage id="customer" />,
                    type: 'item',
                    url: '/data-management/customer',
                    breadcrumbs: false,
                },
                {
                    id: 'accommodation',
                    title: <FormattedMessage id="accommodation" />,
                    type: 'item',
                    url: '/data-management/accommodation',
                    breadcrumbs: false,
                },
                {
                    id: 'event',
                    title: <FormattedMessage id="event" />,
                    type: 'item',
                    url: '/data-management/event',
                    breadcrumbs: false,
                },
                {
                    id: 'key-value',
                    title: <FormattedMessage id="key-value" />,
                    type: 'item',
                    url: '/data-management/key-value',
                    breadcrumbs: false,
                },
            ]
        },
        {
            id: 'survey',
            title: <FormattedMessage id="survey" />,
            type: 'collapse',
            icon: icons.FileDoneOutlined,
            children: [
                {
                    id: 'survey',
                    title: <FormattedMessage id="survey" />,
                    type: 'item',
                    url: '/data-management/survey',
                    breadcrumbs: false,
                },
                {
                    id: 'questionnaire',
                    title: <FormattedMessage id="questionnaire" />,
                    type: 'item',
                    url: '/data-management/questionnaire',
                    breadcrumbs: false,
                },
                {
                    id: 'question',
                    title: <FormattedMessage id="question" />,
                    type: 'item',
                    url: '/data-management/question',
                    breadcrumbs: false,
                },
                {
                    id: 'response',
                    title: <FormattedMessage id="response" />,
                    type: 'item',
                    url: '/data-management/response',
                    breadcrumbs: false,
                },
            ]
        },
        {
            id: 'administration',
            title: <FormattedMessage id="administration" />,
            type: 'collapse',
            icon: icons.UserOutlined,
            children: [
                {
                    id: 'contact',
                    title: <FormattedMessage id="contact" />,
                    type: 'item',
                    url: '/data-management/contact',
                    breadcrumbs: false,
                },
                {
                    id: 'user',
                    title: <FormattedMessage id="user" />,
                    type: 'item',
                    url: '/data-management/user',
                    breadcrumbs: false,
                },
                {
                    id: 'log',
                    title: <FormattedMessage id="log" />,
                    type: 'item',
                    url: '/data-management/log',
                    breadcrumbs: false,
                },
            ]
        },
        {
            id: 'system-data',
            title: <FormattedMessage id="system-data" />,
            type: 'collapse',
            icon: icons.TableOutlined,
            children: [
                {
                    id: 'invitation',
                    title: <FormattedMessage id="invitation" />,
                    type: 'item',
                    url: '/data-management/invitation',
                    breadcrumbs: false,
                },
                {
                    id: 'prefill-config',
                    title: <FormattedMessage id="prefill-config" />,
                    type: 'item',
                    url: '/data-management/prefill-config',
                    breadcrumbs: false,
                },
                {
                    id: 'message-template',
                    title: <FormattedMessage id="message-template" />,
                    type: 'item',
                    url: '/data-management/message-template',
                    breadcrumbs: false,
                },
                {
                    id: 'alert-config',
                    title: <FormattedMessage id="alert-config" />,
                    type: 'item',
                    url: '/data-management/alert-config',
                    breadcrumbs: false,
                },
                {
                    id: 'policy-config',
                    title: <FormattedMessage id="policy-config" />,
                    type: 'item',
                    url: '/data-management/policy-config',
                    breadcrumbs: false,
                },
                // {
                //     id: 'condition',
                //     title: <FormattedMessage id="condition" />,
                //     type: 'item',
                //     url: '/data-management/condition',
                //     breadcrumbs: false,
                // },
                {
                    id: 'token',
                    title: <FormattedMessage id="token" />,
                    type: 'item',
                    url: '/data-management/token',
                    breadcrumbs: false,
                },
                {
                    id: 'note',
                    title: <FormattedMessage id="note" />,
                    type: 'item',
                    url: '/data-management/note',
                    breadcrumbs: false,
                },
                {
                    id: 'file-service',
                    title: <FormattedMessage id="file-service" />,
                    type: 'item',
                    url: '/data-management/file-service',
                    breadcrumbs: false,
                },
                {
                    id: 'file-storage',
                    title: <FormattedMessage id="file-storage" />,
                    type: 'item',
                    url: '/data-management/file-storage',
                    breadcrumbs: false,
                },
                {
                    id: 'send-message',
                    title: <FormattedMessage id="send-message" />,
                    type: 'item',
                    url: '/data-management/send-message',
                    breadcrumbs: false,
                },
                {
                    id: 'lookup',
                    title: <FormattedMessage id="lookup" />,
                    type: 'item',
                    url: '/data-management/lookup',
                    breadcrumbs: false,
                },
                {
                    id: 'widget',
                    title: <FormattedMessage id="widget" />,
                    type: 'item',
                    url: '/data-management/widget',
                    breadcrumbs: false,
                },
                {
                    id: 'dataset',
                    title: <FormattedMessage id="dataset" />,
                    type: 'item',
                    url: '/data-management/dataset',
                    breadcrumbs: false,
                },
                {
                    id: 'chart',
                    title: <FormattedMessage id="chart" />,
                    type: 'item',
                    url: '/data-management/chart',
                    breadcrumbs: false,
                },
            ]
        },
    ]
};

export default dataManagement;
