import { gql } from '@apollo/client';

// ====================== QUERY ====================== //
const QUERY_ITEM = gql`
    query question($input: QuestionInput!){
        question(input: $input){
            id
            uuid
            code
            ref_uuid
            questionnaire_uuid
            questionnaire {
                id
                company
                uuid
                code
                ref_uuid
                title
                description
                touchpoint_uuid
            }
            title
            description
            is_library
            score_type
            sub_score_type
            payload
            company
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`
const QUERY_ITEMS = gql`
    query questions($input: QuestionsInput){
        questions(input: $input){
            id
            uuid
            code
            ref_uuid
            questionnaire_uuid
            questionnaire {
                id
                company
                uuid
                code
                ref_uuid
                title
                description
                touchpoint_uuid
            }
            title
            description
            is_library
            score_type
            sub_score_type
            payload
            company
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`
const QUERY_LOOKUP_ITEMS = gql`
    query questions($input: QuestionsInput){
        questions(input: $input){
            # id
            uuid
            code
            ref_uuid
            questionnaire_uuid
            # questionnaire {
            #     id
            #     company
            #     uuid
            #     code
            #     ref_uuid
            #     title
            #     description
            #     touchpoint_uuid
            # }
            title
            # description
            is_library
            # score_type
            # sub_score_type
            # payload
            company
            # created_at
            # created_by
            # updated_at
            # updated_by
        }
    }
`
const QUERY_ITEMS_BY_PAGE = gql`
    query questionsByPage($input: QuestionsByPageInput!){
        questionsByPage(input: $input){
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
            currentPage
            prevPage
            nextPage
            totalPages
            totalItems
            questions{
                id
                uuid
                code
                ref_uuid
                questionnaire_uuid
                questionnaire {
                    id
                    company
                    uuid
                    code
                    ref_uuid
                    title
                    description
                    touchpoint_uuid
                }
                title
                description
                is_library
                score_type
                sub_score_type
                payload
                company
                created_at
                created_by
                updated_at
                updated_by
            }
        }
    }
`
// ====================== MUTATION ====================== //
const MUTATION_CREATE_ITEM = gql`
    mutation createQuestion($input: CreateQuestionInput!){
        createQuestion(input: $input){
            id
            uuid
            code
            ref_uuid
            questionnaire_uuid
            questionnaire {
                id
                company
                uuid
                code
                ref_uuid
                title
                description
                touchpoint_uuid
            }
            title
            description
            is_library
            score_type
            sub_score_type
            payload
            company
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`
const MUTATION_CREATE_ITEMS = gql`
    mutation createQuestions($input: [CreateQuestionInput]!){
        createQuestions(input: $input){
            id
            uuid
            code
            ref_uuid
            questionnaire_uuid
            questionnaire {
                id
                company
                uuid
                code
                ref_uuid
                title
                description
                touchpoint_uuid
            }
            title
            description
            is_library
            score_type
            sub_score_type
            payload
            company
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`
const MUTATION_DELETE_ITEM = gql`
    mutation deleteQuestion($input: DeleteQuestionInput!){
        deleteQuestion(input: $input){
            id
            uuid
            code
            ref_uuid
            questionnaire_uuid
            questionnaire {
                id
                company
                uuid
                code
                ref_uuid
                title
                description
                touchpoint_uuid
            }
            title
            description
            is_library
            score_type
            sub_score_type
            payload
            company
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`
const MUTATION_DELETE_ITEMS = gql`
    mutation deleteQuestions($input: DeleteQuestionInput!){
        deleteQuestions(input: $input){
            id
            uuid
            code
            ref_uuid
            questionnaire_uuid
            questionnaire {
                id
                company
                uuid
                code
                ref_uuid
                title
                description
                touchpoint_uuid
            }
            title
            description
            is_library
            score_type
            sub_score_type
            payload
            company
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`
const MUTATION_EXPORT_ITEMS = gql`
    mutation exportQuestions($input: QuestionsInput!, $by: ActionByInput!){
        exportQuestions(input: $input, by: $by){
           uuid
           size
           key
           name
           status
           topic
           type
           url
           company
           created_at
           created_by
           updated_at
           updated_by
        }
    }
`
const MUTATION_UPDATE_ITEM = gql`
    mutation updateQuestion($input: UpdateQuestionInput!){
        updateQuestion(input: $input){
            id
            uuid
            code
            ref_uuid
            questionnaire_uuid
            questionnaire {
                id
                company
                uuid
                code
                ref_uuid
                title
                description
                touchpoint_uuid
            }
            title
            description
            is_library
            score_type
            sub_score_type
            payload
            company
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`

// ====================== CREATE GQL ====================== //
const questionGQL = {
    query: {
        getItem: QUERY_ITEM,
        getItems: QUERY_ITEMS,
        getLookupItems: QUERY_LOOKUP_ITEMS,
        getItemsByPage: QUERY_ITEMS_BY_PAGE,
    },
    mutation: {
        createItem: MUTATION_CREATE_ITEM,
        createItems: MUTATION_CREATE_ITEMS,
        deleteItem: MUTATION_DELETE_ITEM,
        deleteItems: MUTATION_DELETE_ITEMS,
        exportItems: MUTATION_EXPORT_ITEMS,
        updateItem: MUTATION_UPDATE_ITEM,
    },
    response: {
        getItem: "question",
        getItems: "questions",
        getLookupItems: "questions",
        getItemsByPage: "questionsByPage",
        createItem: "createQuestion",
        createItems: "createQuestions",
        deleteItem: "deleteQuestion",
        deleteItems: "deleteQuestions",
        exportItems: "exportQuestions",
        updateItem: "updateQuestion",
    }
}

// ====================== EXPORT ====================== //
export {
    questionGQL,
}