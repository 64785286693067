export default function FormControl(theme) {
    return {
        MuiFormControl: {
            styleOverrides: {
                root: {
                    '& label': {
                        transform: 'translate(14px, 10px) scale(1)',
                        lineHeight: 'unset',
                    },
                    '& label.MuiInputLabel-shrink': {
                        transform: 'translate(14px, -9px) scale(0.75)',
                    },
                },
            }
        }
    };
}