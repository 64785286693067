import { ApolloClient, InMemoryCache } from '@apollo/client';
import config from '../config';

var client;

const initGQL = () => {
    console.log('initGQL', config.server.GQL_API_ENDPOINT)
    client = new ApolloClient({
        uri: config.server.GQL_API_ENDPOINT,
        cache: new InMemoryCache(),
        defaultOptions: {
            query: {
                fetchPolicy: "no-cache"
            },
            mutate: {
                fetchPolicy: "no-cache"
            },
        }
    });
}

const getClient = () => {
    return client
}

export {
    client,
    getClient,
    initGQL,
}