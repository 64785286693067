import { gql } from '@apollo/client';

// ====================== QUERY ====================== //
const QUERY_ITEM = gql`
    query touchpoint($input: TouchpointInput!){
        touchpoint(input: $input){
            id
            uuid
            code
            name
            name_en
            name_th
            company
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`
const QUERY_ITEMS = gql`
    query touchpoints($input: TouchpointsInput){
        touchpoints(input: $input){
            id
            uuid
            code
            name
            name_en
            name_th
            company
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`
const QUERY_LOOKUP_ITEMS = gql`
    query touchpoints($input: TouchpointsInput){
        touchpoints(input: $input){
            # id
            uuid
            code
            name
            name_en
            name_th
            company
            # created_at
            # created_by
            # updated_at
            # updated_by
        }
    }
`
const QUERY_ITEMS_BY_PAGE = gql`
    query touchpointsByPage($input: TouchpointsByPageInput!){
        touchpointsByPage(input: $input){
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
            currentPage
            prevPage
            nextPage
            totalPages
            totalItems
            touchpoints{
                id
                uuid
                code
                name
                name_en
                name_th
                company
                created_at
                created_by
                updated_at
                updated_by
            }
        }
    }
`
// ====================== MUTATION ====================== //
const MUTATION_CREATE_ITEM = gql`
    mutation createTouchpoint($input: CreateTouchpointInput!){
        createTouchpoint(input: $input){
            id
            uuid
            code
            name
            name_en
            name_th
            company
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`
const MUTATION_CREATE_ITEMS = gql`
    mutation createTouchpoints($input: [CreateTouchpointInput]!){
        createTouchpoints(input: $input){
            id
            uuid
            code
            name
            name_en
            name_th
            company
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`
const MUTATION_DELETE_ITEM = gql`
    mutation deleteTouchpoint($input: DeleteTouchpointInput!){
        deleteTouchpoint(input: $input){
            id
            uuid
            code
            name
            name_en
            name_th
            company
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`
const MUTATION_DELETE_ITEMS = gql`
    mutation deleteTouchpoints($input: DeleteTouchpointInput!){
        deleteTouchpoints(input: $input){
            id
            uuid
            code
            name
            name_en
            name_th
            company
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`
const MUTATION_EXPORT_ITEMS = gql`
    mutation exportDepartments($input: DepartmentsInput!, $by: ActionByInput!){
        exportDepartments(input: $input, by: $by){
           uuid
           size
           key
           name
           status
           topic
           type
           url
           company
           created_at
           created_by
           updated_at
           updated_by
        }
    }
`
const MUTATION_UPDATE_ITEM = gql`
    mutation updateTouchpoint($input: UpdateTouchpointInput!){
        updateTouchpoint(input: $input){
            id
            uuid
            code
            name
            name_en
            name_th
            company
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`

// ====================== CREATE GQL ====================== //
const touchpointGQL = {
    query: {
        getItem: QUERY_ITEM,
        getItems: QUERY_ITEMS,
        getLookupItems: QUERY_LOOKUP_ITEMS,
        getItemsByPage: QUERY_ITEMS_BY_PAGE,
    },
    mutation: {
        createItem: MUTATION_CREATE_ITEM,
        createItems: MUTATION_CREATE_ITEMS,
        deleteItem: MUTATION_DELETE_ITEM,
        deleteItems: MUTATION_DELETE_ITEMS,
        exportItems: MUTATION_EXPORT_ITEMS,
        updateItem: MUTATION_UPDATE_ITEM,
    },
    response: {
        getItem: "touchpoint",
        getItems: "touchpoints",
        getLookupItems: "touchpoints",
        getItemsByPage: "touchpointsByPage",
        createItem: "createTouchpoint",
        createItems: "createTouchpoints",
        deleteItem: "deleteTouchpoint",
        deleteItems: "deleteTouchpoints",
        exportItems: "exportDepartments",
        updateItem: "updateTouchpoint",
    }
}

// ====================== EXPORT ====================== //
export {
    touchpointGQL,
}