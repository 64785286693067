// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { FundProjectionScreenOutlined, PieChartOutlined, LineChartOutlined, } from '@ant-design/icons';

// icons
const icons = {
  FundProjectionScreenOutlined,
  LineChartOutlined,
  PieChartOutlined,
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const widgetSetup = {
  id: 'group-widget-setup',
  title: <FormattedMessage id="widget-setup" />,
  type: 'group',
  children: [
    {
      id: 'chart-builder',
      title: <FormattedMessage id="chart-builder" />,
      type: 'item',
      url: '/widget-setup/chart-builder',
      icon: icons.LineChartOutlined,
      breadcrumbs: false,
    },
    {
      id: 'dashboard-setup',
      title: <FormattedMessage id="dashboard-setup" />,
      type: 'item',
      url: '/widget-setup/dashboard-setup',
      icon: icons.FundProjectionScreenOutlined,
      breadcrumbs: false,
    },
    {
      id: 'analytics-setup',
      title: <FormattedMessage id="analytics-setup" />,
      type: 'item',
      url: '/widget-setup/analytics-setup',
      icon: icons.PieChartOutlined,
      breadcrumbs: false,
    },
  ]
};

export default widgetSetup;
