// ==============================|| PRESET THEME - Eva Design ||============================== //

const TICX = (colors, mode) => {
  const { blue, red, gold, cyan, green, grey } = colors;
  const greyColors = {
    0: grey[0],
    50: grey[1],
    100: grey[2],
    200: grey[3],
    300: grey[4],
    400: grey[5],
    500: grey[6],
    600: grey[7],
    700: grey[8],
    800: grey[9],
    900: grey[10],
    A50: grey[15],
    A100: grey[11],
    A200: grey[12],
    A400: grey[13],
    A700: grey[14],
    A800: grey[16]
  };
  const contrastText = '#fff';

  // let primaryColors = ['#f0feff', '#f0fdff', '#c7f7ff', '#9eedff', '#6fd6f2', '#46bfe6', '#3097bf', '#1f7299', '#115173', '#0b334d'];
  // let secondaryColors = ['#f3f0ff', '#f3f0ff', '#f2f0ff', '#d6d1ff', '#a59ff2', '#7f7ccc', '#5d5da6', '#414380', '#414380', '#2d3059', greyColors[0], greyColors.A400, greyColors.A700];

  let primaryColors = ['#f3f0ff', '#f3f0ff', '#f2f0ff', '#d6d1ff', '#a59ff2', '#7f7ccc', '#5d5da6', '#414380', '#414380', '#2d3059'];
  let secondaryColors = ['#f0feff', '#f0fdff', '#c7f7ff', '#9eedff', greyColors[400], '#46bfe6', '#3097bf', '#1f7299', '#115173', '#0b334d', greyColors[0], greyColors.A400, greyColors.A700];


  let errorColors = [red[0], red[2], red[4], red[7], red[9]];
  let warningColors = [gold[0], gold[3], gold[5], gold[7], gold[9]];
  let infoColors = [cyan[0], cyan[3], cyan[5], cyan[7], cyan[9]];
  let successColors = [green[0], green[3], green[5], green[7], green[9]];

  let dataGridBorderColor = '#e6ebf1';
  let mainBackgroundColor = 'linear-gradient(296.15deg, #ADD8E6 -12.16%, rgba(214, 207, 255, 0.53) 61.81%, rgba(10, 227, 225, 0) 101.19%)';
  let headerBackgroundColor = 'rgb(255 255 255 / 80%);';

  if (mode === 'dark') {
    // primaryColors = ['#1c2134', '#1f294d', '#243462', '#273e83', '#2c4db0', '#305bdd', '#567fe9', '#80a4f4', '#a9c5f8', '#d2e2fb'];
    errorColors = ['#341d1b', '#b03725', '#dd3f27', '#e9664d', '#fbd6c9'];
    warningColors = ['#342a1a', '#83631a', '#dda116', '#e9ba3a', '#fbefb5'];
    infoColors = ['#202734', '#416fb0', '#4c88dd', '#74a8e9', '#ecf4fb'];
    successColors = ['#1f2e1c', '#449626', '#4fba28', '#74cf4d', '#e3fbd2'];
    dataGridBorderColor = 'rgb(75 72 72 / 87%)'
    mainBackgroundColor = 'linear-gradient(296.15deg, #011d26 -12.16%, rgb(84 80 104 / 53%) 61.81%, rgba(10, 227, 225, 0) 101.19%)';
    headerBackgroundColor = 'rgb(30 30 30 / 80%)'
  }

  return {
    primary: {
      lighter: primaryColors[0],
      100: primaryColors[1],
      200: primaryColors[2],
      light: primaryColors[3],
      400: primaryColors[4],
      main: primaryColors[5],
      dark: primaryColors[6],
      700: primaryColors[7],
      darker: primaryColors[8],
      900: primaryColors[9],
      contrastText
    },
    secondary: mode === 'dark' ?
      {
        lighter: greyColors[100],
        100: greyColors[100],
        200: greyColors[200],
        light: greyColors[300],
        400: greyColors[400],
        main: greyColors[500],
        600: greyColors[600],
        dark: greyColors[700],
        800: greyColors[800],
        darker: greyColors[900],
        A100: greyColors[0],
        A200: greyColors.A400,
        A300: greyColors.A700,
        contrastText: greyColors[0]
      } :
      {
        lighter: secondaryColors[0],
        100: secondaryColors[1],
        200: secondaryColors[2],
        light: secondaryColors[3],
        400: secondaryColors[4],
        main: secondaryColors[5],
        600: secondaryColors[6],
        dark: secondaryColors[7],
        800: secondaryColors[8],
        darker: secondaryColors[9],
        A100: secondaryColors[10],
        A200: secondaryColors[11],
        A300: secondaryColors[12],
        contrastText: greyColors[0]
      },
    error: {
      lighter: errorColors[0],
      light: errorColors[1],
      main: errorColors[2],
      dark: errorColors[3],
      darker: errorColors[4],
      contrastText
    },
    warning: {
      lighter: warningColors[0],
      light: warningColors[1],
      main: warningColors[2],
      dark: warningColors[3],
      darker: warningColors[4],
      contrastText: greyColors[100]
    },
    info: {
      lighter: infoColors[0],
      light: infoColors[1],
      main: infoColors[2],
      dark: infoColors[3],
      darker: infoColors[4],
      contrastText
    },
    success: {
      lighter: successColors[0],
      light: successColors[1],
      main: successColors[2],
      dark: successColors[3],
      darker: successColors[4],
      contrastText
    },
    grey: greyColors,
    custom: {
      dataGridBorderColor,
      mainBackgroundColor,
      headerBackgroundColor,
    }
  };
};

export default TICX;
