import { gql } from '@apollo/client';

// ====================== QUERY ====================== //
const QUERY_ITEM = gql`
    query log($input: LogInput!){
        log(input: $input){
            id
            uuid
            type
            name
            ref_uuid
            payload
            company
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`
const QUERY_ITEMS = gql`
    query logs($input: LogsInput){
        logs(input: $input){
            id
            uuid
            type
            name
            ref_uuid
            payload
            company
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`
const QUERY_LOOKUP_ITEMS = gql`
    query logs($input: LogsInput){
        logs(input: $input){
            # id
            uuid
            type
            name
            ref_uuid
            # payload
            company
            # created_at
            # created_by
            # updated_at
            # updated_by
        }
    }
`
const QUERY_ITEMS_BY_PAGE = gql`
    query logsByPage($input: LogsByPageInput!){
        logsByPage(input: $input){
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
            currentPage
            prevPage
            nextPage
            totalPages
            totalItems
            logs{
                id
                uuid
                type
                name
                ref_uuid
                payload
                company
                created_at
                created_by
                updated_at
                updated_by
            }
        }
    }
`

// ====================== MUTATION ====================== //
const MUTATION_CREATE_ITEM = gql`
    mutation createLog($input: CreateLogInput!){
        createLog(input: $input){
            id
            uuid
            type
            name
            ref_uuid
            payload
            company
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`
const MUTATION_CREATE_ITEMS = gql`
    mutation createLogs($input: [CreateLogInput]!){
        createLogs(input: $input){
            id
            uuid
            type
            name
            ref_uuid
            payload
            company
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`
const MUTATION_DELETE_ITEM = gql`
    mutation deleteLog($input: DeleteLogInput!){
        deleteLog(input: $input){
            id
            uuid
            type
            name
            ref_uuid
            payload
            company
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`
const MUTATION_DELETE_ITEMS = gql`
    mutation deleteLogs($input: DeleteLogInput!){
        deleteLogs(input: $input){
            id
            uuid
            type
            name
            ref_uuid
            payload
            company
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`
const MUTATION_EXPORT_ITEMS = gql`
    mutation exportLogs($input: LogsInput!, $by: ActionByInput!){
        exportLogs(input: $input, by: $by){
           uuid
           size
           key
           name
           status
           topic
           type
           url
           company
           created_at
           created_by
           updated_at
           updated_by
        }
    }
`
const MUTATION_UPDATE_ITEM = gql`
    mutation updateLog($input: UpdateLogInput!){
        updateLog(input: $input){
            id
            uuid
            type
            name
            ref_uuid
            payload
            company
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`

// ====================== CREATE GQL ====================== //
const logGQL = {
    query: {
        getItem: QUERY_ITEM,
        getItems: QUERY_ITEMS,
        getLookupItems: QUERY_LOOKUP_ITEMS,
        getItemsByPage: QUERY_ITEMS_BY_PAGE,
    },
    mutation: {
        createItem: MUTATION_CREATE_ITEM,
        createItems: MUTATION_CREATE_ITEMS,
        deleteItem: MUTATION_DELETE_ITEM,
        deleteItems: MUTATION_DELETE_ITEMS,
        exportItems: MUTATION_EXPORT_ITEMS,
        updateItem: MUTATION_UPDATE_ITEM,
    },
    response: {
        getItem: "log",
        getItems: "logs",
        getLookupItems: "logs",
        getItemsByPage: "logsByPage",
        createItem: "createLog",
        createItems: "createLogs",
        deleteItem: "deleteLog",
        deleteItems: "deleteLogs",
        exportItems: "exportLogs",
        updateItem: "updateLog",
    }
}

// ====================== EXPORT ====================== //
export {
    logGQL,
}