// action - state management
import { getLocalStorage, removeLocalStorage, setLocalStorage } from 'assets/js/hash';
import axios from 'axios';
import { convertToJson } from 'components/luckysheet/luckyToExcel.js';
import { client } from 'graphql/client';
import { datasetGQL } from 'graphql/dataset';
// import axios from 'axios';
// import config from '../../config';
import * as actionTypes from '../actions';
import { setCount } from './file.reducer';
import { openSnackbar } from './snackbar';

// ==============================|| STATE ||============================== //
const moduleConfig = {
    name: "dataset",
    item: "dataset",
    items: "datasets",
    actionTypes: {
        setItem: actionTypes.SET_DATASET,
        setItems: actionTypes.SET_DATASETS,
    },
    gql: datasetGQL,
}
export const initialState = {
    dataset: null,
    datasets: [],
    fieldsForUpdates: [
        "uuid",
        "channel_type",
        "name",
        "description",
        "updated_by",
        'file',
        'url',
        'expression',
        'table_name',
        'filters',
    ],
};
export const STEPS = [
    {
        step: 0,
        label: 'Select Type',
        backAction: (values, callback) => {
            if (callback) return callback(true);
            return true
        },
        nextAction: (values, callback) => {
            let { channel_type } = values;
            if (!channel_type || channel_type === "") {
                if (callback) return callback(null, 'Please select channel type.');
                return false;
            }
            if (callback) return callback(true);
            return true
        },
    },
    {
        step: 1,
        label: 'Settings',
        backAction: (values, callback) => {
            if (callback) return callback(true);
            return true
        },
        nextAction: (values, callback) => {
            let { name, description } = values;
            if (!name || name === "") {
                if (callback) return callback(null, 'Please enter dataset name.');
                return false;
            }
            if (callback) return callback(true);
            return true
        },
    },
    {
        step: 2,
        label: 'Import data', // create file, upload file, web api, select table
        backAction: (values, callback) => {
            if (callback) return callback(true);
            return true
        },
        nextAction: (values, callback) => {
            let { channel_type, file_name, file, url, table_name, filters } = values;
            if (channel_type && channel_type === "MANUAL_DATA" && (!file_name || file_name === "")) {
                if (callback) return callback(null, 'Please enter file name.');
                return false;
            }
            if (channel_type && channel_type === "UPLOAD_DATA" && (!file || file === "")) {
                // if (callback) return callback(null, 'Please upload file.');
                // return false;
            }
            if (channel_type && channel_type === "API_DATA" && (!url || url === "")) {
                if (callback) return callback(null, 'Please enter web api.');
                return false;
            }
            if (channel_type && channel_type === "TICX_DATA") {
                if ((!table_name || table_name === "") && callback) {
                    return callback(null, 'Please select table.');
                }
                if ((filters) && (!filters.from) && (filters.to) && callback) {
                    return callback(null, 'Please select start date.');
                }
            }
            if (callback) return callback(true);
            return true
        },
    },
    {
        step: 3,
        label: 'Preview data', // before save
        backAction: (values, callback) => {
            if (callback) return callback(true);
            return true
        },
        nextAction: (values, callback) => {
            if (callback) return callback(true);
            return true
        },
    },

]
export const TABLES = [
    { name: "Accommodation", table: 'accommodation' },
    { name: "Alert Configuration", table: 'alert_config' },
    { name: "Brand", table: 'brand' },
    { name: "Condition", table: 'condition' },
    { name: "Contact", table: 'contact' },
    { name: "Customer", table: 'customer' },
    { name: "Dataset", table: 'dataset' },
    { name: "Department", table: 'department' },
    { name: "Event", table: 'event' },
    { name: "File", table: 'file' },
    { name: "Invitation", table: 'invitation' },
    { name: "Key-Value", table: 'key_value' },
    { name: "Log", table: 'log' },
    { name: "Message Template", table: 'message_template' },
    { name: "Note", table: 'note' },
    { name: "Policy Configuration", table: 'policy_config' },
    { name: "PPM", table: 'ppm' },
    { name: "Prefill Configuration", table: 'prefill_config' },
    { name: "Project", table: 'project' },
    { name: "Question", table: 'question' },
    { name: "Questionnaire", table: 'questionnaire' },
    { name: "Response", table: 'response' },
    { name: "Staff", table: 'staff' },
    { name: "Survey", table: 'survey' },
    { name: "Token", table: 'token' },
    { name: "Touchpoint", table: 'touchpoint' },
    { name: "User", table: 'user' },
    { name: "Widget", table: 'widget' },
]

// ==============================|| COMMON ACTIONS ||============================== //
export const getDataset = async (values, callback) => {
    const { dataset_uuid, company, current_lucky_sheet, channel_type, url, expression, table_name, filters, is_change_file, file } = values;
    console.log(values)

    let data = {
        rows: 0,
        cols: 0,
        headers: [],
        sample: [],
        items: [],
    }
    let items = [];

    const create = (items) => {
        if (!items) return null
        let first = items.length > 0 ? items[0] : null;
        if (!first) return null
        return {
            rows: items.length,
            cols: Object.keys(first).length,
            headers: Object.keys(first),
            items: _.clone(items),
            sample: _.take(items, 10),
        }
    }

    try {
        if (channel_type === "MANUAL_DATA" || channel_type === "UPLOAD_DATA") {

            if (is_change_file) {
                // use local on updated form
                items = await convertToJson(current_lucky_sheet);
                data = create(items);
            } else {
                let input = {
                    company,
                    isGetAll: false,
                    channel_type,
                    file,
                }
                let res = await client.query({
                    query: moduleConfig.gql.query.getDataset,
                    variables: { input },
                });
                data = res?.data?.[moduleConfig.gql.response.getDataset];
            }

        }
        if (channel_type === "API_DATA") {
            let input = {
                company,
                isGetAll: false,
                channel_type,
                url,
                expression,
            }
            let res = await client.query({
                query: moduleConfig.gql.query.getDataset,
                variables: { input },
            });
            data = res?.data?.[moduleConfig.gql.response.getDataset];
        }
        if (channel_type === "TICX_DATA") {
            // call gql: getTicxData(input): [JSON]
            let input = {
                company,
                isGetAll: false,
                channel_type,
                table_name: table_name?.table,
                filters,
            }
            let res = await client.query({
                query: moduleConfig.gql.query.getDataset,
                variables: { input },
            });
            data = res?.data?.[moduleConfig.gql.response.getDataset];
        }
        if(dataset_uuid){
            let input = {
                company,
                isGetAll: false,
                uuid: dataset_uuid?.uuid,
            }
            let res = await client.query({
                query: moduleConfig.gql.query.getDataset,
                variables: { input },
            });
            data = res?.data?.[moduleConfig.gql.response.getDataset];
        }
        if (callback) callback(data)
    } catch (error) {
        if (callback) callback(data)
    }
}
export const getItem = (input, callback) => {
    console.log(`[[ CALL ${moduleConfig.name} getItem() ]]`, input)
    return (dispatch) => {
        client
            .query({
                query: moduleConfig.gql.query.getItem,
                variables: { input },
            })
            .then(({ data }) => {
                console.log('[[ RES ]]', data)
                let result = data?.[moduleConfig.gql.response.getItem];
                dispatch({ type: moduleConfig.actionTypes.setItem, payload: result });
                if (callback) callback(result, null);
                return
            })
            .catch((error) => {
                console.log('[[ ERROR ]]', error.message)
                dispatch({
                    type: actionTypes.SET_ERROR,
                    error,
                });
                if (callback) callback(null, error);
                return
            });
    };
}
export const getDatasetFromServer = (input, callback) => {
    console.log(`[[ CALL ${moduleConfig.name} getDataset() ]]`, input)
    return (dispatch) => {
        client
            .query({
                query: moduleConfig.gql.query.getDataset,
                variables: { input },
            })
            .then(({ data }) => {
                console.log('[[ RES ]]', data)
                let result = data?.[moduleConfig.gql.response.getDataset];
                // dispatch({ type: moduleConfig.actionTypes.setItem, payload: result });
                if (callback) callback(result, null);
                return
            })
            .catch((error) => {
                console.log('[[ ERROR ]]', error.message)
                dispatch({
                    type: actionTypes.SET_ERROR,
                    error,
                });
                if (callback) callback(null, error);
                return
            });
    };
}
export const getItems = (input, callback) => {
    console.log(`[[ CALL ${moduleConfig.name} getItems() ]]`, input)
    return (dispatch) => {
        client
            .query({
                query: moduleConfig.gql.query.getItems,
                variables: { input },
            })
            .then(({ data }) => {
                console.log('[[ RES ]]', data)
                let result = data?.[moduleConfig.gql.response.getItems];
                dispatch({ type: moduleConfig.actionTypes.setItems, payload: result });
                if (callback) callback(result, null);
                return
            })
            .catch((error) => {
                console.log('[[ ERROR ]]', error.message)
                dispatch({
                    type: actionTypes.SET_ERROR,
                    error,
                });
                if (callback) callback(null, error);
                return
            });
    };
}
export const getLookupItems = (input, callback) => {
    console.log(`[[ CALL ${moduleConfig.name} getLookupItems() ]]`, input)
    return (dispatch) => {
        client
            .query({
                query: moduleConfig.gql.query.getLookupItems,
                variables: { input },
            })
            .then(({ data }) => {
                console.log('[[ RES ]]', data)
                let result = data?.[moduleConfig.gql.response.getLookupItems];
                dispatch({ type: moduleConfig.actionTypes.setItems, payload: result });
                if (callback) callback(result, null);
                return
            })
            .catch((error) => {
                console.log('[[ ERROR ]]', error.message)
                dispatch({
                    type: actionTypes.SET_ERROR,
                    error,
                });
                if (callback) callback(null, error);
                return
            });
    };
}
export const getItemsByPage = (input, callback) => {
    console.log(`[[ CALL ${moduleConfig.name} getItemsByPage() ]]`, input)
    return (dispatch) => {
        client
            .query({
                query: moduleConfig.gql.query.getItemsByPage,
                variables: { input },
            })
            .then(({ data }) => {
                console.log('[[ RES ]]', data)
                let result = data?.[moduleConfig.gql.response.getItemsByPage]
                // dispatch({ type: moduleConfig.actionTypes.setItems, payload: result });
                if (callback) callback(result, null);
                return
            })
            .catch((error) => {
                console.log('[[ ERROR ]]', error.message)
                dispatch({
                    type: actionTypes.SET_ERROR,
                    error,
                });
                if (callback) callback(null, error);
                return
            });
    };
}
export const createItem = (input, callback) => {
    console.log(`[[ CALL ${moduleConfig.name} createItem() ]]`, input)
    return (dispatch) => {
        client
            .mutate({
                mutation: moduleConfig.gql.mutation.createItem,
                variables: { input },
            })
            .then(({ data }) => {
                console.log('[[ RES ]]', data)
                let result = data?.[moduleConfig.gql.response.createItem];
                dispatch(
                    openSnackbar({
                        open: true,
                        autoHideDuration: 5000,
                        message: `Create ${moduleConfig.item} success`,
                        variant: 'alert',
                        alert: {
                            variant: 'border',
                            icon: 'success',
                            color: 'success'
                        },
                        transition: 'SlideUp',
                        close: false,
                    })
                );
                if (callback) callback(result, null);
                return
            })
            .catch((error) => {
                console.log('[[ ERROR ]]', error.message)
                dispatch(
                    openSnackbar({
                        open: true,
                        autoHideDuration: 5000,
                        message: `${error?.response?.data?.message || error?.message}`,
                        variant: 'alert',
                        alert: {
                            variant: 'border',
                            icon: 'error',
                            color: 'error',
                        },
                        transition: 'SlideUp',
                        close: false,
                    })
                );
                if (callback) callback(null, error);
                return
            });
    };
}
export const createItems = (input, callback) => {
    console.log(`[[ CALL ${moduleConfig.name} createItems() ]]`, input)
    return (dispatch) => {
        client
            .mutate({
                mutation: moduleConfig.gql.mutation.createItems,
                variables: { input },
            })
            .then(({ data }) => {
                console.log('[[ RES ]]', data)
                let result = data?.[moduleConfig.gql.response.createItems];
                dispatch(
                    openSnackbar({
                        open: true,
                        autoHideDuration: 5000,
                        message: `Create ${moduleConfig.items} success`,
                        variant: 'alert',
                        alert: {
                            variant: 'border',
                            icon: 'success',
                            color: 'success'
                        },
                        transition: 'SlideUp',
                        close: false,
                    })
                );
                if (callback) callback(result, null);
                return
            })
            .catch((error) => {
                console.log('[[ ERROR ]]', error.message)
                dispatch(
                    openSnackbar({
                        open: true,
                        autoHideDuration: 5000,
                        message: `${error?.response?.data?.message || error?.message}`,
                        variant: 'alert',
                        alert: {
                            variant: 'border',
                            icon: 'error',
                            color: 'error',
                        },
                        transition: 'SlideUp',
                        close: false,
                    })
                );
                if (callback) callback(null, error);
                return
            });
    };
}
export const deleteItem = (input, callback) => {
    console.log(`[[ CALL ${moduleConfig.name} deleteItem() ]]`, input)
    return (dispatch) => {
        client
            .mutate({
                mutation: moduleConfig.gql.mutation.deleteItem,
                variables: { input },
            })
            .then(({ data }) => {
                console.log('[[ RES ]]', data)
                let result = data?.[moduleConfig.gql.response.deleteItem];
                dispatch(
                    openSnackbar({
                        open: true,
                        autoHideDuration: 5000,
                        message: `Delete ${moduleConfig.item} success`,
                        variant: 'alert',
                        alert: {
                            variant: 'border',
                            icon: 'success',
                            color: 'success'
                        },
                        transition: 'SlideUp',
                        close: false,
                    })
                );
                if (callback) callback(result, null);
                return
            })
            .catch((error) => {
                console.log('[[ ERROR ]]', error.message)
                dispatch(
                    openSnackbar({
                        open: true,
                        autoHideDuration: 5000,
                        message: `${error?.response?.data?.message || error?.message}`,
                        variant: 'alert',
                        alert: {
                            variant: 'border',
                            icon: 'error',
                            color: 'error',
                        },
                        transition: 'SlideUp',
                        close: false,
                    })
                );
                if (callback) callback(null, error);
                return
            });
    };
}
export const deleteItems = (input, callback) => {
    console.log(`[[ CALL ${moduleConfig.name} deleteItems() ]]`, input)
    return (dispatch) => {
        client
            .mutate({
                mutation: moduleConfig.gql.mutation.deleteItems,
                variables: { input },
            })
            .then(({ data }) => {
                console.log('[[ RES ]]', data)
                let result = data?.[moduleConfig.gql.response.deleteItems];
                dispatch(
                    openSnackbar({
                        open: true,
                        autoHideDuration: 5000,
                        message: `Delete ${moduleConfig.items} success`,
                        variant: 'alert',
                        alert: {
                            variant: 'border',
                            icon: 'success',
                            color: 'success'
                        },
                        transition: 'SlideUp',
                        close: false,
                    })
                );
                if (callback) callback(result, null);
                return
            })
            .catch((error) => {
                console.log('[[ ERROR ]]', error.message)
                dispatch(
                    openSnackbar({
                        open: true,
                        autoHideDuration: 5000,
                        message: `${error?.response?.data?.message || error?.message}`,
                        variant: 'alert',
                        alert: {
                            variant: 'border',
                            icon: 'error',
                            color: 'error',
                        },
                        transition: 'SlideUp',
                        close: false,
                    })
                );
                if (callback) callback(null, error);
                return
            });
    };
}
export const exportItems = (input, by, callback) => {
    console.log(`[[ CALL ${moduleConfig.name} exportItems() ]]`, input)
    return (dispatch) => {
        client
            .mutate({
                mutation: moduleConfig.gql.mutation.exportItems,
                variables: { input, by },
            })
            .then(({ data }) => {
                console.log('[[ RES ]]', data)
                let result = data?.[moduleConfig.gql.response.exportItems];
                dispatch(
                    openSnackbar({
                        open: true,
                        autoHideDuration: 5000,
                        message: `Export ${result?.name || 'file'} success`,
                        variant: 'alert',
                        alert: {
                            variant: 'border',
                            icon: 'success',
                            color: 'success'
                        },
                        transition: 'SlideUp',
                        close: false,
                    })
                );
                dispatch(setCount(1, (res)=>{}));
                if (callback) callback(result, null);
                return
            })
            .catch((error) => {
                console.log('[[ ERROR ]]', error.message)
                dispatch(
                    openSnackbar({
                        open: true,
                        autoHideDuration: 5000,
                        message: `${error?.response?.data?.message || error?.message}`,
                        variant: 'alert',
                        alert: {
                            variant: 'border',
                            icon: 'error',
                            color: 'error',
                        },
                        transition: 'SlideUp',
                        close: false,
                    })
                );
                if (callback) callback(null, error);
                return
            });
    };
}
export const updateItem = (input, callback) => {
    console.log(`[[ CALL ${moduleConfig.name} updateItem() ]]`, input)
    return (dispatch) => {
        client
            .mutate({
                mutation: moduleConfig.gql.mutation.updateItem,
                variables: { input },
            })
            .then(({ data }) => {
                console.log('[[ RES ]]', data)
                let result = data?.[moduleConfig.gql.response.updateItem];
                dispatch(
                    openSnackbar({
                        open: true,
                        autoHideDuration: 5000,
                        message: `Update ${moduleConfig.item} success`,
                        variant: 'alert',
                        alert: {
                            variant: 'border',
                            icon: 'success',
                            color: 'success'
                        },
                        transition: 'SlideUp',
                        close: false,
                    })
                );
                if (callback) callback(result, null);
                return
            })
            .catch((error) => {
                console.log('[[ ERROR ]]', error.message)
                dispatch(
                    openSnackbar({
                        open: true,
                        autoHideDuration: 5000,
                        message: `${error?.response?.data?.message || error?.message}`,
                        variant: 'alert',
                        alert: {
                            variant: 'border',
                            icon: 'error',
                            color: 'error',
                        },
                        transition: 'SlideUp',
                        close: false,
                    })
                );
                if (callback) callback(null, error);
                return
            });
    };
}

// ==============================|| CUSTOM ACTIONS ||============================== //


// ==============================|| REDUCER ||============================== //

const datasetReducer = (state = initialState, action) => {
    let { payload } = action;
    switch (action.type) {

        // ------------- COMMON ACTIONS ------------- //
        case moduleConfig.actionTypes.setItem:
            return {
                ...state,
                [`${moduleConfig.item}`]: payload,
            };
        case moduleConfig.actionTypes.setItems:
            return {
                ...state,
                [`${moduleConfig.items}`]: payload,
            };

        // ------------- CUSTOM ACTIONS ------------- //

        // ------------- ------------- ------------- //
        default:
            return state;
    }
};

export default datasetReducer;
