import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

// third-party
import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

// scroll bar
import 'simplebar/src/simplebar.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

// apex-chart
import 'assets/third-party/apex-chart.css';
import 'assets/third-party/react-table.css';

// Modern theme
import 'assets/css/app.css';
import 'assets/css/luckysheet.css';


// load mock apis
import '_api';

import 'assets/css/gridster.css';


// Default V2 theme
// import { StylesManager } from 'survey-core';
// StylesManager.applyTheme("default");
// var defaultThemeColors = StylesManager.ThemeColors["modern"];

// project import
import App from './App';
import { store, persister } from 'store';
import { ConfigProvider } from 'contexts/ConfigContext';
import reportWebVitals from './reportWebVitals';
import { initGQL, getClient } from 'graphql/client';
import { ApolloProvider } from '@apollo/client';
import config from './config';

// sentry
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { Integrations } from "@sentry/tracing";
import { Offline as OfflineIntegration, Tr } from "@sentry/integrations";
import { CaptureConsole } from "@sentry/integrations";

// jquery
import $ from "jquery";
window["$"] = window["jQuery"] = $;
require('jquery-mousewheel');

require("velocity-animate/velocity.js");
require("emotion-ratings/dist/emotion-ratings.js");
require("jquery-ui-dist/jquery-ui.js");

import "jquery-bar-rating/jquery.barrating.js";
import "jquery-bar-rating/dist/themes/fontawesome-stars.css";
import "easy-autocomplete/dist/easy-autocomplete.css";
import "jquery-ui-dist/jquery-ui.css";

// import "bootstrap/dist/css/bootstrap.css"
// import "bootstrap-datepicker/dist/css/bootstrap-datepicker.css";
// import "bootstrap-datepicker/dist/js/bootstrap-datepicker.js"
// import "bootstrap-slider/dist/css/bootstrap-slider.css";

import Inputmask from 'inputmask';
// import 'inputmask/dist/inputmask/phone-codes/phone';

import noUiSlider from 'nouislider';
import "nouislider/dist/nouislider.min.css";

import select2Init from 'select2';
import 'select2/dist/css/select2.min.css';

import Sortable from 'sortablejs';
import React from 'react';

// ==============================|| MAIN - REACT DOM RENDER  ||============================== //

if (config?.sentry?.API_ENDPOINT) {
  Sentry.init({
    dsn: config?.sentry?.API_ENDPOINT,
    integrations: [
      new BrowserTracing(),
      new CaptureConsole({
        levels: [
          // "log", 
          // "warn", 
          "error"
        ],
      }),
      new OfflineIntegration(),
      new Sentry.Integrations.TryCatch(),
      new Sentry.Integrations.Breadcrumbs({
        // Log calls to `console.log`, `console.debug`, etc
        console: true,
        // Log all click and keypress events
        // - When an object with `serializeAttribute` key is provided,
        //   Breadcrumbs integration will look for given attribute(s) in DOM elements,
        //   while generating the breadcrumb trails.
        //   Matched elements will be followed by their custom attributes,
        //   instead of their `id`s or `class` names.
        dom: true,

        // Log HTTP requests done with the Fetch API
        fetch: true,

        // Log calls to `history.pushState` and friends
        history: true,

        // Log whenever we send an event to the server
        sentry: true,

        // Log HTTP requests done with the XHR API
        xhr: true,
      }),
      new Sentry.Integrations.GlobalHandlers({
        onerror: true,
        onunhandledrejection: true,
      }),
      new Sentry.Integrations.LinkedErrors(),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

initGQL();

const client = getClient();
// console.log(client)

ReactDOM.render(
  <ReduxProvider store={store}>
    <PersistGate loading={null} persistor={persister}>
      <ConfigProvider>
        <BrowserRouter basename="/">
          <ApolloProvider client={client}>
            <App />
          </ApolloProvider>
        </BrowserRouter>
      </ConfigProvider>
    </PersistGate>
  </ReduxProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
