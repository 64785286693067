import { gql } from '@apollo/client';

// ====================== QUERY ====================== //
const QUERY_ITEM = gql`
    query response($input: ResponseInput!){
        response(input: $input){
            id
            uuid
            status
            type
            questionnaire_uuid
            questionnaire {
                company
                uuid
                code
                ref_uuid
                title
                touchpoint{
                    uuid
                    code
                    name
                }
            }
            survey_uuid
            survey {
                uuid
                code
                ref_uuid
                title
            }
            question_code
            # question {
            #     uuid
            #     code
            #     ref_uuid
            #     title
            #     is_library
            #     score_type
            #     sub_score_type
            #     payload
            # }
            question_name
            answer
            invitation_uuid
            invitation {
                uuid
                name
                ref_uuid
                prefill_config_uuid
                prefill_config {
                    uuid
                    name
                    fields
                }
            }
            token_uuid
            token {
                uuid
                payload
                email
                phone_number
            }
            submitted_uuid
            submitted_at
            submitted_by
            metadata_browser
            metadata_device
            metadata_model
            metadata_os
            metadata_user_agent
            metadata_vendor
            metadata_city
            metadata_region
            metadata_country
            metadata_ip_address
            prefill_payload
            pdpa_status
            page_no
            page_time_spent
            survey_time_spent
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`
const QUERY_ITEMS = gql`
    query responses($input: ResponsesInput){
        responses(input: $input){
            id
            uuid
            status
            type
            questionnaire_uuid
            questionnaire {
                company
                uuid
                code
                ref_uuid
                title
                touchpoint{
                    uuid
                    code
                    name
                }
            }
            survey_uuid
            survey {
                uuid
                code
                ref_uuid
                title
            }
            question_code
            # question {
            #     uuid
            #     code
            #     ref_uuid
            #     title
            #     is_library
            #     score_type
            #     sub_score_type
            #     payload
            # }
            question_name
            answer
            invitation_uuid
            invitation {
                uuid
                name
                ref_uuid
                prefill_config_uuid
                prefill_config {
                    uuid
                    name
                    fields
                }
            }
            token_uuid
            token {
                uuid
                payload
                email
                phone_number
            }
            submitted_uuid
            submitted_at
            submitted_by
            metadata_browser
            metadata_device
            metadata_model
            metadata_os
            metadata_user_agent
            metadata_vendor
            metadata_city
            metadata_region
            metadata_country
            metadata_ip_address
            prefill_payload
            pdpa_status
            page_no
            page_time_spent
            survey_time_spent
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`
const QUERY_LOOKUP_ITEMS = gql`
    query responses($input: ResponsesInput){
        responses(input: $input){
            # id
            uuid
            status
            type
            questionnaire_uuid
            questionnaire {
                uuid
                code
                ref_uuid
                title
                touchpoint{
                    uuid
                    code
                    name
                }
            }
            survey_uuid
            survey {
                uuid
                code
                ref_uuid
                title
            }
            question_code
            # question {
            #     uuid
            #     code
            #     ref_uuid
            #     title
            #     is_library
            #     score_type
            #     sub_score_type
            #     payload
            # }
            question_name
            answer
            invitation_uuid
            invitation {
                uuid
                name
                ref_uuid
                # prefill_config_uuid
                # prefill_config {
                #     uuid
                #     name
                #     fields
                # }
            }
            token_uuid
            token {
                uuid
                # payload
                email
                phone_number
            }
            submitted_uuid
            submitted_at
            submitted_by
            metadata_browser
            metadata_device
            metadata_model
            metadata_os
            metadata_user_agent
            metadata_vendor
            metadata_city
            metadata_region
            metadata_country
            metadata_ip_address
            prefill_payload
            pdpa_status
            # created_at
            # created_by
            # updated_at
            # updated_by
        }
    }
`
const QUERY_ITEMS_BY_PAGE = gql`
    query responsesByPage($input: ResponsesByPageInput!){
        responsesByPage(input: $input){
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
            currentPage
            prevPage
            nextPage
            totalPages
            totalItems
            responses{
                id
                uuid
                status
                type
                questionnaire_uuid
                questionnaire {
                    company
                    uuid
                    code
                    ref_uuid
                    title
                    touchpoint{
                        uuid
                        code
                        name
                    }
                }
                survey_uuid
                survey {
                    uuid
                    code
                    ref_uuid
                    title
                }
                question_code
                # question {
                #     uuid
                #     code
                #     ref_uuid
                #     title
                #     is_library
                #     score_type
                #     sub_score_type
                #     payload
                # }
                question_name
                answer
                invitation_uuid
                invitation {
                    uuid
                    name
                    ref_uuid
                    prefill_config_uuid
                    prefill_config {
                        uuid
                        name
                        fields
                    }
                }
                token_uuid
                token {
                    uuid
                    payload
                    email
                    phone_number
                }
                submitted_uuid
                submitted_at
                submitted_by
                metadata_browser
                metadata_device
                metadata_model
                metadata_os
                metadata_user_agent
                metadata_vendor
                metadata_city
                metadata_region
                metadata_country
                metadata_ip_address
                prefill_payload
                pdpa_status
                page_no
                page_time_spent
                survey_time_spent
                created_at
                created_by
                updated_at
                updated_by
            }
        }
    }
`
const QUERY_RESPONSE_FILTER = gql`
    query responseFilter($input: ResponseFilterInput!){
        responseFilter(input: $input){
            status
            type
            questionnaire_uuid
            questionnaire {
                company
                uuid
                code
                ref_uuid
                title
                touchpoint{
                    uuid
                    code
                    name
                }
                payload
            }
            survey_uuid
            survey {
                uuid
                code
                ref_uuid
                title
            }
            invitation_uuid
            invitation {
                uuid
                name
                ref_uuid
                prefill_config_uuid
                # prefill_config {
                #     uuid
                #     name
                #     fields
                # }
            }
            token_uuid
            token {
                uuid
                # payload
                email
                phone_number
                created_at
                created_by
            }
            submitted_uuid
            submitted_at
            submitted_by
            metadata_browser
            metadata_device
            metadata_model
            metadata_os
            metadata_user_agent
            metadata_vendor
            metadata_city
            metadata_region
            metadata_country
            metadata_ip_address
            prefill_payload
            pdpa_status
            survey_time_spent
            csat_score
            nps_score
            sentiment_score
            responses {
                id
                uuid
                question_code
                question_name
                answer
                prefill_payload
                pdpa_status
                page_no
                page_time_spent
                survey_time_spent
                created_at
                created_by
                updated_at
                updated_by
            }
            notes {
                id
                uuid
                type
                message
                note_file
                created_at
                created_by
                updated_at
                updated_by
            }
            last_note {
                id
                uuid
                type
                message
                note_file
                created_at
                created_by
                updated_at
                updated_by
            }
        }
    }
`
const QUERY_RESPONSE_FILTERS = gql`
    query responseFilters($input: ResponseFiltersInput){
        responseFilters(input: $input){
            status
            type
            questionnaire_uuid
            questionnaire {
                company
                uuid
                # code
                # ref_uuid
                title
                touchpoint{
                    uuid
                    # code
                    name
                }
                # payload
            }
            # survey_uuid
            # survey {
            #     uuid
            #     code
            #     ref_uuid
            #     title
            # }
            # invitation_uuid
            # invitation {
            #     uuid
            #     name
            #     ref_uuid
            #     prefill_config_uuid
            #     # prefill_config {
            #     #     uuid
            #     #     name
            #     #     fields
            #     # }
            # }
            # token_uuid
            token {
                uuid
                # payload
                email
                phone_number
                # created_at
                # created_by
            }
            submitted_uuid
            submitted_at
            submitted_by
            # metadata_browser
            # metadata_device
            # metadata_model
            # metadata_os
            # metadata_user_agent
            # metadata_vendor
            # metadata_city
            # metadata_region
            # metadata_country
            # metadata_ip_address
            prefill_payload_shortcut
            # pdpa_status
            survey_time_spent
            csat_score
            nps_score
            sentiment_score
            # responses {
            #     id
            #     uuid
            #     question_code
            #     question_name
            #     answer
            #     prefill_payload
            #     pdpa_status
            #     page_no
            #     page_time_spent
            #     survey_time_spent
            #     created_at
            #     created_by
            #     updated_at
            #     updated_by
            # }
            # notes {
            #     id
            #     uuid
            #     type
            #     message
            #     note_file
            #     created_at
            #     created_by
            #     updated_at
            #     updated_by
            # }
        }
    }
`
const QUERY_RESPONSE_FILTERS_BY_PAGE = gql`
    query responseFiltersByPage($input: ResponseFiltersByPageInput!){
        responseFiltersByPage(input: $input){
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
            currentPage
            prevPage
            nextPage
            totalPages
            totalItems
            responseFilters{
                status
                type
                questionnaire_uuid
                questionnaire {
                    company
                    uuid
                    # code
                    # ref_uuid
                    title
                    touchpoint{
                        uuid
                        # code
                        name
                    }
                    # payload
                }
                # survey_uuid
                # survey {
                #     uuid
                #     code
                #     ref_uuid
                #     title
                # }
                # invitation_uuid
                # invitation {
                #     uuid
                #     name
                #     ref_uuid
                #     prefill_config_uuid
                #     # prefill_config {
                #     #     uuid
                #     #     name
                #     #     fields
                #     # }
                # }
                # token_uuid
                token {
                    uuid
                    # payload
                    email
                    phone_number
                    # created_at
                    # created_by
                }
                submitted_uuid
                submitted_at
                submitted_by
                # metadata_browser
                # metadata_device
                # metadata_model
                # metadata_os
                # metadata_user_agent
                # metadata_vendor
                # metadata_city
                # metadata_region
                # metadata_country
                # metadata_ip_address
                prefill_payload_shortcut
                # pdpa_status
                survey_time_spent
                csat_score
                nps_score
                sentiment_score
                # responses {
                #     id
                #     uuid
                #     question_code
                #     question_name
                #     answer
                #     prefill_payload
                #     pdpa_status
                #     page_no
                #     page_time_spent
                #     survey_time_spent
                #     created_at
                #     created_by
                #     updated_at
                #     updated_by
                # }
                # notes {
                #     id
                #     uuid
                #     type
                #     message
                #     note_file
                #     created_at
                #     created_by
                #     updated_at
                #     updated_by
                # }
                last_note {
                    id
                    uuid
                    type
                    message
                    note_file
                    created_at
                    created_by
                    updated_at
                    updated_by
                }
            }
        }
    }
`
// ====================== MUTATION ====================== //
const MUTATION_CREATE_ITEM = gql`
    mutation createResponse($input: CreateResponseInput!){
        createResponse(input: $input){
            id
            uuid
            status
            type
            questionnaire_uuid
            questionnaire {
                company
                uuid
                code
                ref_uuid
                title
                touchpoint{
                    uuid
                    code
                    name
                }
            }
            survey_uuid
            survey {
                uuid
                code
                ref_uuid
                title
            }
            question_code
            # question {
            #     uuid
            #     code
            #     ref_uuid
            #     title
            #     is_library
            #     score_type
            #     sub_score_type
            #     payload
            # }
            question_name
            answer
            invitation_uuid
            invitation {
                uuid
                name
                ref_uuid
                prefill_config_uuid
                prefill_config {
                    uuid
                    name
                    fields
                }
            }
            token_uuid
            token {
                uuid
                payload
                email
                phone_number
            }
            submitted_uuid
            submitted_at
            submitted_by
            metadata_browser
            metadata_device
            metadata_model
            metadata_os
            metadata_user_agent
            metadata_vendor
            metadata_city
            metadata_region
            metadata_country
            metadata_ip_address
            prefill_payload
            pdpa_status
            page_no
            page_time_spent
            survey_time_spent
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`
const MUTATION_CREATE_ITEMS = gql`
    mutation createResponses($input: [CreateResponseInput]!){
        createResponses(input: $input){
            id
            uuid
            status
            type
            questionnaire_uuid
            questionnaire {
                company
                uuid
                code
                ref_uuid
                title
                touchpoint{
                    uuid
                    code
                    name
                }
            }
            survey_uuid
            survey {
                uuid
                code
                ref_uuid
                title
            }
            question_code
            # question {
            #     uuid
            #     code
            #     ref_uuid
            #     title
            #     is_library
            #     score_type
            #     sub_score_type
            #     payload
            # }
            question_name
            answer
            invitation_uuid
            invitation {
                uuid
                name
                ref_uuid
                prefill_config_uuid
                prefill_config {
                    uuid
                    name
                    fields
                }
            }
            token_uuid
            token {
                uuid
                payload
                email
                phone_number
            }
            submitted_uuid
            submitted_at
            submitted_by
            metadata_browser
            metadata_device
            metadata_model
            metadata_os
            metadata_user_agent
            metadata_vendor
            metadata_city
            metadata_region
            metadata_country
            metadata_ip_address
            prefill_payload
            pdpa_status
            page_no
            page_time_spent
            survey_time_spent
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`
const MUTATION_DELETE_ITEM = gql`
    mutation deleteResponse($input: DeleteResponseInput!){
        deleteResponse(input: $input){
            id
            uuid
            status
            type
            questionnaire_uuid
            questionnaire {
                company
                uuid
                code
                ref_uuid
                title
                touchpoint{
                    uuid
                    code
                    name
                }
            }
            survey_uuid
            survey {
                uuid
                code
                ref_uuid
                title
            }
            question_code
            # question {
            #     uuid
            #     code
            #     ref_uuid
            #     title
            #     is_library
            #     score_type
            #     sub_score_type
            #     payload
            # }
            question_name
            answer
            invitation_uuid
            invitation {
                uuid
                name
                ref_uuid
                prefill_config_uuid
                prefill_config {
                    uuid
                    name
                    fields
                }
            }
            token_uuid
            token {
                uuid
                payload
                email
                phone_number
            }
            submitted_uuid
            submitted_at
            submitted_by
            metadata_browser
            metadata_device
            metadata_model
            metadata_os
            metadata_user_agent
            metadata_vendor
            metadata_city
            metadata_region
            metadata_country
            metadata_ip_address
            prefill_payload
            pdpa_status
            page_no
            page_time_spent
            survey_time_spent
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`
const MUTATION_DELETE_ITEMS = gql`
    mutation deleteResponses($input: DeleteResponseInput!){
        deleteResponses(input: $input){
            id
            uuid
            status
            type
            questionnaire_uuid
            questionnaire {
                company
                uuid
                code
                ref_uuid
                title
                touchpoint{
                    uuid
                    code
                    name
                }
            }
            survey_uuid
            survey {
                uuid
                code
                ref_uuid
                title
            }
            question_code
            # question {
            #     uuid
            #     code
            #     ref_uuid
            #     title
            #     is_library
            #     score_type
            #     sub_score_type
            #     payload
            # }
            question_name
            answer
            invitation_uuid
            invitation {
                uuid
                name
                ref_uuid
                prefill_config_uuid
                prefill_config {
                    uuid
                    name
                    fields
                }
            }
            token_uuid
            token {
                uuid
                payload
                email
                phone_number
            }
            submitted_uuid
            submitted_at
            submitted_by
            metadata_browser
            metadata_device
            metadata_model
            metadata_os
            metadata_user_agent
            metadata_vendor
            metadata_city
            metadata_region
            metadata_country
            metadata_ip_address
            prefill_payload
            pdpa_status
            page_no
            page_time_spent
            survey_time_spent
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`
const MUTATION_EXPORT_ITEMS = gql`
    mutation exportResponses($input: ResponsesInput!, $by: ActionByInput!){
        exportResponses(input: $input, by: $by){
           uuid
           size
           key
           name
           status
           topic
           type
           url
           company
           created_at
           created_by
           updated_at
           updated_by
        }
    }
`
const MUTATION_EXPORT_RESPONSE_FILTERS = gql`
    mutation exportResponseFilters($input: ResponseFiltersInput!, $by: ActionByInput!){
        exportResponseFilters(input: $input, by: $by){
           uuid
           size
           key
           name
           status
           topic
           type
           url
           company
           created_at
           created_by
           updated_at
           updated_by
        }
    }
`
const MUTATION_SUBMIT_ITEMS = gql`
    mutation submitResponses($input: [CreateResponseInput]!){
        submitResponses(input: $input){
            id
            uuid
            status
            type
            questionnaire_uuid
            questionnaire {
                company
                uuid
                code
                ref_uuid
                title
                touchpoint{
                    uuid
                    code
                    name
                }
            }
            survey_uuid
            survey {
                uuid
                code
                ref_uuid
                title
            }
            question_code
            # question {
            #     uuid
            #     code
            #     ref_uuid
            #     title
            #     is_library
            #     score_type
            #     sub_score_type
            #     payload
            # }
            question_name
            answer
            invitation_uuid
            invitation {
                uuid
                name
                ref_uuid
                prefill_config_uuid
                prefill_config {
                    uuid
                    name
                    fields
                }
            }
            token_uuid
            token {
                uuid
                payload
                email
                phone_number
            }
            submitted_uuid
            submitted_at
            submitted_by
            metadata_browser
            metadata_device
            metadata_model
            metadata_os
            metadata_user_agent
            metadata_vendor
            metadata_city
            metadata_region
            metadata_country
            metadata_ip_address
            prefill_payload
            pdpa_status
            page_no
            page_time_spent
            survey_time_spent
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`
const MUTATION_UPDATE_ITEM = gql`
    mutation updateResponse($input: UpdateResponseInput!){
        updateResponse(input: $input){
            id
            uuid
            status
            type
            questionnaire_uuid
            questionnaire {
                company
                uuid
                code
                ref_uuid
                title
                touchpoint{
                    uuid
                    code
                    name
                }
            }
            survey_uuid
            survey {
                uuid
                code
                ref_uuid
                title
            }
            question_code
            # question {
            #     uuid
            #     code
            #     ref_uuid
            #     title
            #     is_library
            #     score_type
            #     sub_score_type
            #     payload
            # }
            question_name
            answer
            invitation_uuid
            invitation {
                uuid
                name
                ref_uuid
                prefill_config_uuid
                prefill_config {
                    uuid
                    name
                    fields
                }
            }
            token_uuid
            token {
                uuid
                payload
                email
                phone_number
            }
            submitted_uuid
            submitted_at
            submitted_by
            metadata_browser
            metadata_device
            metadata_model
            metadata_os
            metadata_user_agent
            metadata_vendor
            metadata_city
            metadata_region
            metadata_country
            metadata_ip_address
            prefill_payload
            pdpa_status
            page_no
            page_time_spent
            survey_time_spent
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`

// ====================== CREATE GQL ====================== //
const responseGQL = {
    query: {
        getItem: QUERY_ITEM,
        getItems: QUERY_ITEMS,
        getLookupItems: QUERY_LOOKUP_ITEMS,
        getItemsByPage: QUERY_ITEMS_BY_PAGE,
        getResponseFilter: QUERY_RESPONSE_FILTER,
        getResponseFilters: QUERY_RESPONSE_FILTERS,
        getResponseFiltersByPage: QUERY_RESPONSE_FILTERS_BY_PAGE,
    },
    mutation: {
        createItem: MUTATION_CREATE_ITEM,
        createItems: MUTATION_CREATE_ITEMS,
        deleteItem: MUTATION_DELETE_ITEM,
        deleteItems: MUTATION_DELETE_ITEMS,
        exportItems: MUTATION_EXPORT_ITEMS,
        exportResponseFilters: MUTATION_EXPORT_RESPONSE_FILTERS,
        submitItems: MUTATION_SUBMIT_ITEMS,
        updateItem: MUTATION_UPDATE_ITEM,
    },
    response: {
        getItem: "response",
        getItems: "responses",
        getLookupItems: "responses",
        getItemsByPage: "responsesByPage",
        getResponseFilter: "responseFilter",
        getResponseFilters: "responseFilters",
        getResponseFiltersByPage: "responseFiltersByPage",
        createItem: "createResponse",
        createItems: "createResponses",
        deleteItem: "deleteResponse",
        deleteItems: "deleteResponses",
        exportItems: "exportResponses",
        exportResponseFilters: "exportResponseFilters",
        submitItems: "submitResponses",
        updateItem: "updateResponse",
    }
}

// ====================== EXPORT ====================== //
export {
    responseGQL,
}