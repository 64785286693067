// third-party
import { FormattedMessage } from 'react-intl';

// assets
import {
  DashboardOutlined,
  MessageOutlined,
  BarChartOutlined
} from '@ant-design/icons';

// icons
const icons = {
  DashboardOutlined,
  MessageOutlined,
  BarChartOutlined
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const result = {
  id: 'group-result',
  title: <FormattedMessage id="result" />,
  type: 'group',
  children: [
    {
      id: 'dashboard',
      title: <FormattedMessage id="dashboard" />,
      description: "Dashboard result page",
      type: 'item',
      url: '/result/dashboard',
      icon: icons.DashboardOutlined,
      breadcrumbs: false,
    },
    {
      id: 'response',
      title: <FormattedMessage id="response" />,
      description: "All response and export data",
      type: 'item',
      url: '/result/response',
      icon: icons.MessageOutlined,
      breadcrumbs: false,
    },
    {
      id: 'analytics',
      title: <FormattedMessage id="analytics" />,
      description: "CX score metric and analytics model",
      type: 'item',
      url: '/result/analytics',
      icon: icons.BarChartOutlined,
      breadcrumbs: false,
    }
  ]
};

export default result;
