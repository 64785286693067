import { gql } from '@apollo/client';

// ====================== QUERY ====================== //
const QUERY_LOGIN = gql`
    query login($input: AuthUserInput!){
        login(input: $input){
            id
            company
            uuid
            is_agree_to_tos_and_pp
            name
            firstname
            lastname
            phone
            username
            # password
            email
            license
            level
            permission
            profile_image
            company
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`
const QUERY_ITEM = gql`
    query user($input: UserInput!){
        user(input: $input){
            id
            company
            uuid
            is_agree_to_tos_and_pp
            name
            firstname
            lastname
            phone
            username
            # password
            email
            license
            level
            permission
            profile_image
            company
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`
const QUERY_ITEMS = gql`
    query users($input: UsersInput){
        users(input: $input){
            id
            company
            uuid
            is_agree_to_tos_and_pp
            name
            firstname
            lastname
            phone
            username
            # password
            email
            license
            level
            permission
            profile_image
            company
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`
const QUERY_LOOKUP_ITEMS = gql`
    query users($input: UsersInput){
        users(input: $input){
            # id
            company
            uuid
            is_agree_to_tos_and_pp
            name
            firstname
            lastname
            phone
            username
            # password
            email
            license
            level
            # permission
            # profile_image
            company
            # created_at
            # created_by
            # updated_at
            # updated_by
        }
    }
`
const QUERY_ITEMS_BY_PAGE = gql`
    query usersByPage($input: UsersByPageInput!){
        usersByPage(input: $input){
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
            currentPage
            prevPage
            nextPage
            totalPages
            totalItems
            users{
                id
                company
                uuid
                is_agree_to_tos_and_pp
                name
                firstname
                lastname
                phone
                username
                # password
                email
                license
                level
                permission
                profile_image
                company
                created_at
                created_by
                updated_at
                updated_by
            }
        }
    }
`
// ====================== MUTATION ====================== //
const MUTATION_CHANGE_PASSWORD = gql`
    mutation changePassword($input: ChangePasswordInput!){
        changePassword(input: $input){
            id
            company
            uuid
            is_agree_to_tos_and_pp
            name
            firstname
            lastname
            phone
            username
            # password
            email
            license
            level
            permission
            profile_image
            company
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`
const MUTATION_CREATE_ITEM = gql`
    mutation createUser($input: CreateUserInput!){
        createUser(input: $input){
            id
            company
            uuid
            is_agree_to_tos_and_pp
            name
            firstname
            lastname
            phone
            username
            # password
            email
            license
            level
            permission
            profile_image
            company
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`
const MUTATION_CREATE_ITEMS = gql`
    mutation createUsers($input: [CreateUserInput]!){
        createUsers(input: $input){
            id
            company
            uuid
            is_agree_to_tos_and_pp
            name
            firstname
            lastname
            phone
            username
            # password
            email
            license
            level
            permission
            profile_image
            company
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`
const MUTATION_DELETE_ITEM = gql`
    mutation deleteUser($input: DeleteUserInput!){
        deleteUser(input: $input){
            id
            company
            uuid
            is_agree_to_tos_and_pp
            name
            firstname
            lastname
            phone
            username
            # password
            email
            license
            level
            permission
            profile_image
            company
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`
const MUTATION_DELETE_ITEMS = gql`
    mutation deleteUsers($input: DeleteUserInput!){
        deleteUsers(input: $input){
            id
            company
            uuid
            is_agree_to_tos_and_pp
            name
            firstname
            lastname
            phone
            username
            # password
            email
            license
            level
            permission
            profile_image
            company
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`
const MUTATION_EXPORT_ITEMS = gql`
    mutation exportUsers($input: UsersInput!, $by: ActionByInput!){
        exportUsers(input: $input, by: $by){
           uuid
           size
           key
           name
           status
           topic
           type
           url
           company
           created_at
           created_by
           updated_at
           updated_by
        }
    }
`
const MUTATION_UPDATE_ITEM = gql`
    mutation updateUser($input: UpdateUserInput!){
        updateUser(input: $input){
            id
            company
            uuid
            is_agree_to_tos_and_pp
            name
            firstname
            lastname
            phone
            username
            # password
            email
            license
            level
            permission
            profile_image
            company
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`

// ====================== CREATE GQL ====================== //
const userGQL = {
    query: {
        login: QUERY_LOGIN,
        getItem: QUERY_ITEM,
        getItems: QUERY_ITEMS,
        getLookupItems: QUERY_LOOKUP_ITEMS,
        getItemsByPage: QUERY_ITEMS_BY_PAGE,
    },
    mutation: {
        changePassword: MUTATION_CHANGE_PASSWORD,
        createItem: MUTATION_CREATE_ITEM,
        createItems: MUTATION_CREATE_ITEMS,
        deleteItem: MUTATION_DELETE_ITEM,
        deleteItems: MUTATION_DELETE_ITEMS,
        exportItems: MUTATION_EXPORT_ITEMS,
        updateItem: MUTATION_UPDATE_ITEM,
    },
    response: {
        login: "login",
        getItem: "user",
        getItems: "users",
        getLookupItems: "users",
        getItemsByPage: "usersByPage",
        changePassword: "changePassword",
        createItem: "createUser",
        createItems: "createUsers",
        deleteItem: "deleteUser",
        deleteItems: "deleteUsers",
        exportItems: "exportUsers",
        updateItem: "updateUser",
    }
}

// ====================== EXPORT ====================== //
export {
    userGQL,
}