// action - state management
import { UserOutlined } from '@ant-design/icons';
import { getLocalStorage, removeLocalStorage, setLocalStorage } from 'assets/js/hash';
import axios from 'axios';
import config from '../../config';
import { client } from 'graphql/client';
import { userGQL } from 'graphql/user';
import { REGISTER, LOGIN, LOGOUT, SET_AUTH_USER, SET_AUTH_USER_ACCOUNT, SET_USER, SET_USERS } from '../actions';
import { openSnackbar } from './snackbar';
import * as userReducer from './user.reducer';

const moduleConfig = {
  name: "user",
  item: "user",
  items: "users",
  actionTypes: {
    setItem: SET_USER,
    setItems: SET_USERS,
  },
  gql: userGQL,
}

// initial state
export const initialState = {
  isLoggedIn: false,
  isInitialized: false,
  user: null,
  account: null,
};

// ==============================|| AUTH REDUCER ||============================== //

export const isAuthenticated = (disable, callback) => {
  console.log('[[ CALL isAuthenticated() ]]', disable)
  return (dispatch) => {
    let isAuth = getLocalStorage("IS_AUTHEN") === "YES";
    if (isAuth) {
      let authen = getLocalStorage("AUTHEN");
      let { username } = authen
      dispatch({ type: SET_AUTH_USER, payload: authen });
      if (!disable) dispatch(userReducer.getItem({ username }, (res, error) => {
        if (res) {
          setLocalStorage('IS_AUTHEN', "YES");
          setLocalStorage('AUTHEN', res);
          dispatch({ type: SET_AUTH_USER, payload: res });
          dispatch({
            type: LOGIN,
            payload: {
              isLoggedIn: true,
              user: res
            }
          });
        }
      }))
    } else {
      removeLocalStorage('IS_AUTHEN');
      removeLocalStorage('AUTHEN');
      dispatch({ type: SET_AUTH_USER, payload: null });
      dispatch({ type: SET_AUTH_USER_ACCOUNT, payload: null });
      dispatch({
        type: LOGOUT
      });
    }
    if (callback) callback(isAuth)
    return isAuth
  }
};
export const login = (input, callback) => {
  console.log('[[ CALL login() ]]', input)
  return (dispatch) => {
    client
      .query({
        query: moduleConfig.gql.query.login,
        variables: { input },
      })
      .then(({ data }) => {
        console.log('[[ RES ]]', data)
        let result = data?.[moduleConfig.gql.response.login];
        setLocalStorage('IS_AUTHEN', "YES");
        setLocalStorage('AUTHEN', result);
        dispatch({ type: SET_AUTH_USER, payload: result });
        dispatch({
          type: LOGIN,
          payload: {
            isLoggedIn: true,
            user: result
          }
        });
        dispatch(
          openSnackbar({
            open: true,
            autoHideDuration: 5000,
            message: `${'Login success'}`,
            variant: 'alert',
            alert: {
              variant: 'filled',
              icon: 'user',
              color: 'info',
            },
            transition: 'SlideUp',
            close: false,
          })
        );
        if (callback) callback(result, null);
        return
      })
      .catch((error) => {
        console.log('[[ ERROR ]]', error.message)
        dispatch(
          openSnackbar({
            open: true,
            autoHideDuration: 5000,
            message: `${error?.response?.data?.message || error?.message}`,
            variant: 'alert',
            alert: {
              variant: 'filled',
              icon: 'user',
              color: 'error',
            },
            transition: 'SlideUp',
            close: false,
          })
        );
        removeLocalStorage('IS_AUTHEN');
        removeLocalStorage('AUTHEN');
        dispatch({ type: SET_AUTH_USER, payload: null });
        dispatch({ type: SET_AUTH_USER_ACCOUNT, payload: null });
        dispatch({
          type: LOGOUT
        });
        if (callback) callback(null, error);
        return
      });
  };
}
export const login2 = (input, callback) => {
  console.log('[[ CALL login() ]]', input)
  return (dispatch) => {
    axios({
      method: "post",
      url: `${config.server.API_ENDPOINT}/auth/login`,
      data: input,
      withCredentials: true,
      headers: { 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json' },
    }).then(response => {
      console.log('[[ RES ]]', response)
      let { status, message, access_token } = response.data;

      client
        .query({
          query: moduleConfig.gql.query.getItem,
          variables: { input: { username: input?.email } },
        })
        .then(({ data }) => {
          console.log('[[ RES ]]', data)
          let result = data?.[moduleConfig.gql.response.getItem];
          let mapRes = _.extend({}, response.data, result)
          setLocalStorage('IS_AUTHEN', "YES");
          setLocalStorage('AUTHEN', mapRes);
          dispatch({ type: SET_AUTH_USER, payload: mapRes });
          dispatch({
            type: LOGIN,
            payload: {
              isLoggedIn: true,
              user: mapRes
            }
          });
          dispatch(
            openSnackbar({
              open: true,
              autoHideDuration: 5000,
              message: `${message}`,
              variant: 'alert',
              alert: {
                variant: 'filled',
                icon: 'user',
                color: 'info'
              },
              transition: 'SlideUp',
              close: false,
            })
          );
          if (callback) callback(result, null);
          return
        })
        .catch((error) => {
          console.log('[[ ERROR ]]', error.message)
          dispatch(
            openSnackbar({
              open: true,
              autoHideDuration: 5000,
              message: `${error?.response?.data?.message || error?.message}`,
              variant: 'alert',
              alert: {
                variant: 'filled',
                icon: 'user',
                color: 'error',
              },
              transition: 'SlideUp',
              close: false,
            })
          );
          removeLocalStorage('IS_AUTHEN');
          removeLocalStorage('AUTHEN');
          dispatch({ type: SET_AUTH_USER, payload: null });
          dispatch({ type: SET_AUTH_USER_ACCOUNT, payload: null });
          dispatch({
            type: LOGOUT
          });
          if (callback) callback(null, error);
          return
        });
    }).catch((error) => {
      console.log('[[ ERROR ]]', error.message)
      dispatch(
        openSnackbar({
          open: true,
          autoHideDuration: 5000,
          message: `${error?.response?.data?.message || error?.message}`,
          variant: 'alert',
          alert: {
            variant: 'filled',
            icon: 'user',
            color: 'error',
          },
          transition: 'SlideUp',
          close: false,
        })
      );
      removeLocalStorage('IS_AUTHEN');
      removeLocalStorage('AUTHEN');
      dispatch({ type: SET_AUTH_USER, payload: null });
      dispatch({ type: SET_AUTH_USER_ACCOUNT, payload: null });
      dispatch({
        type: LOGOUT
      });
      if (callback) callback(null, error);
    });
  };
}
export const logout = (callback) => {
  console.log('[[ CALL logout() ]]')
  return (dispatch) => {
    removeLocalStorage('IS_AUTHEN');
    removeLocalStorage('AUTHEN');
    dispatch({ type: SET_AUTH_USER, payload: null });
    dispatch({ type: SET_AUTH_USER_ACCOUNT, payload: null });
    dispatch({
      type: LOGOUT
    });
    if (callback) callback();
    return
  };
}
export const logout2 = (callback) => {
  console.log('[[ CALL logout() ]]')
  return (dispatch) => {
    let isAuth = getLocalStorage("IS_AUTHEN") === "YES";
    if (isAuth) {
      axios({
        method: "get",
        url: `${config.server.API_ENDPOINT}/auth/logout`,
        withCredentials: true,
      }).then(response => {
        console.log('[[ RES ]]', response)
        let { status, message } = response.data;
  
        removeLocalStorage('IS_AUTHEN');
        removeLocalStorage('AUTHEN');
        dispatch({ type: SET_AUTH_USER, payload: null });
        dispatch({ type: SET_AUTH_USER_ACCOUNT, payload: null });
        dispatch({
          type: LOGOUT
        });
        if (callback) callback();
      }).catch((error) => {
        console.log('[[ ERROR ]]', error.message)
        removeLocalStorage('IS_AUTHEN');
        removeLocalStorage('AUTHEN');
        dispatch({ type: SET_AUTH_USER, payload: null });
        dispatch({ type: SET_AUTH_USER_ACCOUNT, payload: null });
        dispatch({
          type: LOGOUT
        });
        if (callback) callback();
      });
    } else {
      removeLocalStorage('IS_AUTHEN');
      removeLocalStorage('AUTHEN');
      dispatch({ type: SET_AUTH_USER, payload: null });
      dispatch({ type: SET_AUTH_USER_ACCOUNT, payload: null });
      dispatch({
        type: LOGOUT
      });
      if (callback) callback();
    }
    return true
  };
}
export const refresh = (disable, callback) => {
  console.log('[[ CALL refresh() ]]', disable)
  return (dispatch) => {
    let isAuth = getLocalStorage("IS_AUTHEN") === "YES";
    if (isAuth) {
      axios({
        method: "get",
        url: `${config.server.API_ENDPOINT}/auth/refresh`,
        withCredentials: true,
      }).then(response => {
        console.log('[[ RES1 ]]', response)
        let { status, message, access_token } = response.data;
  
        let authen = getLocalStorage("AUTHEN");
        let { username } = authen
        dispatch({ type: SET_AUTH_USER, payload: authen });
        if (!disable) {
          client
            .query({
              query: moduleConfig.gql.query.getItem,
              variables: { input: { username } },
            })
            .then(({ data }) => {
              console.log('[[ RES2 ]]', data)
              let result = data?.[moduleConfig.gql.response.getItem];
              let mapRes = _.extend({}, response.data, result)
              setLocalStorage('IS_AUTHEN', "YES");
              setLocalStorage('AUTHEN', mapRes);
              dispatch({ type: SET_AUTH_USER, payload: mapRes });
              dispatch({
                type: LOGIN,
                payload: {
                  isLoggedIn: true,
                  user: mapRes
                }
              });
            })
            .catch((error) => {
              console.log('[[ ERROR2 ]]', error.message)
              removeLocalStorage('IS_AUTHEN');
              removeLocalStorage('AUTHEN');
              dispatch({ type: SET_AUTH_USER, payload: null });
              dispatch({ type: SET_AUTH_USER_ACCOUNT, payload: null });
              dispatch({
                type: LOGOUT
              });
            });
        }
      }).catch((error) => {
        console.log('[[ ERROR1 ]]', error.message)
        removeLocalStorage('IS_AUTHEN');
        removeLocalStorage('AUTHEN');
        dispatch({ type: SET_AUTH_USER, payload: null });
        dispatch({ type: SET_AUTH_USER_ACCOUNT, payload: null });
        dispatch({
          type: LOGOUT
        });
      });
    } else {
      removeLocalStorage('IS_AUTHEN');
      removeLocalStorage('AUTHEN');
      dispatch({ type: SET_AUTH_USER, payload: null });
      dispatch({ type: SET_AUTH_USER_ACCOUNT, payload: null });
      dispatch({
        type: LOGOUT
      });

      if (callback) callback(isAuth)
      return isAuth
    }
  }
};
const auth = (state = initialState, action) => {
  switch (action.type) {
    case REGISTER: {
      const { user } = action.payload;
      return {
        ...state,
        user
      };
    }
    case LOGIN: {
      const { user } = action.payload;
      return {
        ...state,
        isLoggedIn: true,
        isInitialized: true,
        user
      };
    }
    case LOGOUT: {
      return {
        ...state,
        isInitialized: true,
        isLoggedIn: false,
        user: null
      };
    }

    // ------------- CUSTOM ACTIONS ------------- //
    case SET_AUTH_USER: {
      return {
        ...state,
        user: action.payload,
      }
    }
    case SET_AUTH_USER_ACCOUNT: {
      return {
        ...state,
        account: action.payload,
      }
    }
    // -------------------------------------
    default: {
      return { ...state };
    }
  }
};

export default auth;
