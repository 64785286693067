import { gql } from '@apollo/client';

// ====================== QUERY ====================== //
const QUERY_ITEM = gql`
    query token($input: TokenInput!){
        token(input: $input){
            id
            uuid
            invitation_uuid
            invitation {
                uuid
                name
            }
            payload
            email
            phone_number
            email_status
            sms_status
            token_usage
            company
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`
const QUERY_ITEMS = gql`
    query tokens($input: TokensInput){
        tokens(input: $input){
            id
            uuid
            invitation_uuid
            invitation {
                uuid
                name
            }
            payload
            email
            phone_number
            email_status
            sms_status
            token_usage
            company
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`
const QUERY_LOOKUP_ITEMS = gql`
    query tokens($input: TokensInput){
        tokens(input: $input){
            # id
            uuid
            # invitation_uuid
            # invitation {
            #     uuid
            #     name
            # }
            # payload
            email
            phone_number
            email_status
            sms_status
            token_usage
            company
            # created_at
            # created_by
            # updated_at
            # updated_by
        }
    }
`
const QUERY_ITEMS_BY_PAGE = gql`
    query tokensByPage($input: TokensByPageInput!){
        tokensByPage(input: $input){
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
            currentPage
            prevPage
            nextPage
            totalPages
            totalItems
            tokens{
                id
                uuid
                invitation_uuid
                invitation {
                    uuid
                    name
                }
                payload
                email
                phone_number
                email_status
                sms_status
                token_usage
                company
                created_at
                created_by
                updated_at
                updated_by
            }
        }
    }
`
// ====================== MUTATION ====================== //
const MUTATION_CREATE_ITEM = gql`
    mutation createToken($input: CreateTokenInput!){
        createToken(input: $input){
            id
            uuid
            invitation_uuid
            invitation {
                uuid
                name
            }
            payload
            email
            phone_number
            email_status
            sms_status
            token_usage
            company
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`
const MUTATION_CREATE_ITEMS = gql`
    mutation createTokens($input: [CreateTokenInput]!){
        createTokens(input: $input){
            id
            uuid
            invitation_uuid
            invitation {
                uuid
                name
            }
            payload
            email
            phone_number
            email_status
            sms_status
            token_usage
            company
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`
const MUTATION_DELETE_ITEM = gql`
    mutation deleteToken($input: DeleteTokenInput!){
        deleteToken(input: $input){
            id
            uuid
            invitation_uuid
            invitation {
                uuid
                name
            }
            payload
            email
            phone_number
            email_status
            sms_status
            token_usage
            company
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`
const MUTATION_DELETE_ITEMS = gql`
    mutation deleteTokens($input: DeleteTokenInput!){
        deleteTokens(input: $input){
            id
            uuid
            invitation_uuid
            invitation {
                uuid
                name
            }
            payload
            email
            phone_number
            email_status
            sms_status
            token_usage
            company
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`
const MUTATION_SEND_INVITATION = gql`
    mutation sendInvitation($input: SendInvitationInput!){
        sendInvitation(input: $input){
            id
            uuid
            invitation_uuid
            invitation {
                uuid
                name
            }
            payload
            email
            phone_number
            email_status
            sms_status
            token_usage
            company
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`
const MUTATION_EXPORT_ITEMS = gql`
    mutation exportTokens($input: TokensInput!, $by: ActionByInput!){
        exportTokens(input: $input, by: $by){
           uuid
           size
           key
           name
           status
           topic
           type
           url
           company
           created_at
           created_by
           updated_at
           updated_by
        }
    }
`
const MUTATION_UPDATE_ITEM = gql`
    mutation updateToken($input: UpdateTokenInput!){
        updateToken(input: $input){
            id
            uuid
            invitation_uuid
            invitation {
                uuid
                name
            }
            payload
            email
            phone_number
            email_status
            sms_status
            token_usage
            company
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`

// ====================== CREATE GQL ====================== //
const tokenGQL = {
    query: {
        getItem: QUERY_ITEM,
        getItems: QUERY_ITEMS,
        getLookupItems: QUERY_LOOKUP_ITEMS,
        getItemsByPage: QUERY_ITEMS_BY_PAGE,
    },
    mutation: {
        createItem: MUTATION_CREATE_ITEM,
        createItems: MUTATION_CREATE_ITEMS,
        deleteItem: MUTATION_DELETE_ITEM,
        deleteItems: MUTATION_DELETE_ITEMS,
        exportItems: MUTATION_EXPORT_ITEMS,
        sendInvitation: MUTATION_SEND_INVITATION,
        updateItem: MUTATION_UPDATE_ITEM,
    },
    response: {
        getItem: "token",
        getItems: "tokens",
        getLookupItems: "tokens",
        getItemsByPage: "tokensByPage",
        createItem: "createToken",
        createItems: "createTokens",
        deleteItem: "deleteToken",
        deleteItems: "deleteTokens",
        exportItems: "exportTokens",
        sendInvitation: "sendInvitation",
        updateItem: "updateToken",
    }
}

// ====================== EXPORT ====================== //
export {
    tokenGQL,
}