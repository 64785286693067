// action - state management
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import config from '../../config';
import { client } from 'graphql/client';
import { fileGQL } from 'graphql/file';
import * as actionTypes from '../actions';
import { openSnackbar } from './snackbar';
import moment from 'moment';
// ==============================|| STATE ||============================== //
const moduleConfig = {
    name: "file",
    item: "file",
    items: "files",
    actionTypes: {
        setItem: actionTypes.SET_FILE,
        setItems: actionTypes.SET_FILES,
        setCount: actionTypes.SET_FILE_COUNT,
    },
    gql: fileGQL,
}
export const initialState = {
    file: null,
    files: [],
    count: 0,
    fieldsForUpdates: [
        "uuid",
        "key",
        "name",
        "status",
        "topic",
        "type",
        "url",
        "updated_by",
    ],
};

// ==============================|| ACTIONS ||============================== //

export const getDownloadUrl = (path) => {
    return `${config.server.API_ENDPOINT}/file/download/${path}`
}
export const getListFiles = (input, callback) => {
    console.log(`[[ CALL getListFiles() ]]`, input);
    // return
    return axios({
        method: "GET",
        url: `${config.server.API_ENDPOINT}/file`,
        params: input,
    }).then(response => {
        console.log('[[ RES ]]', response)
        let result = response.data;
        if (callback) callback(result, null);
    }).catch((error) => {
        console.log('[[ ERROR ]]', error.message)
        if (callback) callback(null, error);
    });
}
export const uploadFile = (mapInput, module, file, isNotDisplayResult, callback) => {
    console.log(`[[ CALL uploadFile() ]]`, { module, file, isNotDisplayResult })
    return (dispatch) => {
        let input = _.extend({}, {
            module,
            uuid: uuidv4(),
            filename: file?.name,
            file,
            upload_at: moment().format('YYYY-MM-DD')
            // files,
        }, mapInput)
        let bodyFormData = new FormData();
        Object.keys(input).forEach(key => {
            if (input[key] == null || input[key] == undefined || input[key] == "") {
            } else {
                if (key === "files") {
                    let files = input[key];
                    files.forEach((file, index) => {
                        bodyFormData.append(`${'file'}_${index}`, file);
                    });
                } else {
                    bodyFormData.append(key, input[key]);
                }
            }
        });

        return axios({
            method: "post",
            url: `${config.server.API_ENDPOINT}/file/upload`,
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
        }).then(response => {
            console.log('[[ RES ]]', response)
            let result = response.data;
            if (!isNotDisplayResult) {
                dispatch(
                    openSnackbar({
                        open: true,
                        autoHideDuration: 5000,
                        message: `${result.message}`,
                        variant: 'alert',
                        alert: {
                            variant: 'border',
                            icon: 'success',
                            color: 'success'
                        },
                        transition: 'SlideUp',
                        close: false,
                    })
                );
            }
            if (callback) callback(result.data, null);
        }).catch((error) => {
            console.log('[[ ERROR ]]', error.message)
            if (!isNotDisplayResult) {
                dispatch(
                    openSnackbar({
                        open: true,
                        autoHideDuration: 5000,
                        message: `${error?.response?.data?.message || error?.message}`,
                        variant: 'alert',
                        alert: {
                            variant: 'border',
                            icon: 'error',
                            color: 'error',
                        },
                        transition: 'SlideUp',
                        close: false,
                    })
                );
            }
            if (callback) callback(null, error);
        });
    };
}

// ==============================|| COMMON ACTIONS ||============================== //

export const getItem = (input, callback) => {
    console.log(`[[ CALL ${moduleConfig.name} getItem() ]]`, input)
    return (dispatch) => {
        client
            .query({
                query: moduleConfig.gql.query.getItem,
                variables: { input },
            })
            .then(({ data }) => {
                console.log('[[ RES ]]', data)
                let result = data?.[moduleConfig.gql.response.getItem];
                dispatch({ type: moduleConfig.actionTypes.setItem, payload: result });
                if (callback) callback(result, null);
                return
            })
            .catch((error) => {
                console.log('[[ ERROR ]]', error.message)
                dispatch({
                    type: actionTypes.SET_ERROR,
                    error,
                });
                if (callback) callback(null, error);
                return
            });
    };
}
export const getItems = (input, callback) => {
    console.log(`[[ CALL ${moduleConfig.name} getItems() ]]`, input)
    return (dispatch) => {
        client
            .query({
                query: moduleConfig.gql.query.getItems,
                variables: { input },
            })
            .then(({ data }) => {
                console.log('[[ RES ]]', data)
                let result = data?.[moduleConfig.gql.response.getItems];
                dispatch({ type: moduleConfig.actionTypes.setItems, payload: result });
                if (callback) callback(result, null);
                return
            })
            .catch((error) => {
                console.log('[[ ERROR ]]', error.message)
                dispatch({
                    type: actionTypes.SET_ERROR,
                    error,
                });
                if (callback) callback(null, error);
                return
            });
    };
}
export const getLookupItems = (input, callback) => {
    console.log(`[[ CALL ${moduleConfig.name} getLookupItems() ]]`, input)
    return (dispatch) => {
        client
            .query({
                query: moduleConfig.gql.query.getLookupItems,
                variables: { input },
            })
            .then(({ data }) => {
                console.log('[[ RES ]]', data)
                let result = data?.[moduleConfig.gql.response.getLookupItems];
                dispatch({ type: moduleConfig.actionTypes.setItems, payload: result });
                if (callback) callback(result, null);
                return
            })
            .catch((error) => {
                console.log('[[ ERROR ]]', error.message)
                dispatch({
                    type: actionTypes.SET_ERROR,
                    error,
                });
                if (callback) callback(null, error);
                return
            });
    };
}
export const getItemsByPage = (input, callback) => {
    console.log(`[[ CALL ${moduleConfig.name} getItemsByPage() ]]`, input)
    return (dispatch) => {
        client
            .query({
                query: moduleConfig.gql.query.getItemsByPage,
                variables: { input },
            })
            .then(({ data }) => {
                console.log('[[ RES ]]', data)
                let result = data?.[moduleConfig.gql.response.getItemsByPage]
                // dispatch({ type: moduleConfig.actionTypes.setItems, payload: result });
                if (callback) callback(result, null);
                return
            })
            .catch((error) => {
                console.log('[[ ERROR ]]', error.message)
                dispatch({
                    type: actionTypes.SET_ERROR,
                    error,
                });
                if (callback) callback(null, error);
                return
            });
    };
}
export const createItem = (input, callback) => {
    console.log(`[[ CALL ${moduleConfig.name} createItem() ]]`, input)
    return (dispatch) => {
        client
            .mutate({
                mutation: moduleConfig.gql.mutation.createItem,
                variables: { input },
            })
            .then(({ data }) => {
                console.log('[[ RES ]]', data)
                let result = data?.[moduleConfig.gql.response.createItem];
                dispatch(
                    openSnackbar({
                        open: true,
                        autoHideDuration: 5000,
                        message: `Create ${moduleConfig.item} success`,
                        variant: 'alert',
                        alert: {
                            variant: 'border',
                            icon: 'success',
                            color: 'success'
                        },
                        transition: 'SlideUp',
                        close: false,
                    })
                );
                if (callback) callback(result, null);
                return
            })
            .catch((error) => {
                console.log('[[ ERROR ]]', error.message)
                dispatch(
                    openSnackbar({
                        open: true,
                        autoHideDuration: 5000,
                        message: `${error?.response?.data?.message || error?.message}`,
                        variant: 'alert',
                        alert: {
                            variant: 'border',
                            icon: 'error',
                            color: 'error',
                        },
                        transition: 'SlideUp',
                        close: false,
                    })
                );
                if (callback) callback(null, error);
                return
            });
    };
}
export const createItems = (input, callback) => {
    console.log(`[[ CALL ${moduleConfig.name} createItems() ]]`, input)
    return (dispatch) => {
        client
            .mutate({
                mutation: moduleConfig.gql.mutation.createItems,
                variables: { input },
            })
            .then(({ data }) => {
                console.log('[[ RES ]]', data)
                let result = data?.[moduleConfig.gql.response.createItems];
                dispatch(
                    openSnackbar({
                        open: true,
                        autoHideDuration: 5000,
                        message: `Create ${moduleConfig.items} success`,
                        variant: 'alert',
                        alert: {
                            variant: 'border',
                            icon: 'success',
                            color: 'success'
                        },
                        transition: 'SlideUp',
                        close: false,
                    })
                );
                if (callback) callback(result, null);
                return
            })
            .catch((error) => {
                console.log('[[ ERROR ]]', error.message)
                dispatch(
                    openSnackbar({
                        open: true,
                        autoHideDuration: 5000,
                        message: `${error?.response?.data?.message || error?.message}`,
                        variant: 'alert',
                        alert: {
                            variant: 'border',
                            icon: 'error',
                            color: 'error',
                        },
                        transition: 'SlideUp',
                        close: false,
                    })
                );
                if (callback) callback(null, error);
                return
            });
    };
}
export const deleteItem = (input, callback) => {
    console.log(`[[ CALL ${moduleConfig.name} deleteItem() ]]`, input)
    return (dispatch) => {
        client
            .mutate({
                mutation: moduleConfig.gql.mutation.deleteItem,
                variables: { input },
            })
            .then(({ data }) => {
                console.log('[[ RES ]]', data)
                let result = data?.[moduleConfig.gql.response.deleteItem];
                dispatch(
                    openSnackbar({
                        open: true,
                        autoHideDuration: 5000,
                        message: `Delete ${moduleConfig.item} success`,
                        variant: 'alert',
                        alert: {
                            variant: 'border',
                            icon: 'success',
                            color: 'success'
                        },
                        transition: 'SlideUp',
                        close: false,
                    })
                );
                if (callback) callback(result, null);
                return
            })
            .catch((error) => {
                console.log('[[ ERROR ]]', error.message)
                dispatch(
                    openSnackbar({
                        open: true,
                        autoHideDuration: 5000,
                        message: `${error?.response?.data?.message || error?.message}`,
                        variant: 'alert',
                        alert: {
                            variant: 'border',
                            icon: 'error',
                            color: 'error',
                        },
                        transition: 'SlideUp',
                        close: false,
                    })
                );
                if (callback) callback(null, error);
                return
            });
    };
}
export const deleteItems = (input, callback) => {
    console.log(`[[ CALL ${moduleConfig.name} deleteItems() ]]`, input)
    return (dispatch) => {
        client
            .mutate({
                mutation: moduleConfig.gql.mutation.deleteItems,
                variables: { input },
            })
            .then(({ data }) => {
                console.log('[[ RES ]]', data)
                let result = data?.[moduleConfig.gql.response.deleteItems];
                dispatch(
                    openSnackbar({
                        open: true,
                        autoHideDuration: 5000,
                        message: `Delete ${moduleConfig.items} success`,
                        variant: 'alert',
                        alert: {
                            variant: 'border',
                            icon: 'success',
                            color: 'success'
                        },
                        transition: 'SlideUp',
                        close: false,
                    })
                );
                if (callback) callback(result, null);
                return
            })
            .catch((error) => {
                console.log('[[ ERROR ]]', error.message)
                dispatch(
                    openSnackbar({
                        open: true,
                        autoHideDuration: 5000,
                        message: `${error?.response?.data?.message || error?.message}`,
                        variant: 'alert',
                        alert: {
                            variant: 'border',
                            icon: 'error',
                            color: 'error',
                        },
                        transition: 'SlideUp',
                        close: false,
                    })
                );
                if (callback) callback(null, error);
                return
            });
    };
}
export const exportItems = (input, by, callback) => {
    console.log(`[[ CALL ${moduleConfig.name} exportItems() ]]`, input)
    return (dispatch) => {
        client
            .mutate({
                mutation: moduleConfig.gql.mutation.exportItems,
                variables: { input, by },
            })
            .then(({ data }) => {
                console.log('[[ RES ]]', data)
                let result = data?.[moduleConfig.gql.response.exportItems];
                dispatch(
                    openSnackbar({
                        open: true,
                        autoHideDuration: 5000,
                        message: `Export ${result?.name || 'file'} success`,
                        variant: 'alert',
                        alert: {
                            variant: 'border',
                            icon: 'success',
                            color: 'success'
                        },
                        transition: 'SlideUp',
                        close: false,
                    })
                );
                dispatch(setCount(1, (res)=>{}));
                if (callback) callback(result, null);
                return
            })
            .catch((error) => {
                console.log('[[ ERROR ]]', error.message)
                dispatch(
                    openSnackbar({
                        open: true,
                        autoHideDuration: 5000,
                        message: `${error?.response?.data?.message || error?.message}`,
                        variant: 'alert',
                        alert: {
                            variant: 'border',
                            icon: 'error',
                            color: 'error',
                        },
                        transition: 'SlideUp',
                        close: false,
                    })
                );
                if (callback) callback(null, error);
                return
            });
    };
}
export const updateItem = (input, callback, noAlert) => {
    console.log(`[[ CALL ${moduleConfig.name} updateItem() ]]`, input)
    return (dispatch) => {
        client
            .mutate({
                mutation: moduleConfig.gql.mutation.updateItem,
                variables: { input },
            })
            .then(({ data }) => {
                console.log('[[ RES ]]', data)
                let result = data?.[moduleConfig.gql.response.updateItem];
                if (!noAlert) {
                    dispatch(
                        openSnackbar({
                            open: true,
                            autoHideDuration: 5000,
                            message: `Update ${moduleConfig.item} success`,
                            variant: 'alert',
                            alert: {
                                variant: 'border',
                                icon: 'success',
                                color: 'success'
                            },
                            transition: 'SlideUp',
                            close: false,
                        })
                    );
                }

                if (callback) callback(result, null);
                return
            })
            .catch((error) => {
                console.log('[[ ERROR ]]', error.message)
                if (!noAlert) {
                    dispatch(
                        openSnackbar({
                            open: true,
                            autoHideDuration: 5000,
                            message: `${error?.response?.data?.message || error?.message}`,
                            variant: 'alert',
                            alert: {
                                variant: 'border',
                                icon: 'error',
                                color: 'error',
                            },
                            transition: 'SlideUp',
                            close: false,
                        })
                    );
                }

                if (callback) callback(null, error);
                return
            });
    };
}
export const setCount = (n, callback) => {
    console.log(`[[ CALL ${moduleConfig.name} setCount() ]]`, n)
    return (dispatch) => {
        dispatch({ type: moduleConfig.actionTypes.setCount, payload: n });
        if (callback) callback(n, null);
        return
    };
}

// ==============================|| REDUCER ||============================== //

const fileReducer = (state = initialState, action) => {
    let { payload } = action;
    switch (action.type) {
        // ------------- COMMON ACTIONS ------------- //
        case moduleConfig.actionTypes.setItem:
            return {
                ...state,
                [`${moduleConfig.item}`]: payload,
            };
        case moduleConfig.actionTypes.setItems:
            return {
                ...state,
                [`${moduleConfig.items}`]: payload,
            };
        case moduleConfig.actionTypes.setCount:
            return {
                ...state,
                [`count`]: payload,
            };

        // ------------- CUSTOM ACTIONS ------------- //

        // ------------- ------------- ------------- //
        default:
            return state;
    }
};

export default fileReducer;
