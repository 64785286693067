import { gql } from '@apollo/client';

// ====================== QUERY ====================== //
const QUERY_ITEM = gql`
    query prefillConfig($input: PrefillConfigInput!){
        prefillConfig(input: $input){
            id
            uuid
            name
            type
            invitation_uuid
            fields
            company
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`
const QUERY_ITEMS = gql`
    query prefillConfigs($input: PrefillConfigsInput){
        prefillConfigs(input: $input){
            id
            uuid
            name
            type
            invitation_uuid
            fields
            company
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`
const QUERY_LOOKUP_ITEMS = gql`
    query prefillConfigs($input: PrefillConfigsInput){
        prefillConfigs(input: $input){
            # id
            uuid
            name
            type
            # invitation_uuid
            fields
            company
            # created_at
            # created_by
            # updated_at
            # updated_by
        }
    }
`
const QUERY_ITEMS_BY_PAGE = gql`
    query prefillConfigsByPage($input: PrefillConfigsByPageInput!){
        prefillConfigsByPage(input: $input){
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
            currentPage
            prevPage
            nextPage
            totalPages
            totalItems
            prefill_configs{
                id
                uuid
                name
                type
                invitation_uuid
                fields
                company
                created_at
                created_by
                updated_at
                updated_by
            }
        }
    }
`
// ====================== MUTATION ====================== //
const MUTATION_CREATE_ITEM = gql`
    mutation createPrefillConfig($input: CreatePrefillConfigInput!){
        createPrefillConfig(input: $input){
            id
            uuid
            name
            type
            invitation_uuid
            fields
            company
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`
const MUTATION_CREATE_ITEMS = gql`
    mutation createPrefillConfigs($input: [CreatePrefillConfigInput]!){
        createPrefillConfigs(input: $input){
            id
            uuid
            name
            type
            invitation_uuid
            fields
            company
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`
const MUTATION_DELETE_ITEM = gql`
    mutation deletePrefillConfig($input: DeletePrefillConfigInput!){
        deletePrefillConfig(input: $input){
            id
            uuid
            name
            type
            invitation_uuid
            fields
            company
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`
const MUTATION_DELETE_ITEMS = gql`
    mutation deletePrefillConfigs($input: DeletePrefillConfigInput!){
        deletePrefillConfigs(input: $input){
            id
            uuid
            name
            type
            invitation_uuid
            fields
            company
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`
const MUTATION_EXPORT_ITEMS = gql`
    mutation exportPrefillConfigs($input: PrefillConfigsInput!, $by: ActionByInput!){
        exportPrefillConfigs(input: $input, by: $by){
           uuid
           size
           key
           name
           status
           topic
           type
           url
           company
           created_at
           created_by
           updated_at
           updated_by
        }
    }
`
const MUTATION_UPDATE_ITEM = gql`
    mutation updatePrefillConfig($input: UpdatePrefillConfigInput!){
        updatePrefillConfig(input: $input){
            id
            uuid
            name
            type
            invitation_uuid
            fields
            company
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`

// ====================== CREATE GQL ====================== //
const prefillConfigGQL = {
    query: {
        getItem: QUERY_ITEM,
        getItems: QUERY_ITEMS,
        getLookupItems: QUERY_LOOKUP_ITEMS,
        getItemsByPage: QUERY_ITEMS_BY_PAGE,
    },
    mutation: {
        createItem: MUTATION_CREATE_ITEM,
        createItems: MUTATION_CREATE_ITEMS,
        deleteItem: MUTATION_DELETE_ITEM,
        deleteItems: MUTATION_DELETE_ITEMS,
        exportItems: MUTATION_EXPORT_ITEMS,
        updateItem: MUTATION_UPDATE_ITEM,
    },
    response: {
        getItem: "prefillConfig",
        getItems: "prefillConfigs",
        getLookupItems: "prefillConfigs",
        getItemsByPage: "prefillConfigsByPage",
        createItem: "createPrefillConfig",
        createItems: "createPrefillConfigs",
        deleteItem: "deletePrefillConfig",
        deleteItems: "deletePrefillConfigs",
        exportItems: "exportPrefillConfigs",
        updateItem: "updatePrefillConfig",
    }
}

// ====================== EXPORT ====================== //
export {
    prefillConfigGQL,
}