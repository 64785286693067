import { lazy } from 'react';

// project import
import MainLayout from 'layout/MainLayout';
import CommonLayout from 'layout/CommonLayout';
import Loadable from 'components/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';

// render - dashboard
// const DashboardDefault = Loadable(lazy(() => import('pages/dashboard/default')));
// const DashboardAnalytics = Loadable(lazy(() => import('pages/dashboard/analytics')));

// render - widget
// const WidgetStatistics = Loadable(lazy(() => import('pages/widget/statistics')));
// const WidgetData = Loadable(lazy(() => import('pages/widget/data')));
// const WidgetChart = Loadable(lazy(() => import('pages/widget/chart')));

// render - applications
// const AppChat = Loadable(lazy(() => import('pages/apps/chat')));
// const AppCalendar = Loadable(lazy(() => import('pages/apps/calendar')));
// const AppCustomerList = Loadable(lazy(() => import('pages/apps/customer/list')));

const UserProfile = Loadable(lazy(() => import('pages/apps/profiles/user')));
const UserTabPersonal = Loadable(lazy(() => import('sections/apps/profiles/user/TabPersonal')));
// const UserTabPayment = Loadable(lazy(() => import('sections/apps/profiles/user/TabPayment')));
const UserTabPassword = Loadable(lazy(() => import('sections/apps/profiles/user/TabPassword')));
const UserTabRoles = Loadable(lazy(() => import('sections/apps/profiles/user/TabRoles')));

// const AccountProfile = Loadable(lazy(() => import('pages/apps/profiles/account')));
// const AccountTabProfile = Loadable(lazy(() => import('sections/apps/profiles/account/TabProfile')));
// const AccountTabPersonal = Loadable(lazy(() => import('sections/apps/profiles/account/TabPersonal')));
// const AccountTabAccount = Loadable(lazy(() => import('sections/apps/profiles/account/TabAccount')));
// const AccountTabPassword = Loadable(lazy(() => import('sections/apps/profiles/account/TabPassword')));
// const AccountTabRole = Loadable(lazy(() => import('sections/apps/profiles/account/TabRole')));
// const AccountTabSettings = Loadable(lazy(() => import('sections/apps/profiles/account/TabSettings')));

// const ProfileUserList = Loadable(lazy(() => import('pages/apps/profiles/user-list')));
// const ProfileUserCard = Loadable(lazy(() => import('pages/apps/profiles/user-card')));

// const AppECommProducts = Loadable(lazy(() => import('pages/apps/e-commerce/product')));
// const AppECommProductDetails = Loadable(lazy(() => import('pages/apps/e-commerce/product-details')));
// const AppECommProductList = Loadable(lazy(() => import('pages/apps/e-commerce/products-list')));
// const AppECommCheckout = Loadable(lazy(() => import('pages/apps/e-commerce/checkout')));
// const AppECommAddProduct = Loadable(lazy(() => import('pages/apps/e-commerce/add-product')));

// render - forms & tables
// const FormsValidation = Loadable(lazy(() => import('pages/forms/validation')));
// const FormsWizard = Loadable(lazy(() => import('pages/forms/wizard')));

// const FormsLayoutBasic = Loadable(lazy(() => import('pages/forms/layouts/basic')));
// const FormsLayoutMultiColumn = Loadable(lazy(() => import('pages/forms/layouts/multi-column')));
// const FormsLayoutActionBar = Loadable(lazy(() => import('pages/forms/layouts/action-bar')));
// const FormsLayoutStickyBar = Loadable(lazy(() => import('pages/forms/layouts/sticky-bar')));

// const FormsPluginsMask = Loadable(lazy(() => import('pages/forms/plugins/mask')));
// const FormsPluginsClipboard = Loadable(lazy(() => import('pages/forms/plugins/clipboard')));
// const FormsPluginsRecaptcha = Loadable(lazy(() => import('pages/forms/plugins/re-captcha')));
// const FormsPluginsEditor = Loadable(lazy(() => import('pages/forms/plugins/editor')));
// const FormsPluginsDropzone = Loadable(lazy(() => import('pages/forms/plugins/dropzone')));

// const ReactTableBasic = Loadable(lazy(() => import('pages/tables/react-table/basic')));
// const ReactTableSorting = Loadable(lazy(() => import('pages/tables/react-table/sorting')));
// const ReactTableFiltering = Loadable(lazy(() => import('pages/tables/react-table/filtering')));
// const ReactTableGrouping = Loadable(lazy(() => import('pages/tables/react-table/grouping')));
// const ReactTablePagination = Loadable(lazy(() => import('pages/tables/react-table/pagination')));
// const ReactTableRowSelection = Loadable(lazy(() => import('pages/tables/react-table/row-selection')));
// const ReactTableExpanding = Loadable(lazy(() => import('pages/tables/react-table/expanding')));
// const ReactTableEditable = Loadable(lazy(() => import('pages/tables/react-table/editable')));
// const ReactTableDragDrop = Loadable(lazy(() => import('pages/tables/react-table/drag-drop')));
// const ReactTableColumnHiding = Loadable(lazy(() => import('pages/tables/react-table/column-hiding')));
// const ReactTableUmbrella = Loadable(lazy(() => import('pages/tables/react-table/umbrella')));

// render - charts & map
// const ChartApexchart = Loadable(lazy(() => import('pages/charts/apexchart')));
// const ChartOrganization = Loadable(lazy(() => import('pages/charts/org-chart')));

// table routing
// const MuiTableBasic = Loadable(lazy(() => import('pages/tables/mui-table/basic')));
// const MuiTableDense = Loadable(lazy(() => import('pages/tables/mui-table/dense')));
// const MuiTableEnhanced = Loadable(lazy(() => import('pages/tables/mui-table/enhanced')));
// const MuiTableDatatable = Loadable(lazy(() => import('pages/tables/mui-table/datatable')));
// const MuiTableCustom = Loadable(lazy(() => import('pages/tables/mui-table/custom')));
// const MuiTableFixedHeader = Loadable(lazy(() => import('pages/tables/mui-table/fixed-header')));
// const MuiTableCollapse = Loadable(lazy(() => import('pages/tables/mui-table/collapse')));

// pages routing
// const AuthLogin = Loadable(lazy(() => import('pages/auth/login')));
// const AuthRegister = Loadable(lazy(() => import('pages/auth/register')));
// const AuthForgotPassword = Loadable(lazy(() => import('pages/auth/forgot-password')));
// const AuthResetPassword = Loadable(lazy(() => import('pages/auth/reset-password')));
// const AuthCheckMail = Loadable(lazy(() => import('pages/auth/check-mail')));
// const AuthCodeVerification = Loadable(lazy(() => import('pages/auth/code-verification')));

const MaintenanceError = Loadable(lazy(() => import('pages/maintenance/404')));
const MaintenanceError500 = Loadable(lazy(() => import('pages/maintenance/500')));
const MaintenanceUnderConstruction = Loadable(lazy(() => import('pages/maintenance/under-construction')));
const MaintenanceComingSoon = Loadable(lazy(() => import('pages/maintenance/coming-soon')));

const AppContactUS = Loadable(lazy(() => import('pages/contact-us')));



// render - sample page
// const SamplePage = Loadable(lazy(() => import('pages/extra-pages/sample-page')));
// const PricingPage = Loadable(lazy(() => import('pages/extra-pages/pricing')));

// render - result page
const ResultDashBoard = Loadable(lazy(() => import('pages/result/dashboard')));
const ResultResponse = Loadable(lazy(() => import('pages/result/response')));
const ResultAnalystics = Loadable(lazy(() => import('pages/result/analytics')));

// render - survey setup page
const Survey = Loadable(lazy(() => import('pages/survey/survey')));
const SurveySetupQuestionnaireBuilder = Loadable(lazy(() => import('pages/survey-setup/questionnaire-builder/QuestionnaireBuilder')));
const SurveySetupSurveySummary = Loadable(lazy(() => import('pages/survey-setup/survey-summary')));
const SurveySetupInvitations = Loadable(lazy(() => import('pages/survey-setup/invitations')));

const WidgetSetupChartBuilder = Loadable(lazy(() => import('pages/widget-setup/chart-builder/ChartBuilder')));
const WidgetSetupDashboardSetup = Loadable(lazy(() => import('pages/widget-setup/dashboard-setup/DashboardSetup')));

// render - data management page
const DataManagementAccommodation = Loadable(lazy(() => import('pages/data-management/accommodation/Accommodation')));
const DataManagementAlertConfig = Loadable(lazy(() => import('pages/data-management/alert-config/AlertConfig')));
const DataManagementBrand = Loadable(lazy(() => import('pages/data-management/brand/Brand')));
const DataManagementChart = Loadable(lazy(() => import('pages/data-management/chart/Chart')));
const DataManagementContact = Loadable(lazy(() => import('pages/data-management/contact/Contact')));
const DataManagementCustomer = Loadable(lazy(() => import('pages/data-management/customer/Customer')));
const DataManagementDataset = Loadable(lazy(() => import('pages/data-management/dataset/Dataset')));
const DataManagementDepartment = Loadable(lazy(() => import('pages/data-management/department/Department')));
const DataManagementEvent = Loadable(lazy(() => import('pages/data-management/event/Event')));
const DataManagementFileStorage = Loadable(lazy(() => import('pages/data-management/file-storage/FileStorage')));
const DataManagementFileService = Loadable(lazy(() => import('pages/data-management/file-service/FileService')));
const DataManagementInvitation = Loadable(lazy(() => import('pages/data-management/invitation/Invitation')));
const DataManagementKeyValue = Loadable(lazy(() => import('pages/data-management/key-value/KeyValue')));
const DataManagementLog = Loadable(lazy(() => import('pages/data-management/log/Log')));
const DataManagementLookup = Loadable(lazy(() => import('pages/data-management/lookup/Lookup')));
const DataManagementMessageTemplate = Loadable(lazy(() => import('pages/data-management/message-template/MessageTemplate')));
const DataManagementNote = Loadable(lazy(() => import('pages/data-management/note/Note')));
const DataManagementPolicyConfig = Loadable(lazy(() => import('pages/data-management/policy-config/PolicyConfig')));
const DataManagementPPM = Loadable(lazy(() => import('pages/data-management/ppm/PPM')));
const DataManagementPrefillConfig = Loadable(lazy(() => import('pages/data-management/prefill-config/PrefillConfig')));
const DataManagementProject = Loadable(lazy(() => import('pages/data-management/project/Project')));
const DataManagementQuestion = Loadable(lazy(() => import('pages/data-management/question/Question')));
const DataManagementQuestionnaire = Loadable(lazy(() => import('pages/data-management/questionnaire/Questionnaire')));
const DataManagementResponse = Loadable(lazy(() => import('pages/data-management/response/Response')));
const DataManagementSendMessage = Loadable(lazy(() => import('pages/data-management/send-message/SendMessage')));
const DataManagementStaff = Loadable(lazy(() => import('pages/data-management/staff/Staff')));
const DataManagementSurvey = Loadable(lazy(() => import('pages/data-management/survey/Survey')));
const DataManagementToken = Loadable(lazy(() => import('pages/data-management/token/Token')));
const DataManagementTouchpoint = Loadable(lazy(() => import('pages/data-management/touchpoint/Touchpoint')));
const DataManagementUser = Loadable(lazy(() => import('pages/data-management/user/User')));
const DataManagementWidget = Loadable(lazy(() => import('pages/data-management/widget/Widget')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: 'result',
          children: [
            {
              path: 'dashboard',
              element: <ResultDashBoard />
            },
            {
              path: 'response',
              element: <ResultResponse />
            },
            {
              path: 'analytics',
              element: <MaintenanceComingSoon />
            },
          ]
        },
        {
          path: 'survey-setup',
          children: [
            {
              path: 'survey-summary',
              element: <SurveySetupSurveySummary />
            },
            {
              path: 'questionnaire-builder',
              element: <SurveySetupQuestionnaireBuilder />
            },
            {
              path: 'invitations',
              element: <SurveySetupInvitations />
            },
          ]
        },
        {
          path: 'data-management',
          children: [
            {
              path: 'accommodation',
              element: <DataManagementAccommodation />
            },
            {
              path: 'alert-config',
              element: <DataManagementAlertConfig />
            },
            {
              path: 'brand',
              element: <DataManagementBrand />
            },
            {
              path: 'chart',
              element: <DataManagementChart />
            },
            {
              path: 'contact',
              element: <DataManagementContact />
            },
            {
              path: 'customer',
              element: <DataManagementCustomer />
            },
            {
              path: 'dataset',
              element: <DataManagementDataset />
            },
            {
              path: 'department',
              element: <DataManagementDepartment />
            },
            {
              path: 'event',
              element: <DataManagementEvent />
            },
            {
              path: 'file-storage',
              element: <DataManagementFileStorage />
            },
            {
              path: 'file-service',
              element: <DataManagementFileService />
            },
            {
              path: 'invitation',
              element: <DataManagementInvitation />
            },
            {
              path: 'key-value',
              element: <DataManagementKeyValue />
            },
            {
              path: 'log',
              element: <DataManagementLog />
            },
            {
              path: 'lookup',
              element: <DataManagementLookup />
            },
            {
              path: 'message-template',
              element: <DataManagementMessageTemplate />
            },
            {
              path: 'note',
              element: <DataManagementNote />
            },
            {
              path: 'policy-config',
              element: <DataManagementPolicyConfig />
            },
            {
              path: 'ppm',
              element: <DataManagementPPM />
            },
            {
              path: 'prefill-config',
              element: <DataManagementPrefillConfig />
            },
            {
              path: 'project',
              element: <DataManagementProject />
            },
            {
              path: 'question',
              element: <DataManagementQuestion />
            },
            {
              path: 'questionnaire',
              element: <DataManagementQuestionnaire />
            },
            {
              path: 'response',
              element: <DataManagementResponse />
            },
            {
              path: 'send-message',
              element: <DataManagementSendMessage />
            },
            {
              path: 'staff',
              element: <DataManagementStaff />
            },
            {
              path: 'survey',
              element: <DataManagementSurvey />
            },
            {
              path: 'token',
              element: <DataManagementToken />
            },
            {
              path: 'touchpoint',
              element: <DataManagementTouchpoint />
            },
            {
              path: 'user',
              element: <DataManagementUser />
            },
            {
              path: 'widget',
              element: <DataManagementWidget />
            },
           
          ]
        },
        {
          path: 'widget-setup',
          children: [
            {
              path: 'analytics-setup',
              element: <MaintenanceComingSoon />
            },
            {
              path: 'chart-builder',
              element: <WidgetSetupChartBuilder />
            },
            {
              path: 'dashboard-setup',
              element: <WidgetSetupDashboardSetup />
            },
          ]
        },
        {
          path: 'profiles',
          children: [
            {
              path: 'user',
              element: <UserProfile />,
              children: [
                {
                  path: 'personal',
                  element: <UserTabPersonal />
                },
                {
                  path: 'password',
                  element: <UserTabPassword />
                },
                {
                  path: 'role',
                  element: <UserTabRoles />
                },
              ]
            },
          ]
        }
      ],
    },
    {
      path: '/survey',
      element: <CommonLayout />,
      children: [
        {
          path: ':uuid',
          element: <Survey />
        },
        {
          path: 'questionnaire/:uuid',
          element: <Survey />
        },
        {
          path: 'invitation/:uuid',
          element: <Survey />
        },
        {
          path: 'token/:uuid',
          element: <Survey />
        },
      ]
    },
    {
      path: '/',
      path: '/theme-preview',
      element: <MainLayout />,
      children: [
        // {
        //   path: 'dashboard',
        //   children: [
        //     {
        //       path: 'default',
        //       element: <DashboardDefault />
        //     },
        //     {
        //       path: 'analytics',
        //       element: <DashboardAnalytics />
        //     }
        //   ]
        // },
        // {
        //   path: 'widget',
        //   children: [
        //     {
        //       path: 'statistics',
        //       element: <WidgetStatistics />
        //     },
        //     {
        //       path: 'data',
        //       element: <WidgetData />
        //     },
        //     {
        //       path: 'chart',
        //       element: <WidgetChart />
        //     }
        //   ]
        // },
        // {
        //   path: 'apps',
        //   children: [
        //     {
        //       path: 'chat',
        //       element: <AppChat />
        //     },
        //     {
        //       path: 'calendar',
        //       element: <AppCalendar />
        //     },
        //     {
        //       path: 'customer',
        //       children: [
        //         {
        //           path: 'list',
        //           element: <AppCustomerList />
        //         }
        //       ]
        //     },
        //     {
        //       path: 'profiles',
        //       children: [
        //         {
        //           path: 'account',
        //           element: <AccountProfile />,
        //           children: [
        //             {
        //               path: 'basic',
        //               element: <AccountTabProfile />
        //             },
        //             {
        //               path: 'personal',
        //               element: <AccountTabPersonal />
        //             },
        //             {
        //               path: 'my-account',
        //               element: <AccountTabAccount />
        //             },
        //             {
        //               path: 'password',
        //               element: <AccountTabPassword />
        //             },
        //             {
        //               path: 'role',
        //               element: <AccountTabRole />
        //             },
        //             {
        //               path: 'settings',
        //               element: <AccountTabSettings />
        //             }
        //           ]
        //         },
        //         {
        //           path: 'user',
        //           element: <UserProfile />,
        //           children: [
        //             {
        //               path: 'personal',
        //               element: <UserTabPersonal />
        //             },
        //             {
        //               path: 'payment',
        //               element: <UserTabPayment />
        //             },
        //             {
        //               path: 'password',
        //               element: <UserTabPassword />
        //             },
        //             {
        //               path: 'settings',
        //               element: <UserTabSettings />
        //             }
        //           ]
        //         },
        //         {
        //           path: 'user-list',
        //           element: <ProfileUserList />
        //         },
        //         {
        //           path: 'user-card',
        //           element: <ProfileUserCard />
        //         }
        //       ]
        //     },
        //     {
        //       path: 'e-commerce',
        //       children: [
        //         {
        //           path: 'products',
        //           element: <AppECommProducts />
        //         },
        //         {
        //           path: 'product-details/:id',
        //           element: <AppECommProductDetails />
        //         },
        //         {
        //           path: 'product-list',
        //           element: <AppECommProductList />
        //         },
        //         {
        //           path: 'add-new-product',
        //           element: <AppECommAddProduct />
        //         },
        //         {
        //           path: 'checkout',
        //           element: <AppECommCheckout />
        //         }
        //       ]
        //     }
        //   ]
        // },
        // {
        //   path: 'forms',
        //   children: [
        //     {
        //       path: 'validation',
        //       element: <FormsValidation />
        //     },
        //     {
        //       path: 'wizard',
        //       element: <FormsWizard />
        //     },
        //     {
        //       path: 'layout',
        //       children: [
        //         {
        //           path: 'basic',
        //           element: <FormsLayoutBasic />
        //         },
        //         {
        //           path: 'multi-column',
        //           element: <FormsLayoutMultiColumn />
        //         },
        //         {
        //           path: 'action-bar',
        //           element: <FormsLayoutActionBar />
        //         },
        //         {
        //           path: 'sticky-bar',
        //           element: <FormsLayoutStickyBar />
        //         }
        //       ]
        //     },
        //     {
        //       path: 'plugins',
        //       children: [
        //         {
        //           path: 'mask',
        //           element: <FormsPluginsMask />
        //         },
        //         {
        //           path: 'clipboard',
        //           element: <FormsPluginsClipboard />
        //         },
        //         {
        //           path: 're-captcha',
        //           element: <FormsPluginsRecaptcha />
        //         },
        //         {
        //           path: 'editor',
        //           element: <FormsPluginsEditor />
        //         },
        //         {
        //           path: 'dropzone',
        //           element: <FormsPluginsDropzone />
        //         }
        //       ]
        //     }
        //   ]
        // },
        // {
        //   path: 'tables',
        //   children: [
        //     {
        //       path: 'react-table',
        //       children: [
        //         {
        //           path: 'basic',
        //           element: <ReactTableBasic />
        //         },
        //         {
        //           path: 'sorting',
        //           element: <ReactTableSorting />
        //         },
        //         {
        //           path: 'filtering',
        //           element: <ReactTableFiltering />
        //         },
        //         {
        //           path: 'grouping',
        //           element: <ReactTableGrouping />
        //         },
        //         {
        //           path: 'pagination',
        //           element: <ReactTablePagination />
        //         },
        //         {
        //           path: 'row-selection',
        //           element: <ReactTableRowSelection />
        //         },
        //         {
        //           path: 'expanding',
        //           element: <ReactTableExpanding />
        //         },
        //         {
        //           path: 'editable',
        //           element: <ReactTableEditable />
        //         },
        //         {
        //           path: 'drag-drop',
        //           element: <ReactTableDragDrop />
        //         },
        //         {
        //           path: 'column-hiding',
        //           element: <ReactTableColumnHiding />
        //         },
        //         {
        //           path: 'umbrella',
        //           element: <ReactTableUmbrella />
        //         }
        //       ]
        //     },
        //     {
        //       path: 'mui-table',
        //       children: [
        //         {
        //           path: 'basic',
        //           element: <MuiTableBasic />
        //         },
        //         {
        //           path: 'dense',
        //           element: <MuiTableDense />
        //         },
        //         {
        //           path: 'enhanced',
        //           element: <MuiTableEnhanced />
        //         },
        //         {
        //           path: 'datatable',
        //           element: <MuiTableDatatable />
        //         },
        //         {
        //           path: 'custom',
        //           element: <MuiTableCustom />
        //         },
        //         {
        //           path: 'fixed-header',
        //           element: <MuiTableFixedHeader />
        //         },
        //         {
        //           path: 'collapse',
        //           element: <MuiTableCollapse />
        //         }
        //       ]
        //     }
        //   ]
        // },
        // {
        //   path: 'charts',
        //   children: [
        //     {
        //       path: 'apexchart',
        //       element: <ChartApexchart />
        //     },
        //     {
        //       path: 'org-chart',
        //       element: <ChartOrganization />
        //     }
        //   ]
        // },
        // {
        //   path: 'sample-page',
        //   element: <SamplePage />
        // },
        // {
        //   path: 'pricing',
        //   element: <PricingPage />
        // }
      ]
    },
    {
      path: '/maintenance',
      element: <CommonLayout />,
      children: [
        {
          path: '404',
          element: <MaintenanceError />
        },
        {
          path: '500',
          element: <MaintenanceError500 />
        },
        {
          path: 'under-construction',
          element: <MaintenanceUnderConstruction />
        },
        {
          path: 'coming-soon',
          element: <MaintenanceComingSoon />
        }
      ]
    },
    {
      path: '/',
      element: <CommonLayout layout="simple" />,
      children: [
        {
          path: 'contact-us',
          element: <AppContactUS />
        }
      ]
    },

  ]
};

export default MainRoutes;
