import { gql } from '@apollo/client';

// ====================== QUERY ====================== //
const QUERY_ITEM = gql`
    query staff($input: StaffInput!){
        staff(input: $input){
            id
            uuid
            code
            staff_company
            company
            prefixname
            name
            firstname
            lastname
            department_uuid
            department {
                id
                uuid
                code
                name
            }
            role
            mobile
            email
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`
const QUERY_ITEMS = gql`
    query staffs($input: StaffsInput){
        staffs(input: $input){
            id
            uuid
            code
            staff_company
            company
            prefixname
            name
            firstname
            lastname
            department_uuid
            department {
                id
                uuid
                code
                name
            }
            role
            mobile
            email
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`
const QUERY_LOOKUP_ITEMS = gql`
    query staffs($input: StaffsInput){
        staffs(input: $input){
            # id
            uuid
            code
            staff_company
            company
            prefixname
            name
            firstname
            lastname
            department_uuid
            # department {
            #     id
            #     uuid
            #     code
            #     name
            # }
            # role
            mobile
            email
            # created_at
            # created_by
            # updated_at
            # updated_by
        }
    }
`
const QUERY_ITEMS_BY_PAGE = gql`
    query staffsByPage($input: StaffsByPageInput!){
        staffsByPage(input: $input){
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
            currentPage
            prevPage
            nextPage
            totalPages
            totalItems
            staffs{
                id
                uuid
                code
                staff_company
                company
                prefixname
                name
                firstname
                lastname
                department_uuid
                department {
                    id
                    uuid
                    code
                    name
                }
                role
                mobile
                email
                created_at
                created_by
                updated_at
                updated_by
            }
        }
    }
`
// ====================== MUTATION ====================== //
const MUTATION_CREATE_ITEM = gql`
    mutation createStaff($input: CreateStaffInput!){
        createStaff(input: $input){
            id
            uuid
            code
            staff_company
            company
            prefixname
            name
            firstname
            lastname
            department_uuid
            department {
                id
                uuid
                code
                name
            }
            role
            mobile
            email
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`
const MUTATION_CREATE_ITEMS = gql`
    mutation createStaffs($input: [CreateStaffInput]!){
        createStaffs(input: $input){
            id
            uuid
            code
            staff_company
            company
            prefixname
            name
            firstname
            lastname
            department_uuid
            department {
                id
                uuid
                code
                name
            }
            role
            mobile
            email
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`
const MUTATION_DELETE_ITEM = gql`
    mutation deleteStaff($input: DeleteStaffInput!){
        deleteStaff(input: $input){
            id
            uuid
            code
            staff_company
            company
            prefixname
            name
            firstname
            lastname
            department_uuid
            department {
                id
                uuid
                code
                name
            }
            role
            mobile
            email
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`
const MUTATION_DELETE_ITEMS = gql`
    mutation deleteStaffs($input: DeleteStaffInput!){
        deleteStaffs(input: $input){
            id
            uuid
            code
            staff_company
            company
            prefixname
            name
            firstname
            lastname
            department_uuid
            department {
                id
                uuid
                code
                name
            }
            role
            mobile
            email
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`
const MUTATION_EXPORT_ITEMS = gql`
    mutation exportStaffs($input: StaffsInput!, $by: ActionByInput!){
        exportStaffs(input: $input, by: $by){
           uuid
           size
           key
           name
           status
           topic
           type
           url
           company
           created_at
           created_by
           updated_at
           updated_by
        }
    }
`
const MUTATION_UPDATE_ITEM = gql`
    mutation updateStaff($input: UpdateStaffInput!){
        updateStaff(input: $input){
            id
            uuid
            code
            staff_company
            company
            prefixname
            name
            firstname
            lastname
            department_uuid
            department {
                id
                uuid
                code
                name
            }
            role
            mobile
            email
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`

// ====================== CREATE GQL ====================== //
const staffGQL = {
    query: {
        getItem: QUERY_ITEM,
        getItems: QUERY_ITEMS,
        getLookupItems: QUERY_LOOKUP_ITEMS,
        getItemsByPage: QUERY_ITEMS_BY_PAGE,
    },
    mutation: {
        createItem: MUTATION_CREATE_ITEM,
        createItems: MUTATION_CREATE_ITEMS,
        deleteItem: MUTATION_DELETE_ITEM,
        deleteItems: MUTATION_DELETE_ITEMS,
        exportItems: MUTATION_EXPORT_ITEMS,
        updateItem: MUTATION_UPDATE_ITEM,
    },
    response: {
        getItem: "staff",
        getItems: "staffs",
        getLookupItems: "staffs",
        getItemsByPage: "staffsByPage",
        createItem: "createStaff",
        createItems: "createStaffs",
        deleteItem: "deleteStaff",
        deleteItems: "deleteStaffs",
        exportItems: "exportStaffs",
        updateItem: "updateStaff",
    }
}

// ====================== EXPORT ====================== //
export {
    staffGQL,
}