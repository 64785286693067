// action - state management
import { getLocalStorage, removeLocalStorage, setLocalStorage } from 'assets/js/hash';
import { client } from 'graphql/client';
import { prefillConfigGQL } from 'graphql/prefill_config';
// import axios from 'axios';
// import config from '../../config';
import * as actionTypes from '../actions';
import { setCount } from './file.reducer';
import { openSnackbar } from './snackbar';

// ==============================|| STATE ||============================== //
const moduleConfig = {
    name: "prefill_config",
    item: "prefillConfig",
    items: "prefillConfigs",
    actionTypes: {
        setItem: actionTypes.SET_PREFILL_CONFIG,
        setItems: actionTypes.SET_PREFILL_CONFIGS,
    },
    gql: prefillConfigGQL,
}
export const initialState = {
    prefillConfig: null,
    prefillConfigs: [],
    defaultFields: [
        { key: "invitation.name", label: "Invitation Name", type: "string" },
        { key: "invitation.link", label: "Invitation Link", type: "string" },
        // { key: "token.name", label: "Token Name", type: "string" },
        { key: "token.link", label: "Token Link", type: "string" },
    ],
    tables: [
        // {
        //     table: "invitation",
        //     label: "Invitation",
        //     fields: [
        //         { key: "invitation.name", label: "Invitation Name", type: "string" },
        //         { key: "invitation.link", label: "Invitation Link", type: "string" },
        //     ]
        // },
        // {
        //     table: "token",
        //     label: "Token",
        //     fields: [
        //         { key: "token.name", label: "Token Name", type: "string" },
        //         { key: "token.link", label: "Token Link", type: "string" },
        //     ]
        // },

        {
            table: "project",
            label: "Project",
            fields: [
                { key: "project.code", label: "Project Code", type: "string" },
                { key: "project.name", label: "Project Name", type: "string" },
                { key: "project.type", label: "Project Type", type: "string" },
                { key: "project.project_company", label: "Project Company", type: "string" },
                { key: "project.status", label: "Project Status", type: "string" },
            ]
        },
        {
            table: "brand",
            label: "Brand",
            fields: [
                { key: "brand.name", label: "Brand Name", type: "string" },
            ]
        },
        {
            table: "staff",
            label: "Staff",
            fields: [
                { key: "staff.code", label: "Staff Code", type: "string" },
                { key: "staff.prefixname", label: "Staff Prefixname", type: "string" },
                { key: "staff.name", label: "Staff Name", type: "string" },
                { key: "staff.firstname", label: "Staff Firstname", type: "string" },
                { key: "staff.lastname", label: "Staff Lastname", type: "string" },
                { key: "staff.staff_company", label: "Staff Company", type: "string" },
                { key: "staff.mobile", label: "Staff Mobile", type: "string" },
                { key: "staff.email", label: "Staff Email", type: "string" },
            ]
        },
        {
            table: "ppm",
            label: "PPM",
            fields: [
                { key: "ppm.name", label: "Juristic Name", type: "string" },
            ]
        },
        {
            table: "department",
            label: "Department",
            fields: [
                { key: "department.name", label: "Department Name", type: "string" },
                { key: "department.report_to", label: "Report to Department", type: "string" },
                { key: "department.assign_to", label: "Assign to Department", type: "string" },
            ]
        },
        {
            table: "accommodation",
            label: "Accommodation",
            fields: [
                { key: "accommodation.unit_number", label: "Unit Number", type: "string" },
                { key: "accommodation.unit_type", label: "Unit Type", type: "string" },
                { key: "accommodation.house_number", label: "House Number", type: "string" },
                { key: "accommodation.sale_price", label: "Unit Sale Price", type: "float" },
                { key: "accommodation.rent_price", label: "Unit Rent Price", type: "float" },
                { key: "accommodation.detail", label: "Unit Detail", type: "string" },
            ]
        },
        {
            table: "customer",
            label: "Customer",
            fields: [
                { key: "customer.customer_id", label: "Customer ID", type: "string" },
                { key: "customer.code", label: "Customer Code", type: "string" },
                { key: "customer.prefixname", label: "Customer Prefixname", type: "string" },
                { key: "customer.name", label: "Customer Name", type: "string" },
                { key: "customer.firstname", label: "Customer Firstname", type: "string" },
                { key: "customer.lastname", label: "Customer Lastname", type: "string" },
                { key: "customer.type", label: "Customer Type", type: "string" },
                { key: "customer.nationality", label: "Customer Nationality", type: "string" },
                { key: "customer.status", label: "Customer Status", type: "string" },
                { key: "customer.coming_type", label: "Customer Coming Type", type: "string" },
                { key: "customer.level", label: "Customer Level", type: "string" },
                { key: "customer.mobile", label: "Customer Mobile", type: "string" },
                { key: "customer.email", label: "Customer Email", type: "string" },
                { key: "customer.gender", label: "Customer Gender", type: "string" },
                { key: "customer.birthday", label: "Customer Birthday", type: "date" },
                { key: "customer.address", label: "Customer Address", type: "string" },
                { key: "customer.channel", label: "Customer Channel", type: "string" },
            ]
        },
        {
            table: "event",
            label: "Event",
            fields: [
                { key: "event.case_id", label: "Case ID", type: "string" },
                { key: "event.case_name", label: "Case Name", type: "string" },
                { key: "event.case_topic", label: "Case Topic", type: "string" },
                { key: "event.case_detail", label: "Case Detail", type: "string" },
                { key: "event.event_location", label: "Event Location", type: "string" },
                { key: "event.event_type", label: "Event Type", type: "string" },
                { key: "event.event_date", label: "Event Date", type: "date" },
                { key: "event.visit_date", label: "Visit Date", type: "date" },
                { key: "event.booking_date", label: "Booking Date", type: "date" },
                { key: "event.contract_date", label: "Contract Date", type: "date" },
                { key: "event.transfer_date", label: "Transfer Date", type: "date" },
                { key: "event.warranty_date", label: "Warranty Date", type: "date" },
                { key: "event.open_date", label: "Open Date", type: "date" },
                { key: "event.close_date", label: "Close Date", type: "date" },
                { key: "event.create_date", label: "Create Date", type: "date" },
            ]
        },
        {
            table: "touchpoint",
            label: "Touchpoint",
            fields: [
                { key: "touchpoint.name", label: "Touchpoint Name", type: "string" },
            ]
        },
        {
            table: "token",
            label: "Token",
            fields: [
                { key: "token.token_usage", label: "Token Usage", type: "number" },
            ]
        },
        {
            table: "user",
            label: "User",
            fields: [
                { key: "user.username", label: "Username", type: "string" },
            ]
        },
    ],
    fieldsForUpdates: [
        "uuid",
        "name",
        "type",
        "invitation_uuid",
        "fields",
        "updated_by",
    ],
};

// ==============================|| COMMON ACTIONS ||============================== //

export const getItem = (input, callback) => {
    console.log(`[[ CALL ${moduleConfig.name} getItem() ]]`, input)
    return (dispatch) => {
        client
            .query({
                query: moduleConfig.gql.query.getItem,
                variables: { input },
            })
            .then(({ data }) => {
                console.log('[[ RES ]]', data)
                let result = data?.[moduleConfig.gql.response.getItem];
                dispatch({ type: moduleConfig.actionTypes.setItem, payload: result });
                if (callback) callback(result, null);
                return
            })
            .catch((error) => {
                console.log('[[ ERROR ]]', error.message)
                dispatch({
                    type: actionTypes.SET_ERROR,
                    error,
                });
                if (callback) callback(null, error);
                return
            });
    };
}
export const getItems = (input, callback) => {
    console.log(`[[ CALL ${moduleConfig.name} getItems() ]]`, input)
    return (dispatch) => {
        client
            .query({
                query: moduleConfig.gql.query.getItems,
                variables: { input },
            })
            .then(({ data }) => {
                console.log('[[ RES ]]', data)
                let result = data?.[moduleConfig.gql.response.getItems];
                dispatch({ type: moduleConfig.actionTypes.setItems, payload: result });
                if (callback) callback(result, null);
                return
            })
            .catch((error) => {
                console.log('[[ ERROR ]]', error.message)
                dispatch({
                    type: actionTypes.SET_ERROR,
                    error,
                });
                if (callback) callback(null, error);
                return
            });
    };
}
export const getLookupItems = (input, callback) => {
    console.log(`[[ CALL ${moduleConfig.name} getLookupItems() ]]`, input)
    return (dispatch) => {
        client
            .query({
                query: moduleConfig.gql.query.getLookupItems,
                variables: { input },
            })
            .then(({ data }) => {
                console.log('[[ RES ]]', data)
                let result = data?.[moduleConfig.gql.response.getLookupItems];
                dispatch({ type: moduleConfig.actionTypes.setItems, payload: result });
                if (callback) callback(result, null);
                return
            })
            .catch((error) => {
                console.log('[[ ERROR ]]', error.message)
                dispatch({
                    type: actionTypes.SET_ERROR,
                    error,
                });
                if (callback) callback(null, error);
                return
            });
    };
}
export const getItemsByPage = (input, callback) => {
    console.log(`[[ CALL ${moduleConfig.name} getItemsByPage() ]]`, input)
    return (dispatch) => {
        client
            .query({
                query: moduleConfig.gql.query.getItemsByPage,
                variables: { input },
            })
            .then(({ data }) => {
                console.log('[[ RES ]]', data)
                let result = data?.[moduleConfig.gql.response.getItemsByPage]
                // dispatch({ type: moduleConfig.actionTypes.setItems, payload: result });
                if (callback) callback(result, null);
                return
            })
            .catch((error) => {
                console.log('[[ ERROR ]]', error.message)
                dispatch({
                    type: actionTypes.SET_ERROR,
                    error,
                });
                if (callback) callback(null, error);
                return
            });
    };
}
export const createItem = (input, callback) => {
    console.log(`[[ CALL ${moduleConfig.name} createItem() ]]`, input)
    return (dispatch) => {
        client
            .mutate({
                mutation: moduleConfig.gql.mutation.createItem,
                variables: { input },
            })
            .then(({ data }) => {
                console.log('[[ RES ]]', data)
                let result = data?.[moduleConfig.gql.response.createItem];
                dispatch(
                    openSnackbar({
                        open: true,
                        autoHideDuration: 5000,
                        message: `Create ${moduleConfig.item} success`,
                        variant: 'alert',
                        alert: {
                            variant: 'border',
                            icon: 'success',
                            color: 'success'
                        },
                        transition: 'SlideUp',
                        close: false,
                    })
                );
                if (callback) callback(result, null);
                return
            })
            .catch((error) => {
                console.log('[[ ERROR ]]', error.message)
                dispatch(
                    openSnackbar({
                        open: true,
                        autoHideDuration: 5000,
                        message: `${error?.response?.data?.message || error?.message}`,
                        variant: 'alert',
                        alert: {
                            variant: 'border',
                            icon: 'error',
                            color: 'error',
                        },
                        transition: 'SlideUp',
                        close: false,
                    })
                );
                if (callback) callback(null, error);
                return
            });
    };
}
export const createItems = (input, callback) => {
    console.log(`[[ CALL ${moduleConfig.name} createItems() ]]`, input)
    return (dispatch) => {
        client
            .mutate({
                mutation: moduleConfig.gql.mutation.createItems,
                variables: { input },
            })
            .then(({ data }) => {
                console.log('[[ RES ]]', data)
                let result = data?.[moduleConfig.gql.response.createItems];
                dispatch(
                    openSnackbar({
                        open: true,
                        autoHideDuration: 5000,
                        message: `Create ${moduleConfig.items} success`,
                        variant: 'alert',
                        alert: {
                            variant: 'border',
                            icon: 'success',
                            color: 'success'
                        },
                        transition: 'SlideUp',
                        close: false,
                    })
                );
                if (callback) callback(result, null);
                return
            })
            .catch((error) => {
                console.log('[[ ERROR ]]', error.message)
                dispatch(
                    openSnackbar({
                        open: true,
                        autoHideDuration: 5000,
                        message: `${error?.response?.data?.message || error?.message}`,
                        variant: 'alert',
                        alert: {
                            variant: 'border',
                            icon: 'error',
                            color: 'error',
                        },
                        transition: 'SlideUp',
                        close: false,
                    })
                );
                if (callback) callback(null, error);
                return
            });
    };
}
export const deleteItem = (input, callback) => {
    console.log(`[[ CALL ${moduleConfig.name} deleteItem() ]]`, input)
    return (dispatch) => {
        client
            .mutate({
                mutation: moduleConfig.gql.mutation.deleteItem,
                variables: { input },
            })
            .then(({ data }) => {
                console.log('[[ RES ]]', data)
                let result = data?.[moduleConfig.gql.response.deleteItem];
                dispatch(
                    openSnackbar({
                        open: true,
                        autoHideDuration: 5000,
                        message: `Delete ${moduleConfig.item} success`,
                        variant: 'alert',
                        alert: {
                            variant: 'border',
                            icon: 'success',
                            color: 'success'
                        },
                        transition: 'SlideUp',
                        close: false,
                    })
                );
                if (callback) callback(result, null);
                return
            })
            .catch((error) => {
                console.log('[[ ERROR ]]', error.message)
                dispatch(
                    openSnackbar({
                        open: true,
                        autoHideDuration: 5000,
                        message: `${error?.response?.data?.message || error?.message}`,
                        variant: 'alert',
                        alert: {
                            variant: 'border',
                            icon: 'error',
                            color: 'error',
                        },
                        transition: 'SlideUp',
                        close: false,
                    })
                );
                if (callback) callback(null, error);
                return
            });
    };
}
export const deleteItems = (input, callback) => {
    console.log(`[[ CALL ${moduleConfig.name} deleteItems() ]]`, input)
    return (dispatch) => {
        client
            .mutate({
                mutation: moduleConfig.gql.mutation.deleteItems,
                variables: { input },
            })
            .then(({ data }) => {
                console.log('[[ RES ]]', data)
                let result = data?.[moduleConfig.gql.response.deleteItems];
                dispatch(
                    openSnackbar({
                        open: true,
                        autoHideDuration: 5000,
                        message: `Delete ${moduleConfig.items} success`,
                        variant: 'alert',
                        alert: {
                            variant: 'border',
                            icon: 'success',
                            color: 'success'
                        },
                        transition: 'SlideUp',
                        close: false,
                    })
                );
                if (callback) callback(result, null);
                return
            })
            .catch((error) => {
                console.log('[[ ERROR ]]', error.message)
                dispatch(
                    openSnackbar({
                        open: true,
                        autoHideDuration: 5000,
                        message: `${error?.response?.data?.message || error?.message}`,
                        variant: 'alert',
                        alert: {
                            variant: 'border',
                            icon: 'error',
                            color: 'error',
                        },
                        transition: 'SlideUp',
                        close: false,
                    })
                );
                if (callback) callback(null, error);
                return
            });
    };
}
export const exportItems = (input, by, callback) => {
    console.log(`[[ CALL ${moduleConfig.name} exportItems() ]]`, input)
    return (dispatch) => {
        client
            .mutate({
                mutation: moduleConfig.gql.mutation.exportItems,
                variables: { input, by },
            })
            .then(({ data }) => {
                console.log('[[ RES ]]', data)
                let result = data?.[moduleConfig.gql.response.exportItems];
                dispatch(
                    openSnackbar({
                        open: true,
                        autoHideDuration: 5000,
                        message: `Export ${result?.name || 'file'} success`,
                        variant: 'alert',
                        alert: {
                            variant: 'border',
                            icon: 'success',
                            color: 'success'
                        },
                        transition: 'SlideUp',
                        close: false,
                    })
                );
                dispatch(setCount(1, (res)=>{}));
                if (callback) callback(result, null);
                return
            })
            .catch((error) => {
                console.log('[[ ERROR ]]', error.message)
                dispatch(
                    openSnackbar({
                        open: true,
                        autoHideDuration: 5000,
                        message: `${error?.response?.data?.message || error?.message}`,
                        variant: 'alert',
                        alert: {
                            variant: 'border',
                            icon: 'error',
                            color: 'error',
                        },
                        transition: 'SlideUp',
                        close: false,
                    })
                );
                if (callback) callback(null, error);
                return
            });
    };
}
export const updateItem = (input, callback) => {
    console.log(`[[ CALL ${moduleConfig.name} updateItem() ]]`, input)
    return (dispatch) => {
        client
            .mutate({
                mutation: moduleConfig.gql.mutation.updateItem,
                variables: { input },
            })
            .then(({ data }) => {
                console.log('[[ RES ]]', data)
                let result = data?.[moduleConfig.gql.response.updateItem];
                dispatch(
                    openSnackbar({
                        open: true,
                        autoHideDuration: 5000,
                        message: `Update ${moduleConfig.item} success`,
                        variant: 'alert',
                        alert: {
                            variant: 'border',
                            icon: 'success',
                            color: 'success'
                        },
                        transition: 'SlideUp',
                        close: false,
                    })
                );
                if (callback) callback(result, null);
                return
            })
            .catch((error) => {
                console.log('[[ ERROR ]]', error.message)
                dispatch(
                    openSnackbar({
                        open: true,
                        autoHideDuration: 5000,
                        message: `${error?.response?.data?.message || error?.message}`,
                        variant: 'alert',
                        alert: {
                            variant: 'border',
                            icon: 'error',
                            color: 'error',
                        },
                        transition: 'SlideUp',
                        close: false,
                    })
                );
                if (callback) callback(null, error);
                return
            });
    };
}

// ==============================|| CUSTOM ACTIONS ||============================== //


// ==============================|| REDUCER ||============================== //

const prefillConfigReducer = (state = initialState, action) => {
    let { payload } = action;
    switch (action.type) {

        // ------------- COMMON ACTIONS ------------- //
        case moduleConfig.actionTypes.setItem:
            return {
                ...state,
                [`${moduleConfig.item}`]: payload,
            };
        case moduleConfig.actionTypes.setItems:
            return {
                ...state,
                [`${moduleConfig.items}`]: payload,
            };

        // ------------- CUSTOM ACTIONS ------------- //

        // ------------- ------------- ------------- //
        default:
            return state;
    }
};

export default prefillConfigReducer;
