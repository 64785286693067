// project import
// import applications from './applications';
// import dashboard from './dashboard';
// import widget from './widget';
// import formsTables from './forms-tables';
// import chartsMap from './charts-map';
// import other from './other';
// import pages from './pages';
import result from './result';
import widgetSetup from './widget-setup';
import surveySetup from './survey-setup';
import dataManagement from './data-management';
// import config from 'config';

// ==============================|| MENU ITEMS ||============================== //
const menuItems = {
  items: [
    result,
    surveySetup,
    dataManagement,
    widgetSetup,
    // ============ Theme Preview Menus ===========
    // config.displayThemePreview ? { ...dashboard } : null,
    // config.displayThemePreview ? { ...widget } : null,
    // config.displayThemePreview ? { ...applications } : null,
    // config.displayThemePreview ? { ...formsTables } : null,
    // config.displayThemePreview ? { ...chartsMap } : null,
    // config.displayThemePreview ? { ...pages } : null,
    // config.displayThemePreview ? { ...other } : null,
  ].filter((menu)=>menu)
};

export default menuItems;
