// action - state management
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import config from '../../config';
import * as actionTypes from '../actions';
import { openSnackbar } from './snackbar';
// ==============================|| STATE ||============================== //

export const initialState = {
};

// ==============================|| ACTIONS ||============================== //

export const sendSMS = (input, isNotDisplayResult, callback) => {
    console.log(`[[ CALL sendSMS() ]]`, input)
    // return (dispatch) => {
        return axios({
            method: "post",
            url: `${config.server.API_ENDPOINT}/send-message/sms`,
            data: input,
            headers: { "Content-Type": "application/json; charset=utf-8" },
        }).then(response => {
            console.log('[[ RES ]]', response)
            let result = response.data;
            if (callback) callback(result, null);
        }).catch((error) => {
            console.log('[[ ERROR ]]', error.message)
            if (callback) callback(null, error);
        });
    // };
}
export const sendEmail = (input, isNotDisplayResult, callback) => {
    console.log(`[[ CALL sendSMS() ]]`, input)
    // return (dispatch) => {
        return axios({
            method: "post",
            url: `${config.server.API_ENDPOINT}/send-message/email`,
            data: input,
            headers: { "Content-Type": "application/json; charset=utf-8" },
        }).then(response => {
            console.log('[[ RES ]]', response)
            let result = response.data;
            if (callback) callback(result, null);
        }).catch((error) => {
            console.log('[[ ERROR ]]', error.message)
            if (callback) callback(null, error);
        });
    // };
}

// ==============================|| REDUCER ||============================== //

const sendMessageReducer = (state = initialState, action) => {
    let { payload } = action;
    switch (action.type) {
        default:
            return state;
    }
};

export default sendMessageReducer;
