import { gql } from '@apollo/client';

// ====================== QUERY ====================== //
const QUERY_ITEM = gql`
    query widget($input: WidgetInput!){
        widget(input: $input){
            id
            uuid
            code
            username
            type
            chart_uuid
            dataset_uuid
            link
            dashboard_name
            dashboard_description
            dashboard_type
            is_template
            size
            property
            company
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`
const QUERY_ITEMS = gql`
    query widgets($input: WidgetsInput){
        widgets(input: $input){
            id
            uuid
            code
            username
            type
            chart_uuid
            dataset_uuid
            link
            dashboard_name
            dashboard_description
            dashboard_type
            is_template
            size
            property
            company
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`
const QUERY_LOOKUP_ITEMS = gql`
    query widgets($input: WidgetsInput){
        widgets(input: $input){
            # id
            uuid
            code
            username
            type
            chart_uuid
            dataset_uuid
            link
            dashboard_name
            dashboard_description
            dashboard_type
            is_template
            # size
            # property
            company
            # created_at
            # created_by
            # updated_at
            # updated_by
        }
    }
`
const QUERY_ITEMS_BY_PAGE = gql`
    query widgetsByPage($input: WidgetsByPageInput!){
        widgetsByPage(input: $input){
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
            currentPage
            prevPage
            nextPage
            totalPages
            totalItems
            widgets{
                id
                uuid
                code
                username
                type
                chart_uuid
                dataset_uuid
                link
                dashboard_name
                dashboard_description
                dashboard_type
                is_template
                size
                property
                company
                created_at
                created_by
                updated_at
                updated_by
            }
        }
    }
`
const QUERY_DASHBOARDS_BY_PAGE = gql`
    query dashboardsByPage($input: DashboardsByPageInput!){
        dashboardsByPage(input: $input){
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
            currentPage
            prevPage
            nextPage
            totalPages
            totalItems
            dashboards{
                username
                dashboard_name
                dashboard_description
                dashboard_type
                is_template
                company
                widgets{
                    id
                    uuid
                    code
                    username
                    type
                    chart_uuid
                    dataset_uuid
                    link
                    dashboard_name
                    dashboard_description
                    dashboard_type
                    is_template
                    size
                    property
                    company
                    created_at
                    created_by
                    updated_at
                    updated_by
                }
                created_at
                created_by
                updated_at
                updated_by
            }
        }
    }
`
// ====================== MUTATION ====================== //
const MUTATION_CREATE_ITEM = gql`
    mutation createWidget($input: CreateWidgetInput!){
        createWidget(input: $input){
            id
            uuid
            code
            username
            type
            chart_uuid
            dataset_uuid
            link
            dashboard_name
            dashboard_description
            dashboard_type
            size
            property
            company
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`
const MUTATION_CREATE_ITEMS = gql`
    mutation createWidgets($input: [CreateWidgetInput]!){
        createWidgets(input: $input){
            id
            uuid
            code
            username
            type
            chart_uuid
            dataset_uuid
            link
            dashboard_name
            dashboard_description
            dashboard_type
            is_template
            size
            property
            company
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`
const MUTATION_CUSTOM_WIDGETS = gql`
    mutation customizeWidgets($input: [CreateWidgetInput]!){
        customizeWidgets(input: $input){
            id
            uuid
            code
            username
            type
            chart_uuid
            dataset_uuid
            link
            dashboard_name
            dashboard_description
            dashboard_type
            is_template
            size
            property
            company
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`
const MUTATION_DELETE_ITEM = gql`
    mutation deleteWidget($input: DeleteWidgetInput!){
        deleteWidget(input: $input){
            id
            uuid
            code
            username
            type
            chart_uuid
            dataset_uuid
            link
            dashboard_name
            dashboard_description
            dashboard_type
            is_template
            size
            property
            company
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`
const MUTATION_DELETE_ITEMS = gql`
    mutation deleteWidgets($input: DeleteWidgetInput!){
        deleteWidgets(input: $input){
            id
            uuid
            code
            username
            type
            chart_uuid
            dataset_uuid
            link
            dashboard_name
            dashboard_description
            dashboard_type
            is_template
            size
            property
            company
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`
const MUTATION_EXPORT_ITEMS = gql`
    mutation exportWidgets($input: WidgetsInput!, $by: ActionByInput!){
        exportWidgets(input: $input, by: $by){
           uuid
           size
           key
           name
           status
           topic
           type
           url
           company
           created_at
           created_by
           updated_at
           updated_by
        }
    }
`
const MUTATION_UPDATE_ITEM = gql`
    mutation updateWidget($input: UpdateWidgetInput!){
        updateWidget(input: $input){
            id
            uuid
            code
            username
            type
            chart_uuid
            dataset_uuid
            link
            dashboard_name
            dashboard_description
            dashboard_type
            is_template
            size
            property
            company
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`

// ====================== CREATE GQL ====================== //
const widgetGQL = {
    query: {
        getItem: QUERY_ITEM,
        getItems: QUERY_ITEMS,
        getLookupItems: QUERY_LOOKUP_ITEMS,
        getItemsByPage: QUERY_ITEMS_BY_PAGE,
        getDashboardsByPage: QUERY_DASHBOARDS_BY_PAGE,
    },
    mutation: {
        createItem: MUTATION_CREATE_ITEM,
        createItems: MUTATION_CREATE_ITEMS,
        customizeWidgets: MUTATION_CUSTOM_WIDGETS,
        deleteItem: MUTATION_DELETE_ITEM,
        deleteItems: MUTATION_DELETE_ITEMS,
        exportItems: MUTATION_EXPORT_ITEMS,
        updateItem: MUTATION_UPDATE_ITEM,
    },
    response: {
        getItem: "widget",
        getItems: "widgets",
        getLookupItems: "widgets",
        getItemsByPage: "widgetsByPage",
        getDashboardsByPage: "dashboardsByPage",
        createItem: "createWidget",
        createItems: "createWidgets",
        customizeWidgets: "customizeWidgets",
        deleteItem: "deleteWidget",
        deleteItems: "deleteWidgets",
        exportItems: "exportWidgets",
        updateItem: "updateWidget",
    }
}

// ====================== EXPORT ====================== //
export {
    widgetGQL,
}