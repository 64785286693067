import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// third-party
import { Auth0Client } from '@auth0/auth0-spa-js';

// reducer - state management
import { LOGIN, LOGOUT } from 'store/actions';
import authReducer, { isAuthenticated, refresh } from 'store/reducers/auth';
import userReducer, { checkSession } from 'store/reducers/user.reducer';


// project import
import Loader from 'components/Loader';
// import { AUTH0_API } from 'config';

// constant
// let auth0Client;

const initialState = {
  isLoggedIn: false,
  isInitialized: false,
  user: null,
  account: null,
};

// ==============================|| AUTH0 CONTEXT & PROVIDER ||============================== //

const Auth0Context = createContext(null);

export const Auth0Provider = ({ children }) => {
  // const [state, dispatch] = useReducer(authReducer, initialState);

  const dispatch = useDispatch();
  const state = useSelector((state) => state.auth);

  useEffect(() => {
    console.log("Auth0Provider")
    const init = async () => {
      try {

        dispatch(refresh(false, (res)=>{
          console.log(res)
        }))
        // dispatch(isAuthenticated(false, (res)=>{
        //   console.log(res)
        // }))

      } catch (err) {
        console.error(err)
        dispatch({
          type: LOGOUT
        });
      }
    };

    init();
  }, []);

  const login = async (options) => {
    // await auth0Client.loginWithPopup(options);
    // const isLoggedIn = await auth0Client.isAuthenticated();

    // if (isLoggedIn) {
    //   const user = await auth0Client.getUser();
    //   dispatch({
    //     type: LOGIN,
    //     payload: {
    //       isLoggedIn: true,
    //       user: {
    //         id: user?.sub,
    //         avatar: user?.picture,
    //         email: user?.email,
    //         name: user?.name,
    //         tier: 'Premium'
    //       }
    //     }
    //   });
    // }
  };

  const logout = () => {
    // auth0Client.logout();

    // dispatch({
    //   type: LOGOUT
    // });
  };

  const resetPassword = async () => { };

  const updateProfile = () => { };

  if (state.isInitialized !== undefined && !state.isInitialized) {
    return <Loader />;
  }

  return <Auth0Context.Provider value={{ ...state, login, logout, resetPassword, updateProfile }}>{children}</Auth0Context.Provider>;
};

Auth0Provider.propTypes = {
  children: PropTypes.node
};

export default Auth0Context;
