import { gql } from '@apollo/client';

// ====================== QUERY ====================== //
const QUERY_ITEM = gql`
    query messageTemplate($input: MessageTemplateInput!){
        messageTemplate(input: $input){
            id
            uuid
            name
            invitation_uuid
            type
            language
            is_default
            header
            body
            company
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`
const QUERY_ITEMS = gql`
    query messageTemplates($input: MessageTemplatesInput){
        messageTemplates(input: $input){
            id
            uuid
            name
            invitation_uuid
            type
            language
            is_default
            header
            body
            company
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`
const QUERY_LOOKUP_ITEMS = gql`
    query messageTemplates($input: MessageTemplatesInput){
        messageTemplates(input: $input){
            # id
            uuid
            name
            # invitation_uuid
            type
            language
            is_default
            # header
            # body
            company
            # created_at
            # created_by
            # updated_at
            # updated_by
        }
    }
`
const QUERY_ITEMS_BY_PAGE = gql`
    query messageTemplatesByPage($input: MessageTemplatesByPageInput!){
        messageTemplatesByPage(input: $input){
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
            currentPage
            prevPage
            nextPage
            totalPages
            totalItems
            message_templates{
                id
                uuid
                name
                invitation_uuid
                type
                language
                is_default
                header
                body
                company
                created_at
                created_by
                updated_at
                updated_by
            }
        }
    }
`
// ====================== MUTATION ====================== //
const MUTATION_CREATE_ITEM = gql`
    mutation createMessageTemplate($input: CreateMessageTemplateInput!){
        createMessageTemplate(input: $input){
            id
            uuid
            name
            invitation_uuid
            type
            language
            is_default
            header
            body
            company
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`
const MUTATION_CREATE_ITEMS = gql`
    mutation createMessageTemplates($input: [CreateMessageTemplateInput]!){
        createMessageTemplates(input: $input){
            id
            uuid
            name
            invitation_uuid
            type
            language
            is_default
            header
            body
            company
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`
const MUTATION_DELETE_ITEM = gql`
    mutation deleteMessageTemplate($input: DeleteMessageTemplateInput!){
        deleteMessageTemplate(input: $input){
            id
            uuid
            name
            invitation_uuid
            type
            language
            is_default
            header
            body
            company
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`
const MUTATION_DELETE_ITEMS = gql`
    mutation deleteMessageTemplates($input: DeleteMessageTemplateInput!){
        deleteMessageTemplates(input: $input){
            id
            uuid
            name
            invitation_uuid
            type
            language
            is_default
            header
            body
            company
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`
const MUTATION_EXPORT_ITEMS = gql`
    mutation exportMessageTemplates($input: MessageTemplatesInput!, $by: ActionByInput!){
        exportMessageTemplates(input: $input, by: $by){
           uuid
           size
           key
           name
           status
           topic
           type
           url
           company
           created_at
           created_by
           updated_at
           updated_by
        }
    }
`
const MUTATION_UPDATE_ITEM = gql`
    mutation updateMessageTemplate($input: UpdateMessageTemplateInput!){
        updateMessageTemplate(input: $input){
            id
            uuid
            name
            invitation_uuid
            type
            language
            is_default
            header
            body
            company
            created_at
            created_by
            updated_at
            updated_by
        }
    }
`

// ====================== CREATE GQL ====================== //
const messageTemplateGQL = {
    query: {
        getItem: QUERY_ITEM,
        getItems: QUERY_ITEMS,
        getLookupItems: QUERY_LOOKUP_ITEMS,
        getItemsByPage: QUERY_ITEMS_BY_PAGE,
    },
    mutation: {
        createItem: MUTATION_CREATE_ITEM,
        createItems: MUTATION_CREATE_ITEMS,
        deleteItem: MUTATION_DELETE_ITEM,
        deleteItems: MUTATION_DELETE_ITEMS,
        exportItems: MUTATION_EXPORT_ITEMS,
        updateItem: MUTATION_UPDATE_ITEM,
    },
    response: {
        getItem: "messageTemplate",
        getItems: "messageTemplates",
        getLookupItems: "messageTemplates",
        getItemsByPage: "messageTemplatesByPage",
        createItem: "createMessageTemplate",
        createItems: "createMessageTemplates",
        deleteItem: "deleteMessageTemplate",
        deleteItems: "deleteMessageTemplates",
        exportItems: "exportMessageTemplates",
        updateItem: "updateMessageTemplate",
    }
}

// ====================== EXPORT ====================== //
export {
    messageTemplateGQL,
}